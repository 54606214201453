// 递归处理数据
function recursionChildData(data, child, neme) {
  var newArr = [];
  let _i = 0;
  function childData(data, index, nameField) {
    index++;
    let name = nameField;
    if (!name) {
      name = "name";
    }
    for (var i = 0; i < data.length; i++) {
      let _name = data[i].name;
      let _blank = "";
      for (let p = 1; p < index; p++) {
        _blank = _blank + "    ";
      }
      data[i].name = _blank + _name;
      newArr.push(data[i]);
      if (data[i][child] != undefined) {
        childData(data[i][child], index);
      }
    }
  }
  childData(data, _i);
  return newArr;
}
export default recursionChildData;
