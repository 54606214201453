<template>
  <div>
    <div class="logobox">
      <transition name="sidebarLogoFade">
        <router-link
          key="Collapse"
          class="sidebar-logo-link"
          to="/"
          v-if="isCollapse"
        >
          <img src="@/assets/images/logo/logo.png" class="sidebar-logo" />
        </router-link>
        <router-link v-else key="expand" class="sidebar-logo-link" to="/">
          <img src="@/assets/images/logo/logo-1.png" class="sidebar-logo" />
        </router-link>
      </transition>
    </div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>
<script>
import SidebarItem from "./SidebarItem";
import variables from "@/styles/variables.scss";
export default {
  components: { SidebarItem },
  computed: {
    routes() {
      // return this.$router.options.routes; // 路由遍历
      return this.$store.state.routerList; //store遍历
    },
    variables() {
      //variables.scss 对侧边栏的定义 颜色背景
      return variables;
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    isCollapse() {
      // 侧边栏是否关闭
      return this.$store.state.closeSidebar;
    }
  }
};
</script>
