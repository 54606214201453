<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </section>
</template>
<script>
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.path;
    }
  }
};
</script>
<style lang="scss" scoped>
.app-main {
  width: 100%;
  height: calc(100vh - 50px);
  overflow: auto;
  padding: 0;
  background: #f7f7f7;
  & > div {
    min-height: 100%;
    padding: 30px;
  }
}
</style>
