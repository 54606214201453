<template>
  <div class="clickUpload">
    <el-input
      v-model="val"
      placeholder="请输入内容"
      autocomplete="on"
      readonly
    ></el-input>
    <el-upload
      class="uploadBtn"
      action=""
      :http-request="upload"
      :show-file-list="false"
      :file-list="valArr"
      multiple
      :limit="limit"
      :accept="accept"
      :on-exceed="error"
    >
      <el-button size="small" type="primary" icon="el-icon-upload2"
        >上传</el-button
      >
      <div slot="tip" class="upload_list">
        <ul class="el-upload-list el-upload-list--text">
          <template v-for="(item, index) in valArr">
            <div
              v-if="isImage(item.url)"
              :key="index"
              class="el-upload-list__img"
            >
              <el-image
                style="width: 100px; height: 100px"
                :src="item.url"
                :preview-src-list="[item.url]"
                :z-index="2020"
              >
              </el-image>
              <i class="el-icon-close" @click="removeUpload(index)"></i>
            </div>
            <li class="el-upload-list__item is-success" :key="index" v-else>
              <a
                :href="item.url"
                target="_blank"
                class="el-upload-list__item-name"
              >
                <i class="el-icon-document"></i>{{ item.name }}
              </a>
              <label class="el-upload-list__item-status-label">
                <i class="el-icon-upload-success el-icon-circle-check"> </i>
              </label>
              <i class="el-icon-close" @click="removeUpload(index)"></i>
            </li>
          </template>
        </ul>
      </div>
    </el-upload>
  </div>
</template>
<script>
import { upload } from "@/api/upload";
import compressImg from "@/utils/compressImg";
export default {
  name: "clickUpload",
  props: {
    multiple: {
      type: Boolean, //是否可以上传多个默认否
      default: false
    },
    limit: {
      type: Number, //最大上传数
      default: 99
    },
    accept: {
      type: String //上传格式
    },
    value: {
      type: [Array, String], //上传格式
      default: Array
    }
  },
  data() {
    return {
      val: "",
      valArr: []
    };
  },
  methods: {
    upload(e) {
      let that = this;
      let form = new FormData();
      if (e.file.type == "image/jpeg" || e.file.type == "image/png") {
        compressImg(e.file).then(function(e) {
          form.append("file", e);
          form.append("folder", "productFile");
          that.apiUpload(form);
        });
      } else {
        form.append("file", e.file);
        form.append("folder", "productFile");
        that.apiUpload(form);
      }
    },
    removeUpload(i) {
      this.valArr.splice(i, 1);
      let _arrt = [];
      this.valArr.forEach(element => {
        _arrt.push(element.url);
      });
      this.val = _arrt.join(",");
    },
    error() {
      this.$message.warning("当前限制最多上传" + this.limit + " 个文件。");
    },
    apiUpload(form) {
      let that = this;
      let _multiple = that.multiple;
      upload(form)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            let arr = {
              url: res.data.fileUrl,
              name: res.data.fileName
            };
            if (!_multiple) {
              that.valArr = [];
            }
            that.valArr.push(arr);
            let _arrt = [];
            that.valArr.forEach(element => {
              _arrt.push(element.url);
            });
            that.val = _arrt.join(",");
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    //获取后缀名判断是否是图片
    isImage(url) {
      let extArray = ["png", "jpg", "jpge"];
      let index = url.lastIndexOf(".");
      //获取后缀
      let ext = url.substring(index + 1);
      if (extArray.includes(ext)) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    val: {
      handler: function(val) {
        this.$emit("input", this.valArr);
      },
      deep: true
    },
    value: {
      handler: function(e) {
        if (Array.isArray(e)) {
          this.valArr = e;
          let _arrt = [];
          this.valArr.forEach(element => {
            _arrt.push(element.url);
          });
          this.val = _arrt.join(",");
        } else {
          this.val = e;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
<style lang="scss">
.el-image-viewer__wrapper {
  z-index: 9999 !important;
}
.clickUpload {
  & > .el-input input {
    padding-right: 90px;
  }
  .uploadBtn > .el-upload {
    position: absolute;
    top: 0;
    right: 0;
  }
  & > .uploadBtn > .upload_list {
    .el-upload-list__item {
      .el-upload-list__item-status-label,
      .el-icon-close {
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .el-upload-list__img {
      display: inline-block;
      margin-right: 10px;
      margin-top: 10px;
      position: relative;
      .el-image {
        vertical-align: middle;
      }
      .el-icon-close {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        text-align: center;
        z-index: 2;
        height: 20px;
        background: #ff00008c;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>
