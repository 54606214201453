<template>
  <div class="groupBrokerage">
    <el-scrollbar>
      <el-form
        :model="broForm"
        :rules="borRules"
        ref="broForm"
        label-width="120px"
        size="small"
      >
        <el-form-item label="经代公司" prop="agent_id">
          <el-select
            v-model="broForm.agent_id"
            placeholder="请选择经代公司"
            filterable
          >
            <el-option
              v-for="(item, index) in agentCompanyList"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="保司补税" prop="insurance_make_tax">
          <el-input
            v-model="broForm.insurance_make_tax"
            placeholder="保司补税%"
          ></el-input>
        </el-form-item>
        <el-form-item label="跟单比例" prop="documentary_ratio">
          <el-input
            v-model="broForm.documentary_ratio"
            placeholder="跟单比例%"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否多方共保" prop="is_co_insurance">
          <el-radio-group v-model="broForm.is_co_insurance">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="佣金结算方式" prop="commission_type">
          <el-radio-group v-model="broForm.commission_type">
            <el-radio :label="1">固定佣金</el-radio>
            <el-radio :label="2">阶梯佣金</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="固定佣金"
          prop="promotion_commission"
          v-if="broForm.commission_type == 1"
        >
          <el-input
            v-model="broForm.promotion_commission"
            placeholder="佣金比例"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="阶梯佣金" prop="step_commission" v-else>
          <div
            class="stepBox"
            v-for="(item, index) in broForm.step_commission"
            :key="index"
          >
            保费满
            <el-form-item
              :prop="`step_commission.${index}.policyCost`"
              :rules="borRules.policyCost"
            >
              <el-input
                v-model="item.policyCost"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            万元，佣金比例
            <el-form-item
              :prop="`step_commission.${index}.ratio`"
              :rules="borRules.ratio"
            >
              <el-input v-model="item.ratio" placeholder="佣金比例"></el-input>
            </el-form-item>
            <el-button
              type="primary"
              v-if="index == 0"
              @click="addSte"
              style="padding:5px"
            >
              添加
            </el-button>
            <el-button
              v-else
              type="danger"
              style="padding:5px"
              @click="deleteSte(index)"
            >
              删除
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-scrollbar>
    <div class="btnBox">
      <el-button type="primary" size="small" @click="confirm()">确定</el-button>
      <el-button size="small" @click="cancel()">取消</el-button>
    </div>
  </div>
</template>
<script>
import {
  changeProductCommission,
  getProductCommission,
  getAgentCompanyList
} from "@/api/productAdmin";
export default {
  name: "groupBrokerage",
  props: {
    id: {
      type: [Number, String],
      default: ""
    },
    status: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      // 经代公司
      agentCompanyList: [],
      broForm: {
        agent_id: "",
        insurance_make_tax: "",
        documentary_ratio: "",
        is_co_insurance: 0,
        commission_type: 1,
        promotion_commission: 0,
        step_commission: [
          {
            policyCost: 0,
            ratio: 0
          }
        ]
      },
      borRules: {
        agent_id: [
          {
            required: true,
            message: "经代公司不能为空",
            trigger: ["blur", "change"]
          }
        ],
        insurance_make_tax: [
          { required: true, message: "保司补税%不能为空", trigger: "blur" },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: "保司补税%必须为数字值",
            trigger: "blur"
          }
        ],
        documentary_ratio: [
          { required: true, message: "跟单比例 %不能为空", trigger: "blur" },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: "跟单比例 %必须为数字值",
            trigger: "blur"
          }
        ],
        promotion_commission: [
          { required: true, message: "固定佣金不能为空", trigger: "blur" },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: "固定佣金必须为数字值",
            trigger: "blur"
          }
        ],
        policyCost: [
          { required: true, message: "保费不能为空", trigger: "blur" },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: "保费必须为数字值",
            trigger: "blur"
          }
        ],
        ratio: [
          { required: true, message: "佣金比例不能为空", trigger: "blur" },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: "佣金比例必须为数字值",
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    // 获取经代公司
    this.getAgentCompanyList();
    // 获取方案
    this.getProductCommission();
  },
  methods: {
    // 添加阶梯佣金
    addSte() {
      this.broForm.step_commission.push({ policyCost: 0, ratio: 0 });
    },
    // 删除阶梯佣金
    deleteSte(e) {
      this.broForm.step_commission.splice(e, 1);
    },
    // 点击确认
    confirm() {
      let that = this;
      that.$refs.broForm.validate(valid => {
        if (valid) {
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          let _data = that.broForm;
          _data.product_id = that.id;
          changeProductCommission(_data)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.$emit("upStatus", true);
                that.cancel();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          that.$nextTick(() => {
            document.querySelector(".is-error").scrollIntoView();
          });
          return false;
        }
      });
    },
    // 取消
    cancel() {
      this.$emit("update:status", false);
    },
    // 获取信息
    getProductCommission() {
      let that = this;
      getProductCommission({ product_id: that.id })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            if (res.data.agent_id) {
              that.broForm = res.data;
            }
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取经代公司
    getAgentCompanyList() {
      let that = this;
      getAgentCompanyList()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.agentCompanyList = res.data;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>
<style lang="scss">
.groupBrokerage {
  .el-scrollbar__wrap {
    height: calc(100vh - 400px);
    overflow-x: hidden;
    margin-bottom: 0 !important;
  }
  .btnBox {
    text-align: center;
    padding: 10px 25px;
    background: #efefef;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -30px;
    .el-button {
      width: 80px;
    }
    .el-button + .el-button {
      margin-left: 25px;
    }
  }
  .el-form {
    width: calc(100% - 100px);
    .stepBox {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;
      .el-form-item {
        margin: 0px 10px;
        width: 100px;
      }
    }
  }
}
</style>
