import axios from "axios";
import qs from "qs";
import store from "@/store";
import Cookies from "js-cookie";
import { Message } from "element-ui";
const service = axios.create({
  baseURL: "/api",
  timeout: 30000,
  withCredentials: true,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
  }
});
service.interceptors.request.use(config => {
  if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    config.headers["X-Token"] = store.getters.token;
    config.headers["x-source"] = 99;
  }
  if (config.method === "post") {
    let curPost = config.url.split("/")[config.url.split("/").length - 1];
    if (
      //上传的接口
      curPost === "upload" ||
      curPost === "fileUpload" ||
      curPost === "uploadWorkType"
    ) {
      return config; // 这里对上传文件/图片的 api 不做传参序列化处理
    } else {
      config.data = qs.stringify(config.data);
      return config;
    }
  }
  return config;
});
service.interceptors.response.use(
  response => {
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    if (response.status === 200) {
      if (response.data.code == 401) {
        store.commit("goLogin", response.data.msg);
        // Message.error(response.data.msg);
      } else {
        return response.data;
      }
    } else if (response.status === 500) {
      Message.error("服务器遇到错误，无法完成请求。");
    } else {
      Promise.reject();
    }
  },
  error => {
    if (error.response.status == 500) {
      Message.error("服务器遇到错误，无法完成请求。");
    } else if (error.response.status === 401) {
      store.commit("goLogin", error.response.data.msg);
      // Message.error(error.response.data.msg);
    }
    return Promise.resolve(error.response);
  }
);

export default service;
