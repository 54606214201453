<template>
  <div class="checkAudit" v-if="routeClass == 2">
    <search-form
      :searcharray="searcharray"
      :span="6"
      @change="search"
      @reset="reset"
    ></search-form>
    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%;margin-top:15px"
      row-key="id"
      border
      stripe
      lazy
      size="small"
      class="teamTtable"
    >
      <el-table-column prop="num_id" align="center" label="流水号">
      </el-table-column>
      <el-table-column prop="policy_num" align="center" label="保单号">
      </el-table-column>
      <el-table-column prop="correct_num" align="center" label="批单号">
      </el-table-column>
      <el-table-column prop="applicationName" align="center" label="投保人名称">
      </el-table-column>
      <el-table-column prop="insuredName" align="center" label="被保人名称">
      </el-table-column>
      <el-table-column prop="productName" align="center" label="产品名称">
      </el-table-column>
      <el-table-column prop="companyName" align="center" label="保险公司">
      </el-table-column>
      <el-table-column prop="c_time" align="center" label="创建时间">
      </el-table-column>
      <el-table-column prop="s_time" align="center" label="生效时间">
      </el-table-column>
      <el-table-column prop="e_time" align="center" label="终止时间">
      </el-table-column>
      <el-table-column
        prop="policy_money"
        align="center"
        label="保费金额"
        width="100"
      >
      </el-table-column>
      <el-table-column prop="statusTxt" align="center" label="审核状态">
      </el-table-column>
      <el-table-column prop="adminName" align="center" label="提交人">
      </el-table-column>
      <el-table-column prop="checkName" align="center" label="审核人">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="look(scope.row.id, 1)"
            v-if="scope.row.status == 2"
          >
            审核
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="look(scope.row.id, 2)"
            v-if="
              scope.row.status == 3 ||
                scope.row.status == 4 ||
                scope.row.status == 5 ||
                scope.row.status == 6 ||
                scope.row.status == 7
            "
          >
            查看
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="look(scope.row.id, 3)"
            v-if="scope.row.status == 15 || scope.row.status == 3"
          >
            上传批单信息
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="look(scope.row.num_id, 3, scope.row.productCate)"
            v-if="searchData.status == 6 || searchData.status == 5"
          >
            更新批单信息
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :page-sizes="pageSizeArray"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
  <router-view v-else />
</template>
<script>
import searchForm from "@/components/common/searchForm";
import { getCorrectionList } from "@/api/audit";
import { getCompany } from "@/api/public";
import { getAdmin } from "@/api/system";
export default {
  name: "policy_audit",
  data() {
    return {
      routeClass: "",
      searcharray: [
        {
          label: "保险公司",
          val: "company_id",
          type: "select",
          pla: "请选择保险公司",
          option: []
        },
        {
          label: "提交人",
          val: "adminId",
          type: "select",
          pla: "请选择",
          option: []
        },
        {
          label: "投保人名称",
          val: "applicationName",
          type: "input",
          pla: "请输入投保人名称"
        },
        {
          label: "被保人名称",
          val: "insuredName",
          type: "input",
          pla: "请输入被保人名称"
        },
        {
          label: "流水号",
          val: "numId",
          type: "input",
          pla: "请输入流水号"
        },
        {
          label: "保单号",
          val: "policyNum",
          type: "input",
          pla: "请输入保单号"
        },
        {
          label: "批单号",
          val: "correctNum",
          type: "input",
          pla: "请输入批单号"
        },
        {
          label: "创建时间",
          val: "cTimeArr",
          type: "date"
        },
        {
          label: "状态",
          val: "status",
          type: "select",
          pla: "请选择保单状态",
          default: "2",
          option: [
            { label: "待审核", val: "2" },
            { label: "已审核", val: "3" },
            // { label: "驳回", val: "4" },
            { label: "待上传批单信息", val: "15" },
            { label: "待生效", val: "5" },
            { label: "保障中", val: "6" }
          ]
        },
        {
          label: "产品名称",
          val: "productName",
          type: "input",
          pla: "请输入流水号"
        }
      ],
      searchData: { status: "2" },
      loading: false,
      tableData: [],
      // 分页 每页条数
      pageSize: 15,
      // 分页 每页条数可选项
      pageSizeArray: [15, 30, 45, 60],
      // 当前页
      page: 1,
      // 总页数
      total: 0
    };
  },
  components: {
    searchForm
  },
  mounted() {
    if (this.$route.query.status) {
      //如果不为空说明是首页定位过来的默认加载待上传资料
      this.searchData.status = this.$route.query.status;
      this.searcharray[8].default = this.$route.query.status;
    }
    // 获取当前路由等级
    this.routeClass = this.$route.matched.length;
    // 获取保司
    this.getCompany();
    // 获取列表
    this.getCorrectionList();
    // 获取当前账号下可用管理员
    this.getAdmin();
  },
  methods: {
    // 搜索数据
    search(e) {
      this.page = 1;
      this.searchData = e;
      this.getCorrectionList();
    },
    //搜索重置
    reset(e) {
      this.page = 1;
      this.searchData = { status: "2" };
      this.getCorrectionList();
    },
    // 获取列表
    getCorrectionList() {
      let _data = this.searchData;
      let that = this;
      _data.page = this.page;
      _data.limit = this.pageSize;
      _data.identification = 2;
      that.loading = true;
      getCorrectionList(_data)
        .then(function(res) {
          console.log(res);
          that.loading = false;
          if (res.code == 1) {
            that.tableData = res.data.list;
            that.total = res.data.total;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 每页几条
    sizeChange() {
      this.getCorrectionList();
    },
    // 页码变化
    currentChange() {
      this.getCorrectionList();
    },
    look(e, op) {
      // 审核1 查看2 上传资料3
      this.$router.push({
        path: "/audit/check/details",
        query: { changeId: e, op: op }
      });
    },
    // 获取保司列表
    getCompany() {
      let that = this;
      getCompany()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            res.data.forEach((item, index) => {
              let _obj = { label: "", val: "" };
              _obj.label = item.name;
              _obj.val = item.id;
              that.searcharray[0].option.push(_obj);
            });
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取当前账号下可用管理员
    getAdmin() {
      let that = this;
      getAdmin()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            res.data.forEach((item, index) => {
              let _obj = { label: "", val: "" };
              _obj.label = item.nickname;
              _obj.val = item.id;
              that.searcharray[1].option.push(_obj);
            });
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>
<style lang="scss">
.checkAudit {
  background: #fff;
}
</style>
