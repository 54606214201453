<template>
  <div class="team">
    <el-button type="primary" size="small" icon="el-icon-plus" @click="add()"
      >添加
    </el-button>
    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%;margin-top:15px"
      row-key="id"
      border
      stripe
      lazy
      size="small"
      class="teamTtable"
    >
      <el-table-column prop="id" label="序号" align="center" width="80px">
      </el-table-column>
      <el-table-column prop="title" align="center" label="公告标题">
      </el-table-column>
      <el-table-column prop="content" align="center" label="公告内容">
      </el-table-column>
      <el-table-column prop="c_time" align="center" label="创建时间">
      </el-table-column>
      <el-table-column prop="u_time" align="center" label="修改时间">
      </el-table-column>
      <el-table-column prop="state" align="center" label="是否启用">
        <template slot-scope="scope">
          <el-tag type="success" size="mini" v-if="scope.row.status == 1">
            启用
          </el-tag>
          <el-tag type="danger" size="mini" v-else>禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="handleEdit(scope.row.id)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :page-sizes="pageSizeArray"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination> -->
    <!--弹窗 -->
    <div class="teamPop">
      <el-dialog
        :title="popTitle"
        :visible.sync="dialogState"
        width="540px"
        custom-class="teamPop"
        @close="dialogClose"
      >
        <el-form
          :model="noticeForm"
          :rules="rules"
          :validate-on-rule-change="false"
          ref="noticeForm"
          label-width="130px"
          class="noticeForm"
          size="small"
        >
          <el-form-item label="公告标题" prop="title">
            <el-input
              v-model="noticeForm.title"
              placeholder="请输入标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="公告内容" prop="content">
            <el-input
              type="textarea"
              v-model.trim="noticeForm.content"
              placeholder="请输入内容"
              autocomplete="on"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="公告展示时间 " prop="charge">
            <el-input
              v-model.trim="noticeForm.charge"
              placeholder="请输入天数"
              autocomplete="on"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="几个小时后发布 " prop="releaseTime">
            <el-input
              v-model.trim="noticeForm.releaseTime"
              placeholder="请输入小时数，0表示立即发布"
              autocomplete="on"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-radio v-model="noticeForm.status" :label="1">启用</el-radio>
            <el-radio v-model="noticeForm.status" :label="0">禁用</el-radio>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="dialogOk()">
            确 定
          </el-button>
          <el-button @click="dialogClose()" size="small">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { getNoticeList, getNoticeInfo, noticeChange } from "@/api/system";
export default {
  name: "team",
  data() {
    return {
      loading: false,
      tableData: [],
      // 分页 每页条数
      pageSize: 15,
      // 分页 每页条数可选项
      pageSizeArray: [15, 30, 45, 60],
      // 当前页
      page: 1,
      // 总页数
      total: 0,
      popTitle: "添加",
      dialogState: false,
      noticeForm: {
        ids: "",
        title: "",
        content: "",
        releaseTime: "0",
        act: "add",
        status: 1
      },
      rules: {
        title: [
          { required: true, message: "公告标题不能为空", trigger: "blur" }
        ],
        content: [
          { required: true, message: "公告内容不能为空", trigger: "blur" }
        ],
        releaseTime: [
          {
            required: true,
            message: "几个小时后发布不能为空",
            trigger: "blur"
          },
          {
            pattern: /^[0-9]+$/,
            message: "只能是整数",
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    this.getNoticeList();
  },
  methods: {
    // 获取列表
    getNoticeList() {
      let _data = {};
      let that = this;
      // _data.page = this.page;
      // _data.limit = this.pageSize;
      that.loading = true;
      getNoticeList(_data)
        .then(function(res) {
          console.log(res);
          that.loading = false;
          if (res.code == 1) {
            that.tableData = res.data;
            // that.total = res.data.total;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 每页几条
    sizeChange() {
      this.getNoticeList();
    },
    // 页码变化
    currentChange() {
      this.getNoticeList();
    },
    // 添加
    add() {
      this.noticeForm = {
        ids: "",
        title: "",
        content: "",
        releaseTime: "0",
        act: "add",
        status: 1
      };
      this.popTitle = "添加";
      this.dialogState = true;
    },
    // 编辑
    handleEdit(e) {
      console.log(e);
      let that = this;
      let _data = {
        ids: e
      };
      getNoticeInfo(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.popTitle = "编辑";
            that.noticeForm = {
              ids: res.data.id,
              title: res.data.title,
              content: res.data.content,
              releaseTime: res.data.release_time,
              act: "update",
              status: res.data.status
            };
            that.dialogState = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 弹窗确定
    dialogOk() {
      this.$refs.noticeForm.validate(valid => {
        let that = this;
        if (valid) {
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          noticeChange(that.noticeForm)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.getNoticeList();
                that.dialogClose();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭取消
    dialogClose() {
      this.$nextTick(() => {
        this.$refs.noticeForm.resetFields();
      });
      this.dialogState = false;
    }
  }
};
</script>
<style lang="scss">
.team {
  padding: 30px;
  min-height: 100%;
  background: #fff;
  .noticeForm {
    width: 400px;
  }
}
</style>
