<template>
  <div class="team">
    <search-form
      :searcharray="searcharray"
      :span="8"
      @change="search"
      @reset="reset"
    ></search-form>
    <el-button type="primary" size="small" icon="el-icon-plus" @click="add()"
      >添加
    </el-button>
    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%;margin-top:15px"
      row-key="id"
      border
      stripe
      lazy
      size="small"
      class="teamTtable"
    >
      <el-table-column prop="id" label="ID" align="center" width="80px">
      </el-table-column>
      <el-table-column prop="title" align="center" label="团队名称">
      </el-table-column>
      <el-table-column prop="remark" align="center" label="团队标识">
      </el-table-column>
      <el-table-column prop="charge" align="center" label="团队负责人">
      </el-table-column>
      <el-table-column prop="chargePhone" align="center" label="负责人手机号">
      </el-table-column>
      <el-table-column prop="state" align="center" label="团队状态">
        <template slot-scope="scope">
          <el-tag type="success" size="mini" v-if="scope.row.status == 1">
            启用
          </el-tag>
          <el-tag type="danger" size="mini" v-else>禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="handleEdit(scope.row.id)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :page-sizes="pageSizeArray"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!--弹窗 -->
    <div class="teamPop">
      <el-dialog
        :title="popTitle"
        :visible.sync="dialogState"
        width="540px"
        custom-class="teamPop"
        @close="dialogClose"
      >
        <el-form
          :model="teamForm"
          :rules="rules"
          :validate-on-rule-change="false"
          ref="teamForm"
          label-width="130px"
          class="teamForm"
          size="small"
        >
          <el-form-item label="团队名称" prop="title">
            <el-input
              v-model="teamForm.title"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="团队标识" prop="remark">
            <el-input
              v-model.trim="teamForm.remark"
              placeholder="请输入内容"
              autocomplete="on"
            ></el-input>
          </el-form-item>
          <el-form-item label="团队负责人 " prop="charge">
            <el-input
              v-model.trim="teamForm.charge"
              placeholder="请输入内容"
              autocomplete="on"
            ></el-input>
          </el-form-item>
          <el-form-item label="负责人手机号" prop="chargePhone">
            <el-input
              v-model.trim="teamForm.chargePhone"
              placeholder="请输入内容"
              autocomplete="on"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-radio v-model="teamForm.status" :label="1">启用</el-radio>
            <el-radio v-model="teamForm.status" :label="0">禁用</el-radio>
          </el-form-item>
        </el-form>
        <p class="hint">该团队设置为禁用后，所属账号均不可登陆。</p>
        <p class="hint">
          如果重新启用该团队，需要单独重新设置当前团队所属账号为可登录状态
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="dialogOk()">
            确 定
          </el-button>
          <el-button @click="dialogClose()" size="small">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import searchForm from "@/components/common/searchForm";
import { changeTeam, getTeamList, getTeamInfo } from "@/api/system";
export default {
  name: "team",
  data() {
    return {
      searcharray: [
        {
          label: "团队名称",
          val: "title",
          type: "input",
          pla: "请输入团队名称"
        },
        {
          label: "团队标识",
          val: "remark",
          type: "input",
          pla: "请输入团队标识"
        },
        {
          label: "团队负责人",
          val: "charge",
          type: "input",
          pla: "请输入团队负责人"
        },
        {
          label: "负责人手机号",
          val: "chargePhone",
          type: "input",
          pla: "请输入负责人手机号"
        },
        {
          label: "团队状态",
          val: "status",
          type: "select",
          pla: "请选择团队状态",
          option: [
            { label: "启用", val: "1" },
            { label: "禁用", val: "0" }
          ]
        }
      ],
      searchData: {},
      loading: false,
      tableData: [],
      // 分页 每页条数
      pageSize: 15,
      // 分页 每页条数可选项
      pageSizeArray: [15, 30, 45, 60],
      // 当前页
      page: 1,
      // 总页数
      total: 0,
      popTitle: "添加",
      dialogState: false,
      teamForm: {
        id: "",
        title: "",
        remark: "",
        charge: "",
        chargePhone: "",
        status: 1
      },
      rules: {
        title: [
          { required: true, message: "团队名称不能为空", trigger: "blur" }
        ],
        remark: [
          { required: true, message: "团队标识不能为空", trigger: "blur" },
          {
            pattern: /^[A-Za-z0-9]+$/,
            message: "团队标识只能是数字字母组合",
            trigger: "blur"
          }
        ],
        charge: [
          { required: true, message: "团队负责人不能为空", trigger: "blur" }
        ],
        chargePhone: [
          { required: true, message: "负责人手机号不能为空", trigger: "blur" },
          {
            pattern: /^1[0-9]{10}$/,
            message: "手机号格式不正确",
            trigger: "blur"
          }
        ]
      }
    };
  },
  components: {
    searchForm
  },
  mounted() {
    this.getTeamList();
  },
  methods: {
    // 搜索数据
    search(e) {
      this.page = 1;
      this.searchData = e;
      this.getTeamList();
    },
    //搜索重置
    reset(e) {
      this.page = 1;
      this.searchData = {};
      this.getTeamList();
    },
    // 获取列表
    getTeamList() {
      let _data = this.searchData;
      let that = this;
      _data.page = this.page;
      _data.limit = this.pageSize;
      that.loading = true;
      getTeamList(_data)
        .then(function(res) {
          console.log(res);
          that.loading = false;
          if (res.code == 1) {
            that.tableData = res.data.list;
            that.total = res.data.total;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 每页几条
    sizeChange() {
      this.getTeamList();
    },
    // 页码变化
    currentChange() {
      this.getTeamList();
    },
    // 添加
    add() {
      this.teamForm = {
        id: "",
        title: "",
        remark: "",
        charge: "",
        chargePhone: "",
        status: 1
      };
      this.popTitle = "添加";
      this.dialogState = true;
    },
    // 编辑
    handleEdit(e) {
      console.log(e);
      let that = this;
      let _data = {
        id: e
      };
      getTeamInfo(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.popTitle = "编辑";
            that.teamForm = {
              id: res.data.id,
              title: res.data.title,
              remark: res.data.remark,
              charge: res.data.charge,
              chargePhone: res.data.chargePhone,
              status: res.data.status
            };
            that.dialogState = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 弹窗确定
    dialogOk() {
      this.$refs.teamForm.validate(valid => {
        let that = this;
        if (valid) {
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          changeTeam(that.teamForm)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.getTeamList(that.searchData);
                that.dialogClose();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭取消
    dialogClose() {
      this.$nextTick(() => {
        this.$refs.teamForm.resetFields();
      });
      this.dialogState = false;
    }
  }
};
</script>
<style lang="scss">
.team {
  padding: 30px;
  min-height: 100%;
  background: #fff;
  .teamForm {
    width: 400px;
  }
  .hint {
    font-size: 12px;
    color: rgba(255, 0, 0, 0.65);
    margin: 0 0 0 100px;
  }
}
</style>
