<template>
  <div class="customForm">
    <el-form
      :model="nowModel"
      :rules="rules"
      :validate-on-rule-change="false"
      label-width="150px"
      size="small"
      ref="customForm"
    >
      <div v-for="(item, index) in dataArray" :key="index">
        <el-form-item
          :label="item.title"
          :prop="item.name"
          v-if="item.cate == 'input'"
        >
          <el-input
            v-model="nowModel[item.name]"
            placeholder="请输入内容"
            disabled
            v-if="item.name == 'product_code'"
          ></el-input>
          <el-input
            v-model="nowModel[item.name]"
            placeholder="请输入内容"
            v-else
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="item.title"
          :prop="item.name"
          v-else-if="item.cate == 'inputnum'"
        >
          <el-input-number
            v-model="nowModel[item.name]"
            controls-position="right"
            style="width:100%"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          :label="item.title"
          :prop="item.name"
          v-else-if="item.cate == 'select'"
        >
          <el-select
            v-model="nowModel[item.name]"
            filterable
            placeholder="请选择"
            style="width:100%"
          >
            <el-option
              :label="v"
              :value="i | toNumber"
              v-for="(v, i) in item.value"
              :key="i"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="item.title"
          :prop="item.name"
          v-else-if="item.cate == 'file'"
        >
          <click-upload v-model="nowModel[item.name]"></click-upload>
        </el-form-item>
        <el-form-item
          :label="item.title"
          :prop="item.name"
          v-else-if="item.cate == 'files'"
        >
          <click-upload
            v-model="nowModel[item.name]"
            :multiple="true"
          ></click-upload>
        </el-form-item>
        <el-form-item
          :label="item.title"
          :prop="item.name"
          v-else-if="item.cate == 'fileimg'"
        >
          <click-upload
            v-model="nowModel[item.name]"
            accept=".jpg, .jpeg, .png"
          ></click-upload>
        </el-form-item>
        <el-form-item
          :label="item.title"
          :prop="item.name"
          v-else-if="item.cate == 'fileimgs'"
        >
          <click-upload
            v-model="nowModel[item.name]"
            accept=".jpg, .jpeg, .png"
            :multiple="true"
          ></click-upload>
        </el-form-item>
        <el-form-item
          :label="item.title"
          :prop="item.name"
          v-if="item.cate == 'text'"
        >
          <el-input type="textarea" v-model="nowModel[item.name]"></el-input>
        </el-form-item>
        <el-form-item
          :label="item.title"
          :prop="item.name"
          v-if="item.cate == 'richtext'"
        >
          <tinymce-editor
            v-model="nowModel[item.name]"
            @input="validateField(item.name)"
            :id="item.name"
          >
          </tinymce-editor>
        </el-form-item>
        <el-form-item
          :label="item.title"
          :prop="item.name"
          v-if="item.cate == 'ratio'"
        >
          <el-radio-group v-model="nowModel[item.name]">
            <el-radio
              :label="i | toNumber"
              v-for="(v, i) in item.value"
              :key="i"
            >
              {{ v }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="item.title"
          :prop="item.name"
          v-if="item.cate == 'checkbox'"
        >
          <check-box
            v-model="nowModel[item.name]"
            :options="item.value"
          ></check-box>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
import tinymceEditor from "@/components/common/tinymceEditor";
import clickUpload from "@/components/common/clickUpload";
import checkBox from "@/components/common/checkBox";
export default {
  name: "customForm",
  components: {
    tinymceEditor,
    clickUpload,
    checkBox
  },
  props: {
    dataArray: {
      type: Array,
      default: Array,
      required: true
    },
    verify: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      nowModel: {},
      rules: {}
    };
  },
  methods: {
    submit() {
      let that = this;
      that.$refs.customForm.validate(valid => {
        if (valid) {
          that.$emit("update:verify", true);
          that.$emit("input", that.nowModel);
        } else {
          that.$emit("update:verify", false);
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.customForm.resetFields();
    },
    validateField(type) {
      //用于触发自动定义组件验证
      this.$refs.customForm.validateField(type);
    }
  },
  watch: {
    dataArray: {
      handler: function(val, oldVal) {
        let that = this;
        val.forEach(element => {
          JSON.stringify;
          that.$set(
            that.nowModel,
            [element.name],
            JSON.parse(JSON.stringify(element.select))
          );
          switch (element.cate) {
            case "inputnum":
              that.$set(
                that.rules,
                [element.name],
                [
                  {
                    pattern: /^\d+(\.\d+)?$/,
                    message: element.title + "必须为数字值",
                    trigger: "blur"
                  }
                ]
              );
              break;
            default:
              // 暂无处理
              break;
          }
          if (element.is_must && element.is_must == 1) {
            if (that.rules[element.name]) {
              that.rules[element.name].push({
                required: true,
                message: element.title + "不能为空",
                trigger: ["change", "blur"]
              });
            } else {
              that.$set(
                that.rules,
                [element.name],
                [
                  {
                    required: true,
                    message: element.title + "不能为空",
                    trigger: ["change", "blur"]
                  }
                ]
              );
            }
          }
        });
      },
      deep: true,
      immediate: true
    }
  },
  filters: {
    toNumber(value) {
      return parseInt(value);
    }
  }
};
</script>
<style lang="scss">
.customForm {
  .el-input-number .el-input__inner {
    text-align: left;
  }
}
</style>
