<template>
  <div class="attrList" v-if="routeClass == 2">
    <search-form
      ref="searchform"
      :searcharray="searcharray"
      :span="6"
      @change="search"
      @reset="reset"
    ></search-form>
    <el-button type="primary" size="small" icon="el-icon-plus" @click="add()">
      添加
    </el-button>
    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%;margin-top:15px"
      row-key="id"
      border
      stripe
      lazy
      size="small"
      class="tableList"
    >
      <el-table-column prop="id" label="ID" align="center" width="80px">
      </el-table-column>
      <el-table-column prop="title" align="center" label="产品属性名称">
      </el-table-column>
      <el-table-column prop="c_time" align="center" label="创建时间">
      </el-table-column>
      <el-table-column prop="state" align="center" label="状态">
        <template slot-scope="scope">
          <el-tag type="success" size="mini" v-if="scope.row.status == 1">
            启用
          </el-tag>
          <el-tag type="danger" size="mini" v-else>禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="handleField(scope.row.id)">
            字段列表
          </el-button>
          <el-button type="text" size="mini" @click="handleEdit(scope.row.id)">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :page-sizes="pageSizeArray"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!--弹窗 -->
    <div class="attrPop">
      <el-dialog
        :title="popTitle"
        :visible.sync="dialogState"
        width="540px"
        @close="dialogClose"
      >
        <el-form
          :model="attrForm"
          :rules="rules"
          :validate-on-rule-change="false"
          ref="attrForm"
          label-width="150px"
          size="small"
          class="attrForm"
        >
          <el-form-item label="产品属性名称" prop="title">
            <el-input
              v-model="attrForm.title"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-radio v-model="attrForm.status" :label="1">启用</el-radio>
            <el-radio v-model="attrForm.status" :label="0">禁用</el-radio>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="dialogOk()">
            确 定
          </el-button>
          <el-button @click="dialogClose()" size="small">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
  <router-view v-else />
</template>
<script>
import searchForm from "@/components/common/searchForm";
import {
  changeProAttr,
  getProductAttrList,
  getProductAttrInfo
} from "@/api/productAdmin";
export default {
  name: "attrList",
  components: {
    searchForm
  },
  data() {
    return {
      routeClass: "",
      searcharray: [
        {
          label: "属性名称",
          val: "title",
          type: "input",
          pla: "请输入名称"
        }
      ],
      searchVal: {},
      tableData: [],
      // 分页 每页条数
      pageSize: 15,
      // 分页 每页条数可选项
      pageSizeArray: [15, 30, 45, 60],
      // 当前页
      page: 1,
      // 总页数
      total: 0,
      loading: false,
      popTitle: "添加",
      dialogState: false,
      attrForm: {
        title: "",
        status: 1,
        id: ""
      },
      rules: {
        title: {
          required: true,
          message: "产品属性名称不能为空",
          trigger: "blur"
        }
      }
    };
  },
  mounted() {
    // 获取当前路由等级
    this.routeClass = this.$route.matched.length;
    this.getProductAttrList();
  },
  methods: {
    //添加类别弹窗
    add() {
      this.popTitle = "添加";
      this.attrForm = {
        title: "",
        status: 1,
        id: ""
      };
      this.dialogState = true;
    },
    // 关闭弹窗
    dialogClose() {
      this.$nextTick(() => {
        this.$refs["attrForm"].resetFields();
      });
      this.dialogState = false;
    },
    // 弹窗确认
    dialogOk() {
      this.$refs.attrForm.validate(valid => {
        let that = this;
        if (valid) {
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          changeProAttr(this.attrForm)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.getProductAttrList();
                that.dialogClose();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //编辑类别弹窗
    handleEdit(e) {
      console.log(e);
      let that = this;
      let _data = {
        attr_id: e
      };
      getProductAttrInfo(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.popTitle = "编辑";
            that.attrForm = {
              title: res.data.title,
              status: res.data.status,
              id: res.data.id
            };
            that.dialogState = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    //字段列表
    handleField(e) {
      console.log(e);
      this.$router.push({
        path: "/productAdmin/attribute/fields",
        query: { id: e }
      });
    },
    search(e) {
      this.searchVal = e;
      this.page = 1;
      this.getProductAttrList();
    },
    reset() {
      this.page = 1;
      this.searchVal = {};
      this.getProductAttrList();
    },
    // 每页几条
    sizeChange() {
      console.log(1);
      this.getProductAttrList();
    },
    // 页码变化
    currentChange() {
      this.getProductAttrList();
    },
    //获取列表
    getProductAttrList() {
      let that = this;
      let _data = this.searchVal;
      _data.page = this.page;
      _data.limit = this.pageSize;
      that.loading = true;
      getProductAttrList(_data)
        .then(function(res) {
          console.log(res);
          that.loading = false;
          if (res.code == 1) {
            that.tableData = res.data.list;
            that.total = res.data.total;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>
<style lang="scss">
.attrList {
  padding: 30px;
  background: #fff;
  min-height: 100%;
  .attrPop .attrForm {
    width: calc(100% - 100px);
  }
}
</style>
