<template>
  <div class="groupRegular">
    <el-scrollbar>
      <el-form
        :model="regForm"
        :rules="regRules"
        :validate-on-rule-change="false"
        ref="regForm"
        label-width="150px"
        size="small"
        class="regForm"
      >
        <el-divider content-position="center">基础规则</el-divider>
        <el-form-item label="投保人数" prop="insure_num" class="inputW80">
          <div
            v-for="(item, index) in regForm.insure_num"
            :key="index"
            class="marBo0"
          >
            <el-form-item
              label=""
              :prop="`insure_num.${index}.startNum`"
              :rules="regRules.startNum"
              class="inline"
            >
              <el-input v-model="item.startNum"></el-input>
            </el-form-item>
            人，至
            <el-form-item
              label=""
              :prop="`insure_num.${index}.endNum`"
              :rules="regRules.endNum"
              class="inline"
            >
              <el-input v-model="item.endNum"></el-input>
            </el-form-item>
            人，男女占比：
            <el-form-item
              label=""
              :prop="`insure_num.${index}.sexNaRate`"
              :rules="regRules.sexNaRate"
              class="inline"
            >
              <el-input v-model="item.sexNaRate"></el-input>
            </el-form-item>
            %:
            <el-form-item
              label=""
              :prop="`insure_num.${index}.sexNvRate`"
              :rules="regRules.sexNvRate"
              class="inline"
            >
              <el-input v-model="item.sexNvRate"></el-input> </el-form-item
            >% ，投保系数：
            <el-form-item
              label=""
              :prop="`insure_num.${index}.insureRate`"
              :rules="regRules.insureRate"
              class="inline"
            >
              <el-input v-model="item.insureRate"></el-input>
            </el-form-item>
            <el-button
              v-if="index == 0"
              @click="addPersonNum"
              class="btn"
              type="primary"
            >
              添加
            </el-button>
            <el-button
              v-else
              type="danger"
              class="btn"
              @click="deletePersonNum(index)"
            >
              删除
            </el-button>
          </div>
          <div class="hint">
            根据保司提供规则设置，如：1-10,11-20。男女占比，举例：男70%，女30%，依次类推。
          </div>
        </el-form-item>
        <el-form-item label="承保年龄" prop="age" class="inputW80">
          <div class="marBo0">
            <el-form-item label="" prop="startAge" class="inline">
              <el-input v-model="regForm.startAge"></el-input>
            </el-form-item>
            —
            <el-form-item label="" prop="endAge" class="inline">
              <el-input v-model="regForm.endAge"></el-input>
            </el-form-item>
            <el-button type="text" @click="addAge" class="btn">
              添加特殊设置
            </el-button>
          </div>
          <div v-for="(item, index) in regForm.age" :key="index" class="marBo0">
            <el-form-item
              label=""
              :prop="`age.${index}.startAge`"
              :rules="regRules.startAge"
              class="inline"
            >
              <el-input v-model="item.startAge"></el-input>
            </el-form-item>
            —
            <el-form-item
              label=""
              :prop="`age.${index}.endAge`"
              :rules="regRules.endAge"
              class="inline"
            >
              <el-input v-model="item.endAge"></el-input>
            </el-form-item>
            ， 性别
            <el-form-item
              label=""
              :prop="`age.${index}.sex`"
              :rules="regRules.sex"
              class="inline"
            >
              <el-select v-model="item.sex" placeholder="请选择">
                <el-option label="不限" value="0"> </el-option>
                <el-option label="男" value="1"> </el-option>
                <el-option label="女" value="2"> </el-option>
              </el-select>
            </el-form-item>
            ， 在总人数的占比
            <el-form-item
              label=""
              :prop="`age.${index}.ageRange`"
              :rules="regRules.ageRange"
              class="inline"
            >
              <el-input v-model="item.ageRange"></el-input>
            </el-form-item>
            %
            <el-button type="danger" class="btn" @click="deleteAge(index)">
              删除
            </el-button>
          </div>
        </el-form-item>
        <el-form-item label="电子签章" prop="is_ele_sign">
          <el-radio v-model="regForm.is_ele_sign" label="1">可用</el-radio>
          <el-radio v-model="regForm.is_ele_sign" label="0">不可用</el-radio>
        </el-form-item>
        <template v-if="regForm.is_ele_sign == 1">
          <el-form-item label="电子签章位置" prop="sign_type">
            <el-radio v-model="regForm.sign_type" label="1">关键词</el-radio>
            <el-radio v-model="regForm.sign_type" label="2"
              >自定义位置</el-radio
            >
          </el-form-item>
          <el-form-item
            label="电子签章关键词"
            prop="sign_keywords"
            v-if="regForm.sign_type == 1"
          >
            <el-input
              v-model="regForm.sign_keywords"
              placeholder="多个关键词使用英文逗号隔开“,”"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="自定义位置"
            prop="sign_position"
            class="inputW80"
            v-else
          >
            <div
              v-for="(item, index) in regForm.sign_position"
              :key="index"
              class="marBo0"
            >
              第
              <el-form-item
                label=""
                :prop="`sign_position.${index}.signPage`"
                :rules="regRules.signPage"
                class="inline"
              >
                <el-input v-model="item.signPage"></el-input>
              </el-form-item>
              页，X坐标
              <el-form-item
                label=""
                :prop="`sign_position.${index}.signX`"
                :rules="regRules.signX"
                class="inline"
              >
                <el-input v-model="item.signX"></el-input>
              </el-form-item>
              Y坐标
              <el-form-item
                label=""
                :prop="`sign_position.${index}.signY`"
                :rules="regRules.signY"
                class="inline"
              >
                <el-input v-model="item.signY"></el-input>
              </el-form-item>
              <el-button
                v-if="index == 0"
                type="primary"
                @click="addSite"
                class="btn"
              >
                添加
              </el-button>
              <el-button
                v-else
                type="danger"
                class="btn"
                @click="deleteSite(index)"
              >
                删除
              </el-button>
            </div>
          </el-form-item>
        </template>
        <el-form-item label="是否指定投保人" prop="isAppointAapplication">
          <el-radio v-model="regForm.isAppointAapplication" label="0">
            否
          </el-radio>
          <el-radio v-model="regForm.isAppointAapplication" label="1">
            是
          </el-radio>
        </el-form-item>
        <template v-if="regForm.isAppointAapplication == 1">
          <el-form-item label="投保人名称" prop="applicationName">
            <el-input v-model="regForm.applicationName"></el-input>
          </el-form-item>
          <el-form-item label="投保人证件号" prop="applicationCode">
            <el-input v-model="regForm.applicationCode"></el-input>
          </el-form-item>
          <el-form-item label="法人" prop="applicationLegalPerson">
            <el-input v-model="regForm.applicationLegalPerson"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="applicationPhone">
            <el-input v-model="regForm.applicationPhone"></el-input>
          </el-form-item>
          <el-form-item label="地区" prop="applicationPCA">
            <el-cascader
              v-model="regForm.applicationPCA"
              :options="regionArray"
              :props="{ children: 'child', label: 'name', value: 'id' }"
              filterable
              style="width:100%"
              popper-class="cascaderRegion"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="经营地址" prop="applicationAddress">
            <el-input v-model="regForm.applicationAddress"></el-input>
          </el-form-item>
          <el-form-item label="营业执照" prop="applicationCodeImg">
            <click-upload v-model="regForm.applicationCodeImg"></click-upload>
          </el-form-item>
        </template>

        <el-form-item label="多职业承保" prop="work_cate_insure">
          <el-radio v-model="regForm.work_cate_insure" label="1">可以</el-radio>
          <el-radio v-model="regForm.work_cate_insure" label="2">
            不可以
          </el-radio>
        </el-form-item>
        <el-form-item label="职业类别费率设置" prop="aaaaaa">
          <el-radio v-model="regForm.is_work_type" label="0">
            不区分费率系数
          </el-radio>
          <el-radio v-model="regForm.is_work_type" label="1">
            区分费率系数
          </el-radio>
        </el-form-item>
        <el-form-item label="职业类别设置" prop="work_type">
          <div
            v-for="(item, index) in regForm.work_type"
            :key="index"
            class="marBo0 zhiye"
          >
            <el-form-item
              :prop="`work_type.${index}.work_cate`"
              :rules="regRules.work_cate"
              class="inline"
              >职业类别：
              <el-select
                v-model="item.work_cate"
                multiple
                filterable
                placeholder="请选择"
                style="width:100px;"
              >
                <el-option
                  v-for="(item, index) in workTypeArray"
                  :key="index"
                  :label="item"
                  :value="index + ''"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :prop="`work_type.${index}.insure_ratio`"
              :rules="regRules.insure_ratio"
              class="inline"
              v-if="regForm.is_work_type == 0"
              >投保系数 <el-input v-model="item.insure_ratio"></el-input>
            </el-form-item>
            <el-form-item
              label=""
              :prop="`work_type.${index}.person_num_ratio`"
              :rules="regRules.person_num_ratio"
              class="inline"
              >投保人数占比
              <el-input v-model="item.person_num_ratio"></el-input>%
            </el-form-item>
            <el-button
              v-if="index == 0"
              type="primary"
              @click="addWork"
              class="btn"
            >
              添加
            </el-button>
            <el-button
              v-else
              type="danger"
              class="btn"
              @click="deleteWork(index)"
            >
              删除
            </el-button>
          </div>
        </el-form-item>

        <el-divider content-position="center">投保批改费率</el-divider>
        <el-form-item label="投保、批改费率" prop="rate" class="tableBoxX">
          <table class="tableBox">
            <tr>
              <th>月份</th>
              <th style="min-width: 80px;">
                月保费率
              </th>
              <template v-if="regForm.is_work_type == 1">
                <template v-for="(item, index) in regForm.work_type">
                  <th
                    style="min-width: 115px;"
                    :key="index + 's'"
                    v-if="index != 0"
                  >
                    <span v-for="(v, i) in item.work_cate" :key="i + 'w'">
                      {{ workTypeArray[v] }}
                    </span>
                    ,月保费率
                  </th>
                </template>
              </template>
              <th style="min-width: 100px;">是否可投保</th>
              <th style="min-width: 80px;">
                <el-select
                  filterable
                  v-model="regForm.is_month_rate"
                  placeholder="请选择"
                  style="width:100px"
                >
                  <el-option label="批增月费率" value="0"> </el-option>
                  <el-option label="批增日费率" value="1"> </el-option>
                </el-select>
              </th>
              <template v-if="regForm.is_work_type == 1">
                <template v-for="(item, index) in regForm.work_type">
                  <th
                    style="min-width: 115px;"
                    :key="index + 'a'"
                    v-if="index != 0"
                  >
                    <span v-for="(v, i) in item.work_cate" :key="i + 'e'">
                      {{ workTypeArray[v] }}
                    </span>
                    ,批增费率
                  </th>
                </template>
              </template>
              <th style="min-width: 115px;">是否可增加人员</th>
              <th style="min-width: 115px;">是否可替换人员</th>
              <th style="min-width: 50px;">操作</th>
            </tr>
            <tr v-for="(item, index) in regForm.rate" :key="index">
              <td>
                <el-form-item
                  label=""
                  :prop="`rate.${index}.month`"
                  :rules="regRules.month"
                  class="inline"
                >
                  <el-select
                    v-model="item.month"
                    placeholder="请选择"
                    style="width:75px"
                    filterable
                    @change="changeMonth"
                  >
                    <el-option
                      v-for="(item, index) in moonArray"
                      :label="item.title"
                      :value="item.id + ''"
                      :disabled="item.disabled"
                      :key="index"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </td>
              <td>
                <el-form-item
                  label=""
                  :prop="`rate.${index}.month_rate`"
                  :rules="regRules.month_rate"
                  class="inline"
                >
                  <el-input
                    v-model="item.month_rate"
                    style="width:45px"
                  ></el-input>
                </el-form-item>
                %
              </td>
              <template v-if="regForm.is_work_type == 1">
                <template v-for="(v, i) in regForm.work_type">
                  <td :key="i + 'm'" v-if="i != 0">
                    <el-form-item
                      label=""
                      :prop="'rate.' + index + '.month_rate_array.' + [i - 1]"
                      :rules="regRules.month_rate_array"
                      class="inline"
                    >
                      <el-input
                        v-model="item.month_rate_array[i - 1]"
                        style="width:45px"
                      ></el-input>
                    </el-form-item>
                    %
                  </td>
                </template>
              </template>
              <td>
                <el-form-item label="" :prop="`rate.${index}.is_insure`">
                  <el-checkbox
                    v-model="item.is_insure"
                    true-label="1"
                    false-label="0"
                    >可投保</el-checkbox
                  >
                </el-form-item>
              </td>
              <td>
                <el-form-item
                  label=""
                  :prop="`rate.${index}.correct_rate`"
                  :rules="regRules.correct_rate"
                  class="inline"
                >
                  <el-input
                    v-model="item.correct_rate"
                    style="width:45px"
                  ></el-input>
                </el-form-item>
                %
              </td>
              <template v-if="regForm.is_work_type == 1">
                <template v-for="(v, i) in regForm.work_type">
                  <td :key="i + 'c'" v-if="i != 0">
                    <el-form-item
                      label=""
                      :prop="'rate.' + index + '.correct_rate_array.' + [i - 1]"
                      :rules="regRules.correct_rate_array"
                      class="inline"
                    >
                      <el-input
                        v-model="item.correct_rate_array[i - 1]"
                        style="width:45px"
                      ></el-input>
                    </el-form-item>
                    %
                  </td>
                </template>
              </template>
              <td>
                <el-form-item
                  label=""
                  :prop="`rate.${index}.is_add_person`"
                  class="inline"
                >
                  <el-checkbox
                    v-model="item.is_add_person"
                    true-label="1"
                    false-label="0"
                    >可增加</el-checkbox
                  >
                </el-form-item>
              </td>
              <td>
                <el-form-item
                  label=""
                  :prop="`rate.${index}.is_change_person`"
                  class="inline"
                >
                  <el-checkbox
                    v-model="item.is_change_person"
                    true-label="1"
                    false-label="0"
                    >可替换</el-checkbox
                  >
                </el-form-item>
              </td>
              <td>
                <el-button
                  v-if="index == 0 && isAddbtn"
                  type="primary"
                  @click="addRate"
                  style="padding:5px"
                >
                  添加
                </el-button>
                <el-button
                  v-else
                  type="danger"
                  style="padding:5px"
                  @click="deleteRate(index)"
                >
                  删除
                </el-button>
              </td>
            </tr>
          </table>
        </el-form-item>
        <el-divider content-position="center">日期规则</el-divider>
        <el-form-item label="投保生效日期" prop="start_time_date">
          <el-input v-model.number="regForm.start_time_date"></el-input>
          <div class="hint">
            指定生效时间，T+N。
          </div>
        </el-form-item>
        <el-form-item label="投保时间是否可选" prop="is_insure_option">
          <el-radio-group v-model="regForm.is_insure_option">
            <el-radio label="1"> 是 </el-radio>
            <el-radio label="0"> 否 </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="投保提前购买天数"
          prop="insure_advance_days"
          v-if="regForm.is_insure_option == 1"
        >
          <el-input v-model.number="regForm.insure_advance_days"></el-input>
        </el-form-item>
        <el-form-item label="投保限制">
          <el-form-item label="" prop="is_insure_cut_off">
            <el-checkbox
              v-model="regForm.is_insure_cut_off"
              true-label="1"
              false-label="0"
              >当日截止时间
            </el-checkbox>
            <el-form-item
              label=""
              prop="insure_cut_off_time"
              :rules="
                regForm.is_insure_cut_off == 1
                  ? regRules.insure_cut_off_time
                  : []
              "
              style="margin:0;display:inline-block"
            >
              <el-time-select
                v-model="regForm.insure_cut_off_time"
                :picker-options="{
                  start: '00:00',
                  step: '00:10',
                  end: '23:59'
                }"
                placeholder="选择截单时间"
                style="margin-left:10px;width:150px"
              >
              </el-time-select>
            </el-form-item>
            <div class="hint">
              指定时间前提交材料，T+1生效反之T+2生效，并且T+1时间不能为法定节假日。
            </div>
          </el-form-item>
          <el-form-item label="" prop="insure_week_is_work">
            <el-checkbox
              v-model="regForm.insure_week_is_work"
              true-label="1"
              false-label="0"
              >执行法定休假日及周六、日。
            </el-checkbox>
            <div class="hint">
              如果不勾选，则视周六、日也为工作日。
            </div>
          </el-form-item>
          <el-form-item label="" prop="is_insure_temporary_overtime">
            <el-checkbox
              v-model="regForm.is_insure_temporary_overtime"
              true-label="1"
              false-label="0"
              >执行临时加班时间
            </el-checkbox>
            <el-form-item
              label=""
              prop="insure_temporary_overtime"
              :rules="
                regForm.is_insure_temporary_overtime == 1
                  ? regRules.insure_temporary_overtime
                  : []
              "
              style="margin:0;display:inline-block"
            >
              <el-date-picker
                type="dates"
                v-model="regForm.insure_temporary_overtime"
                placeholder="选择一个或多个日期"
                value-format="yyyy-MM-dd"
                style="margin-left:10px"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-form-item>
          <el-form-item label="" prop="is_insure_temporary_off_time">
            <el-checkbox
              v-model="regForm.is_insure_temporary_off_time"
              true-label="1"
              false-label="0"
              >执行临时休班时间
            </el-checkbox>
            <el-form-item
              label=""
              prop="insure_temporary_off_time"
              :rules="
                regForm.is_insure_temporary_off_time == 1
                  ? regRules.insure_temporary_off_time
                  : []
              "
              style="margin:0;display:inline-block"
            >
              <el-date-picker
                type="dates"
                v-model="regForm.insure_temporary_off_time"
                placeholder="选择一个或多个日期"
                value-format="yyyy-MM-dd"
                style="margin-left:10px"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-form-item>
          <el-form-item
            label=""
            prop="insure_temporary_cut_off_time"
            style="margin:0;display:inline-block"
            >临时调整截单时间
            <el-date-picker
              v-model="regForm.insure_temporary_cut_off_time"
              type="datetime"
              placeholder="选择截单时间"
              value-format="yyyy-MM-dd HH:mm"
              style="margin-left:10px;width:200px"
            >
            </el-date-picker>
          </el-form-item>
          <div class="hint">
            该时间节点用来设置指定日期截单时间，一次性时效。
          </div>
        </el-form-item>
        <el-form-item label="批改生效日期" prop="correction_start_time_date">
          <el-input
            v-model.number="regForm.correction_start_time_date"
          ></el-input>
          <div class="hint">
            指定生效时间，T+N。
          </div>
        </el-form-item>
        <el-form-item label="批改时间是否可选" prop="is_correction_option">
          <el-radio-group v-model="regForm.is_correction_option">
            <el-radio label="1"> 是 </el-radio>
            <el-radio label="0"> 否 </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="批改提前购买天数"
          prop="correction_advance_days"
          v-if="regForm.is_correction_option == 1"
        >
          <el-input v-model.number="regForm.correction_advance_days"></el-input>
        </el-form-item>
        <el-form-item label="批改限制">
          <el-form-item label="" prop="is_correction_cut_off">
            <el-checkbox
              v-model="regForm.is_correction_cut_off"
              true-label="1"
              false-label="0"
              >当日截单时间
            </el-checkbox>
            <el-form-item
              label=""
              prop="correction_cut_off_time"
              :rules="
                regForm.is_correction_cut_off == 1
                  ? regRules.correction_cut_off_time
                  : []
              "
              style="margin:0;display:inline-block"
            >
              <el-time-select
                v-model="regForm.correction_cut_off_time"
                :picker-options="{
                  start: '00:00',
                  step: '00:10',
                  end: '23:59'
                }"
                placeholder="选择截单时间"
                style="margin-left:10px;width:150px"
              >
              </el-time-select>
            </el-form-item>
            <div class="hint">
              指定时间前提交材料，T+1生效反之T+2生效，并且T+1时间不能为法定节假日。
            </div>
          </el-form-item>
          <el-form-item label="" prop="correction_week_is_work">
            <el-checkbox
              v-model="regForm.correction_week_is_work"
              true-label="1"
              false-label="0"
              >执行法定休假日及周六、日。
            </el-checkbox>
            <div class="hint">
              如果不勾选，则视周六、日也为工作日。
            </div>
          </el-form-item>
          <el-form-item label="" prop="is_correction_temporary_overtime">
            <el-checkbox
              v-model="regForm.is_correction_temporary_overtime"
              true-label="1"
              false-label="0"
              >执行临时加班时间
            </el-checkbox>
            <el-form-item
              label=""
              prop="correction_temporary_overtime"
              :rules="
                regForm.is_correction_temporary_overtime == 1
                  ? regRules.correction_temporary_overtime
                  : []
              "
              style="margin:0;display:inline-block"
            >
              <el-date-picker
                type="dates"
                v-model="regForm.correction_temporary_overtime"
                placeholder="选择一个或多个日期"
                value-format="yyyy-MM-dd"
                style="margin-left:10px"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-form-item>
          <el-form-item label="" prop="is_correction_temporary_off_time">
            <el-checkbox
              v-model="regForm.is_correction_temporary_off_time"
              true-label="1"
              false-label="0"
              >执行临时休班时间
            </el-checkbox>
            <el-form-item
              label=""
              prop="correction_temporary_off_time"
              :rules="
                regForm.is_correction_temporary_off_time == 1
                  ? regRules.correction_temporary_off_time
                  : []
              "
              style="margin:0;display:inline-block"
            >
              <el-date-picker
                type="dates"
                v-model="regForm.correction_temporary_off_time"
                placeholder="选择一个或多个日期"
                value-format="yyyy-MM-dd"
                style="margin-left:10px"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-form-item>
          <el-form-item
            label=""
            prop="correction_temporary_cut_off_time"
            style="margin:0;display:inline-block"
            >临时调整截单时间
            <el-date-picker
              v-model="regForm.correction_temporary_cut_off_time"
              type="datetime"
              placeholder="选择截单时间"
              value-format="yyyy-MM-dd HH:mm"
              style="margin-left:10px;width:200px"
            >
            </el-date-picker>
          </el-form-item>
          <div class="hint">
            该时间设置只用来设置当天截单时间（当日一次性时效）。
          </div>
        </el-form-item>
      </el-form>
    </el-scrollbar>
    <div class="btnBox">
      <el-button type="primary" size="small" @click="confirm()">确定</el-button>
      <el-button size="small" @click="cancel()">取消</el-button>
    </div>
  </div>
</template>
<script>
import clickUpload from "@/components/common/clickUpload";
import { changeProductRule, getProductRule } from "@/api/productAdmin";
import { getAreaList } from "@/api/public";
export default {
  name: "groupRegular",
  components: {
    clickUpload
  },
  props: {
    id: {
      type: [Number, String],
      default: ""
    },
    status: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      regionArray: [], //地区数组
      workTypeArray: {
        1: "一类",
        2: "二类",
        3: "三类",
        4: "四类",
        5: "五类",
        6: "六类",
        7: "七类",
        8: "八类",
        9: "九类",
        10: "十类",
        11: "十一类",
        12: "十二类",
        13: "十三类",
        14: "十四类",
        15: "十五类",
        16: "十六类",
        17: "十七类",
        18: "十八类",
        19: "十九类",
        20: "二十类"
      },
      moonArray: [
        { id: 1, title: "一个月", disabled: false },
        { id: 2, title: "二个月", disabled: false },
        { id: 3, title: "三个月", disabled: false },
        { id: 4, title: "四个月", disabled: false },
        { id: 5, title: "五个月", disabled: false },
        { id: 6, title: "六个月", disabled: false },
        { id: 7, title: "七个月", disabled: false },
        { id: 8, title: "八个月", disabled: false },
        { id: 9, title: "九个月", disabled: false },
        { id: 10, title: "十个月", disabled: false },
        { id: 11, title: "十一个月", disabled: false },
        { id: 12, title: "一年", disabled: false }
      ],
      regForm: {
        insure_num: [
          {
            startNum: "",
            endNum: "",
            sexNaRate: "0",
            sexNvRate: "0",
            insureRate: ""
          }
        ],
        startAge: "",
        endAge: "",
        age: [],
        is_ele_sign: "1",
        sign_type: "1",
        sign_keywords: "",
        sign_position: [{ signPage: "", signX: "", signY: "" }],
        // 是否指定投保人
        isAppointAapplication: "0",
        applicationName: "",
        applicationCode: "",
        applicationLegalPerson: "",
        applicationPhone: "",
        applicationPCA: "",
        applicationAddress: "",
        applicationCodeImg: "",
        // 是否区分职业类别费率 0不区分1区分
        is_work_type: "0",
        // 日费率还是月费率
        is_month_rate: "0",
        // 费率
        rate: [
          {
            month: "",
            month_rate: "",
            is_insure: "1",
            correct_rate: "",
            is_add_person: "1",
            is_change_person: "1",
            month_rate_array: [],
            correct_rate_array: []
          }
        ],
        start_time_date: "1",
        is_insure_option: "1",
        insure_advance_days: "",
        is_insure_cut_off: "0",
        insure_cut_off_time: "",
        is_insure_temporary_cut_off: "1",
        insure_temporary_cut_off_time: "",
        insure_week_is_work: "0",
        is_insure_temporary_overtime: "0",
        insure_temporary_overtime: "",
        is_insure_temporary_off_time: "0",
        insure_temporary_off_time: "",

        correction_start_time_date: "1",
        is_correction_option: "1",
        correction_advance_days: "",
        is_correction_cut_off: "0",
        correction_cut_off_time: "",
        is_correction_temporary_cut_off: "1",
        correction_temporary_cut_off_time: "",
        correction_week_is_work: "0",
        is_correction_temporary_overtime: "0",
        correction_temporary_overtime: "",
        is_correction_temporary_off_time: 0,
        correction_temporary_off_time: "",
        work_cate_insure: "2",
        work_type: [{ work_cate: "", insure_ratio: "", person_num_ratio: "" }]
      },
      isAddbtn: true,
      regRules: {
        startNum: [
          { required: true, message: "投保人数下限不能为空", trigger: "blur" }
        ],
        endNum: [
          { required: true, message: "投保人数上限不能为空", trigger: "blur" }
        ],
        sexNaRate: [
          { required: true, message: "男女比例，男不能为空", trigger: "blur" }
        ],
        sexNvRate: [
          { required: true, message: "男女比例，女不能为空", trigger: "blur" }
        ],
        insureRate: [
          { required: true, message: "投保系数不能为空", trigger: "blur" }
        ],
        startAge: [
          { required: true, message: "年龄下限不能为空", trigger: "blur" }
        ],
        endAge: [
          { required: true, message: "年龄上限不能为空", trigger: "blur" }
        ],
        sex: [
          {
            required: true,
            message: "性别不能为空",
            trigger: ["blur", "change"]
          }
        ],
        ageRange: [
          { required: true, message: "年龄占比不能为空", trigger: "blur" }
        ],
        signPage: [
          { required: true, message: "页码不能为空", trigger: "blur" }
        ],
        signX: [{ required: true, message: "X坐标不能为空", trigger: "blur" }],
        signY: [{ required: true, message: "Y坐标不能为空", trigger: "blur" }],
        month: [{ required: true, message: "月份不能为空", trigger: "blur" }],
        month_rate: [
          { required: true, message: "月保系数不能为空", trigger: "blur" }
        ],
        correct_rate: [
          { required: true, message: "批改费率不能为空", trigger: "blur" }
        ],
        insure_advance_days: [
          {
            required: true,
            message: "投保提前购买天数不能为空",
            trigger: "blur"
          },
          {
            pattern: /^\d+$/,
            message: "投保提前购买天数为整数",
            trigger: "blur"
          }
        ],
        insure_cut_off_time: [
          { required: true, message: "投保截止时间不能为空", trigger: "blur" }
        ],
        insure_temporary_overtime: [
          {
            required: true,
            message: "投保临时加班时间不能为空",
            trigger: "blur"
          }
        ],
        insure_temporary_off_time: [
          {
            required: true,
            message: "投保临时休班时间不能为空",
            trigger: "blur"
          }
        ],
        correction_advance_days: [
          {
            required: true,
            message: "批改提前购买天数不能为空",
            trigger: "blur"
          },
          {
            pattern: /^\d+$/,
            message: "批改提前购买天数为整数",
            trigger: "blur"
          }
        ],
        correction_cut_off_time: [
          { required: true, message: "批改截止时间不能为空", trigger: "blur" }
        ],
        correction_temporary_overtime: [
          {
            required: true,
            message: "批改临时加班时间不能为空",
            trigger: "blur"
          }
        ],
        correction_temporary_off_time: [
          {
            required: true,
            message: "批改临时休班时间不能为空",
            trigger: "blur"
          }
        ],
        work_cate: [
          {
            required: true,
            message: "职业类别不能为空",
            trigger: ["blur", "change"]
          }
        ],
        insure_ratio: [
          { required: true, message: "投保费率不能为空", trigger: "blur" }
        ],
        person_num_ratio: [
          {
            required: true,
            message: "职业类别投保人数占比不能为空",
            trigger: "blur"
          }
        ],
        start_time_date: [
          {
            required: true,
            message: "投保生效日期不能为空",
            trigger: "blur"
          },
          {
            pattern: /^\d+$/,
            message: "投保生效日期为整数",
            trigger: "blur"
          }
        ],
        correction_start_time_date: [
          {
            required: true,
            message: "批改生效日期不不能为空",
            trigger: "blur"
          },
          {
            pattern: /^\d+$/,
            message: "批改生效日期为整数",
            trigger: "blur"
          }
        ],
        month_rate_array: [
          {
            required: true,
            message: "费率不不能为空",
            trigger: "blur"
          }
        ],
        correct_rate_array: [
          {
            required: true,
            message: "费率不不能为空",
            trigger: "blur"
          }
        ]
      },
      pickerOptions: {
        disabledDate(time) {
          let n = Date.now() - 86400001;
          return time.getTime() < n;
        }
      }
    };
  },
  mounted() {
    this.getProductRule();
    this.getAreaList();
  },
  methods: {
    //添加投保人数设置
    addPersonNum() {
      this.regForm.insure_num.push({
        startNum: "",
        endNum: "",
        sexNaRate: "0",
        sexNvRate: "0",
        insureRate: ""
      });
    },
    deletePersonNum(index) {
      this.regForm.insure_num.splice(index, 1);
    },
    //添加特殊年龄段
    addAge() {
      this.regForm.age.push({
        startAge: "",
        endAge: "",
        sex: "",
        ageRange: ""
      });
    },
    deleteAge(index) {
      this.regForm.age.splice(index, 1);
    },
    //添加电子章位置
    addSite() {
      this.regForm.sign_position.push({ signPage: "", signX: "", signY: "" });
    },
    deleteSite(index) {
      this.regForm.sign_position.splice(index, 1);
    },
    //添加职业类别设置
    addWork() {
      let that = this;
      that.regForm.work_type.push({
        work_cate: "",
        insure_ratio: "1",
        person_num_ratio: ""
      });

      that.regForm.rate.forEach(element => {
        that.regForm.work_type.forEach((v, i) => {
          if (i != 0) {
            element.month_rate_array[i - 1] = "1";
          }
        });
      });
      that.regForm.rate.forEach(element => {
        that.regForm.work_type.forEach((v, i) => {
          if (i != 0) {
            element.correct_rate_array[i - 1] = "1";
          }
        });
      });
    },
    deleteWork(index) {
      let that = this;
      that.regForm.work_type.splice(index, 1);
      that.regForm.rate.forEach(element => {
        element.month_rate_array.splice(index - 1, 1);
      });
      that.regForm.rate.forEach(element => {
        element.correct_rate_array.splice(index - 1, 1);
      });
    },
    //添加投保批改费率
    addRate() {
      this.regForm.rate.push({
        month: "",
        month_rate: "",
        is_insure: "1",
        correct_rate: "",
        is_add_person: "1",
        is_change_person: "1",
        month_rate_array: [],
        correct_rate_array: []
      });
      if (this.regForm.rate.length >= 12) {
        this.isAddbtn = false;
      }
      this.changeMonth();
    },
    deleteRate(index) {
      this.regForm.rate.splice(index, 1);
      if (this.regForm.rate.length < 12) {
        this.isAddbtn = true;
      }
      this.changeMonth();
    },
    // 点击确认
    confirm() {
      let that = this;
      that.$refs.regForm.validate((valid, data) => {
        if (valid) {
          if (that.regForm.is_month_rate == 0) {
            let _month = that.regForm.rate.map(item => parseInt(item.month));
            var _max = Math.max.apply(null, _month);
            let _monthType = true;
            for (let index = 1; index <= _max; index++) {
              if (!_month.includes(index)) {
                _monthType = false;
                break;
              }
            }
            if (!_monthType) {
              that.$notification.error({
                title: "错误",
                message: "批改-批增月费率时保障期限必须完善1~" + _max + "个月"
              });
            } else {
              that.changeProductRule();
            }
          } else {
            that.changeProductRule();
          }
        } else {
          for (let key in data) {
            data[key].forEach(element => {
              setTimeout(function() {
                that.$notification.error({
                  title: "错误",
                  message: element.message
                });
              }, 10);
            });
          }
          that.$nextTick(() => {
            document.querySelector(".is-error").scrollIntoView();
          });
          return false;
        }
      });
    },
    // 取消
    cancel() {
      this.$emit("update:status", false);
    },
    // 获取数据
    getProductRule() {
      let that = this;
      let _data = { pro_id: that.id };
      getProductRule(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            if (res.data != "") {
              that.regForm.insure_num = res.data.base_rule.insure_num;
              if (res.data.base_rule.insure_age.ageRange) {
                that.regForm.age = res.data.base_rule.insure_age.ageRange;
              }
              // 转换基础规则
              let _array = [
                "is_ele_sign", //是否启用电子签章
                "sign_type", //电子签章方式
                "sign_keywords", //签章关键词
                "sign_position", //签章自定义位置坐标
                "isAppointAapplication", //是否指定投保人
                "applicationName", // 投保人名称
                "applicationCode", //投保人证件号
                "applicationLegalPerson", //法人：
                "applicationPhone", //手机号码：
                "applicationAddress", //经营地址：
                "applicationCodeImg", //营业执照
                "work_type", //职业类别规则设置
                "work_cate_insure", //多类别承保设置
                "is_work_type" //职业类别费率设置
              ];
              that.regForm.applicationPCA = [];
              that.regForm.applicationPCA.push(
                parseInt(res.data.base_rule.applicationProvince)
              );
              that.regForm.applicationPCA.push(
                parseInt(res.data.base_rule.applicationCity)
              );
              that.regForm.applicationPCA.push(
                parseInt(res.data.base_rule.applicationArea)
              );

              that.transferData(_array, "base_rule", res.data);

              that.regForm.endAge = res.data.base_rule.insure_age.endAge;
              that.regForm.startAge = res.data.base_rule.insure_age.startAge;
              that.regForm.is_month_rate = res.data.insure_rule.is_month_rate;
              let _rate = [];
              res.data.insure_rule.month.forEach((v, i) => {
                let _obj = {};
                _obj.month = v;
                _obj.is_insure = res.data.insure_rule.is_insure[i];
                _obj.correct_rate = res.data.insure_rule.correct_rate[i];
                _obj.is_add_person = res.data.insure_rule.is_add_person[i];
                _obj.is_change_person =
                  res.data.insure_rule.is_change_person[i];
                _obj.month_rate = res.data.insure_rule.month_rate[i];
                _obj.month_rate_array = []; //创建接收投保系数
                _obj.correct_rate_array = []; //创建接收批曾系数
                res.data.base_rule.work_type.forEach((element, index) => {
                  if (index != 0) {
                    let _key = element.work_cate.join("_");
                    if (res.data.insure_rule["month_rate_" + _key]) {
                      _obj.month_rate_array.push(
                        res.data.insure_rule["month_rate_" + _key][i]
                      );
                    } else {
                      _obj.month_rate_array.push("1");
                    }
                    if (res.data.insure_rule["correct_rate_" + _key]) {
                      _obj.correct_rate_array.push(
                        res.data.insure_rule["correct_rate_" + _key][i]
                      );
                    } else {
                      _obj.correct_rate_array.push("1");
                    }
                  }
                });
                _rate.push(_obj);
              });
              that.regForm.rate = _rate;
              if (that.regForm.rate.length >= 12) {
                that.isAddbtn = false;
              }

              // 转换时间规则
              let _array2 = [
                "is_insure_option", //投保时间是否可选
                "insure_advance_days", //投保提前购买天数
                "is_insure_cut_off", //投保是否设置截单时间
                "insure_cut_off_time", //投保截单时间
                "is_insure_temporary_cut_off", //投保是否设置临时截单时间
                "insure_temporary_cut_off_time", // 投保临时截单时间
                "insure_week_is_work", //周六日是否为休假日
                "is_insure_temporary_overtime", //是否执行临时加班时间
                "insure_temporary_overtime", //临时加班时间
                "is_insure_temporary_off_time", //是否执行临时休班时间
                "insure_temporary_off_time", //临时休班时间
                "is_correction_option", //批改时间是否可选
                "correction_advance_days", //批改提前购买天数
                "is_correction_cut_off", //批改是否设置截单时间
                "correction_cut_off_time", //批改截单时间
                "is_correction_temporary_cut_off", //批改是否设置临时截单时间
                "correction_temporary_cut_off_time", //批改临时截单时间
                "correction_week_is_work", //批改是否执行周六日为休假日
                "is_correction_temporary_overtime", //批改是否执行临时加班时间
                "correction_temporary_overtime", //批改临时加班时间
                "is_correction_temporary_off_time", //批改是否执行临时休班时间
                "correction_temporary_off_time", //批改临时休班时间
                "start_time_date", //投保生效日期
                "correction_start_time_date" //批改生效日期
              ];
              that.transferData(_array2, "time_rule", res.data);
            }
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 提交数据
    changeProductRule() {
      let that = this;
      let month = that.objArray(that.regForm.rate, "month");
      let month_rate = that.objArray(that.regForm.rate, "month_rate");
      let correct_rate = that.objArray(that.regForm.rate, "correct_rate");
      let is_insure = that.objArray(that.regForm.rate, "is_insure");
      let is_add_person = that.objArray(that.regForm.rate, "is_add_person");
      let is_change_person = that.objArray(
        that.regForm.rate,
        "is_change_person"
      );
      let _data = {
        pro_id: that.id,
        base_rule: {
          insure_num: that.regForm.insure_num,
          insure_age: {
            ageRange: that.regForm.age,
            startAge: that.regForm.startAge,
            endAge: that.regForm.endAge
          },
          is_ele_sign: that.regForm.is_ele_sign,
          sign_type: that.regForm.sign_type,
          sign_keywords: that.regForm.sign_keywords,
          sign_position: that.regForm.sign_position,
          work_type: that.regForm.work_type,
          work_cate_insure: that.regForm.work_cate_insure,
          isAppointAapplication: that.regForm.isAppointAapplication,
          applicationName: that.regForm.applicationName,
          applicationCode: that.regForm.applicationCode,
          applicationLegalPerson: that.regForm.applicationLegalPerson,
          applicationPhone: that.regForm.applicationPhone,
          applicationAddress: that.regForm.applicationAddress,
          applicationCodeImg: that.regForm.applicationCodeImg,
          applicationProvince: that.regForm.applicationPCA[0],
          applicationCity: that.regForm.applicationPCA[1],
          applicationArea: that.regForm.applicationPCA[2],

          is_work_type: that.regForm.is_work_type
        },
        insure_rule: {
          month: month,
          month_rate: month_rate,
          correct_rate: correct_rate,
          is_month_rate: that.regForm.is_month_rate,
          is_insure: is_insure,
          is_add_person: is_add_person,
          is_change_person: is_change_person
        },
        time_rule: {
          start_time_date: that.regForm.start_time_date,
          correction_start_time_date: that.regForm.correction_start_time_date,
          is_insure_option: that.regForm.is_insure_option,
          insure_advance_days: that.regForm.insure_advance_days,
          is_insure_cut_off: that.regForm.is_insure_cut_off,
          insure_cut_off_time: that.regForm.insure_cut_off_time,
          is_insure_temporary_cut_off: that.regForm.is_insure_temporary_cut_off,
          insure_temporary_cut_off_time:
            that.regForm.insure_temporary_cut_off_time,
          insure_week_is_work: that.regForm.insure_week_is_work,
          is_insure_temporary_overtime:
            that.regForm.is_insure_temporary_overtime,
          insure_temporary_overtime: that.regForm.insure_temporary_overtime,
          is_insure_temporary_off_time:
            that.regForm.is_insure_temporary_off_time,
          insure_temporary_off_time: that.regForm.insure_temporary_off_time,
          is_correction_option: that.regForm.is_correction_option,
          correction_advance_days: that.regForm.correction_advance_days,
          is_correction_cut_off: that.regForm.is_correction_cut_off,
          correction_cut_off_time: that.regForm.correction_cut_off_time,
          is_correction_temporary_cut_off:
            that.regForm.is_correction_temporary_cut_off,
          correction_temporary_cut_off_time:
            that.regForm.correction_temporary_cut_off_time,
          correction_week_is_work: that.regForm.correction_week_is_work,
          is_correction_temporary_overtime:
            that.regForm.is_correction_temporary_overtime,
          correction_temporary_overtime:
            that.regForm.correction_temporary_overtime,
          is_correction_temporary_off_time:
            that.regForm.is_correction_temporary_off_time,
          correction_temporary_off_time:
            that.regForm.correction_temporary_off_time
        }
      };
      that.regForm.work_type.forEach((element, index) => {
        if (index != 0) {
          let _key = element.work_cate.join("_");
          console.log(_key);
          let _val = [];
          let _val2 = [];
          that.regForm.rate.forEach(element => {
            _val.push(element.month_rate_array[index - 1]);
            _val2.push(element.correct_rate_array[index - 1]);
          });
          that.$set(_data.insure_rule, "month_rate_" + _key, _val);
          that.$set(_data.insure_rule, "correct_rate_" + _key, _val2);
        }
      });
      console.log(_data);
      let loadind = that.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      changeProductRule(_data)
        .then(function(res) {
          console.log(res);
          loadind.close();
          if (res.code == 1) {
            that.$message.success(res.msg);
            that.$emit("upStatus", true);
            that.cancel();
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 数据处理工具
    objArray(data, key) {
      let _data = [];
      data.forEach(element => {
        _data.push(element[key]);
      });
      return _data;
    },
    // 处理投保批改费率月份
    changeMonth() {
      let that = this;
      that.moonArray.forEach(element => {
        element.id;
        for (let index = 0; index < that.regForm.rate.length; index++) {
          element.disabled = false;
          if (that.regForm.rate[index].month == element.id) {
            element.disabled = true;
            break;
          }
        }
      });
    },
    // 获取数据后中转绑定
    transferData(array, field, res) {
      let that = this;
      array.forEach(element => {
        if (res[field][element]) {
          that.regForm[element] = res[field][element];
        }
      });
    },
    // 获取地区
    getAreaList() {
      let that = this;
      getAreaList()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.regionArray = res.data;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>
<style lang="scss">
.groupRegular {
  .el-scrollbar__wrap {
    height: calc(100vh - 201px);
    overflow-x: hidden;
    margin-bottom: 0 !important;
  }
  .btnBox {
    text-align: center;
    padding: 10px 25px;
    background: #efefef;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -30px;
    .el-button {
      width: 80px;
    }
    .el-button + .el-button {
      margin-left: 25px;
    }
  }
  .el-form {
    width: calc(100% - 60px);
    margin-top: -20px;
    .el-divider {
      margin-top: 50px;
      margin-bottom: 30px;
      .el-divider__text {
        font-size: 18px;
        font-weight: bold;
      }
    }
    .hint {
      font-size: 12px;
      line-height: 1.5;
      margin-top: 5px;
      color: #f56c6c;
    }
    .el-form-item__error {
      display: none;
    }
    .inputW80 {
      input {
        width: 45px;
        padding: 0 5px;
      }
      .btn {
        margin-left: 20px;
        padding: 5px;
      }
    }
    .marBo0 .el-form-item {
      margin-bottom: 0;
    }
    .marBo0 + .marBo0 {
      margin-top: 15px;
    }
    .inline {
      display: inline-block;
    }
    .tableBox {
      width: 100%;
      border-spacing: 0;
      tr th {
        font-weight: 500;
      }
      td {
        text-align: center;
        padding: 5px 0;
        .el-form-item {
          margin-bottom: 0;
        }
      }
      input {
        padding: 0 5px;
      }
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #606266;
    }
    .zhiye {
      .el-form-item__content {
        margin-right: 15px;
        & > .el-input {
          width: 50px;
          padding: 0 5px;
          input {
            padding: 0 5px;
          }
        }
      }
      .btn {
        padding: 5px;
      }
    }
    .tableBoxX > .el-form-item__content {
      overflow-x: auto;
    }
  }
}
</style>
