<template>
  <div class="accountList">
    <search-form
      :searcharray="searcharray"
      :span="8"
      @change="search"
      @reset="reset"
      class="releSearch"
    ></search-form>
    <el-button type="primary" size="small" @click="addAccountBtn">
      添加账户
    </el-button>
    <el-table
      :data="tableData"
      style="width: 100%;margin-top:15px"
      row-key="id"
      border
      stripe
      lazy
      size="small"
    >
      <el-table-column fixed prop="id" label="ID" width="80px" align="center">
      </el-table-column>
      <el-table-column prop="name" align="center" label="名称">
      </el-table-column>
      <el-table-column prop="post" align="center" fixed label="岗位">
        <template slot-scope="scope">
          <el-tag size="mini">
            {{ scope.row.post }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="phone" align="center" label="电话">
      </el-table-column>
      <el-table-column prop="channel" align="center" label="所属渠道">
      </el-table-column>
      <el-table-column prop="state" align="center" label="状态">
        <template slot-scope="scope">
          <el-tag type="success" size="mini" v-if="scope.row.state == 1">
            正常
          </el-tag>
          <el-tag type="danger" size="mini" v-else>停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="l_time" align="center" label="最后登陆时间">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="handleEdit(scope.row.id)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :page-sizes="pageSizeArray"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗 -->
    <el-dialog
      :title="accountTitle"
      :visible="accountVisible"
      @close="closeAccountBtn"
      width="600px"
    >
      <el-form
        :model="accountForm"
        :rules="rules"
        :validate-on-rule-change="false"
        ref="accountForm"
        label-width="100px"
        class="accountForm"
      >
        <el-form-item label="所属岗位" prop="post">
          <el-select
            filterable
            v-model="accountForm.post"
            placeholder="请选择"
            size="small"
            style="width:100%;"
          >
            <el-option label="无" :value="0"> </el-option>
            <template v-for="item in postList">
              <el-option :label="item.label" :value="item.id" :key="item.id">
              </el-option>
              <template v-if="item.child">
                <template v-if="item.child.length > 0">
                  <el-option
                    v-for="v in item.child"
                    :label="v.label"
                    :value="v.id"
                    :key="v.id"
                  ></el-option>
                </template>
              </template>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="账号" prop="username">
          <el-input
            v-model="accountForm.username"
            placeholder="请输入内容"
            size="small"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="真实姓名" prop="name">
          <el-input
            v-model="accountForm.name"
            placeholder="请输入内容"
            size="small"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model="accountForm.phone"
            placeholder="请输入内容"
            size="small"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input
            v-model="accountForm.email"
            placeholder="请输入内容"
            size="small"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="accountForm.password"
            placeholder="请输入内容"
            show-password
            size="small"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio v-model="accountForm.status" :label="1">正常</el-radio>
          <el-radio v-model="accountForm.status" :label="0">停用</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="accountOk" size="small">
          确 定
        </el-button>
        <el-button @click="closeAccountBtn" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <hr />
    <tinymce-editor ref="editor" v-model="msg" :disabled="disabled" id="dfsdf">
    </tinymce-editor>
    <button @click="clear">清空内容</button>
    <button @click="control()">禁用</button>
  </div>
</template>
<script>
import searchForm from "@/components/common/searchForm";
import tinymceEditor from "@/components/common/tinymceEditor";
export default {
  components: {
    tinymceEditor,
    searchForm
  },
  data() {
    return {
      msg: "Welcome to Use Tinymce Editor",
      disabled: false,
      searcharray: [
        {
          label: "名称",
          val: "name",
          type: "input",
          pla: "请输入名称"
        },
        {
          label: "状态",
          val: "isEnable",
          type: "select",
          pla: "请选择状态",
          option: [
            { label: "正常", val: "1" },
            { label: "停用", val: "0" }
          ]
        }
      ],
      // 表格数据
      tableData: [
        {
          id: 1,
          name: "李英杰",
          post: "内勤",
          phone: "18555555555",
          channel: "边晓菲",
          state: 1,
          l_time: "2012-30-20"
        },
        {
          id: 2,
          name: "财务",
          post: "内勤",
          phone: "18555555555",
          channel: "边晓菲",
          state: 1,
          l_time: "2012-30-20"
        },
        {
          id: 3,
          name: "尹志彬",
          post: "内勤",
          phone: "18555555555",
          channel: "边晓菲",
          state: 0,
          l_time: "2012-30-20"
        },
        {
          id: 4,
          name: "韩向阳",
          post: "内勤",
          phone: "18555555555",
          channel: "边晓菲",
          state: 1,
          l_time: "2012-30-20"
        }
      ],
      // 分页 每页条数
      pageSize: 15,
      // 分页 每页条数可选项
      pageSizeArray: [15, 30, 45, 60],
      // 当前页
      page: 1,
      // 总页数
      total: 400,
      accountTitle: "添加",
      accountVisible: false,
      postList: [
        { id: 1, label: "内勤" },
        { id: 2, label: "渠道" },
        { id: 3, label: "测试" }
      ], //岗位列表
      accountForm: {
        post: "",
        username: "",
        name: "",
        phone: "",
        password: "",
        status: 1
      },
      rules: {
        post: [{ required: true, message: "请选择所属岗位", trigger: "blur" }],
        username: [
          { required: true, message: "账号不可为空", trigger: "blur" }
        ],
        name: [
          {
            pattern: /[\u4e00-\u9fa5]+/g,
            message: "真实姓名请输入中文",
            trigger: "blur"
          }
        ],
        phone: [
          {
            pattern: /^1[0-9]{10}$/,
            message: "手机号格式不正确",
            trigger: "blur"
          }
        ],
        email: [{ type: "email", message: "邮箱格式不正确", trigger: "blur" }],
        password: [{ required: true, message: "密码不可为空", trigger: "blur" }]
      }
    };
  },
  mounted() {},
  methods: {
    // 搜索
    search(e) {
      console.log(e);
    },
    // 重置
    reset(e) {
      console.log(e);
    },
    // 添加账户
    addAccountBtn() {
      this.accountVisible = true;
    },
    // 弹窗取消
    closeAccountBtn() {
      this.accountVisible = false;
    },
    //弹窗确定
    accountOk(e) {
      console.log(e);
    },
    // 编辑
    handleEdit(e) {
      console.log(e);
      this.accountVisible = true;
      this.accountTitle = "编辑";
    },

    // 每页几条
    sizeChange() {
      console.log(this.pageSize);
      console.log(this.page);
    },
    // 页码变化
    currentChange() {
      console.log(this.pageSize);
      console.log(this.page);
    },
    // 清空内容
    clear() {
      console.log(this.pageSize);
      this.$refs.editor.clear();
    },
    control() {
      if (this.disabled) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    }
  }
};
</script>
<style lang="scss">
.accountList {
  padding: 30px;
  background: #fff;
  min-height: 100%;
  // 弹窗
  .accountForm {
    width: calc(100% - 100px);
    .el-form-item {
      margin-bottom: 15px;
    }
  }
}
</style>
