<template>
  <div class="groupPrecept">
    <el-scrollbar>
      <el-form
        :model="preForm"
        :rules="preRules"
        :validate-on-rule-change="false"
        ref="preForm"
        label-width="115px"
        size="small"
        class="preForm"
      >
        <el-form-item label="方案设置方式" prop="planType">
          <el-radio v-model="preForm.planType" :label="1">
            固定系数
          </el-radio>
          <el-radio v-model="preForm.planType" :label="2">
            灵活系数
          </el-radio>
          <el-radio v-model="preForm.planType" :label="3">
            无费率
          </el-radio>
        </el-form-item>
        <template v-if="preForm.planType != 3">
          <el-form-item label="伤残比例" prop="disability_ratio">
            <el-checkbox
              v-model="preForm.disability_ratio.is_one_percent"
              true-label="1"
              false-label="0"
            >
              1%
              <el-form-item
                label=""
                prop="disability_ratio.one_ratio"
                style="display: inline-block"
                v-if="
                  preForm.planType == 1 &&
                    preForm.disability_ratio.is_one_percent == 1
                "
              >
                <el-input
                  v-model="preForm.disability_ratio.one_ratio"
                  placeholder="请输入投保比例"
                >
                </el-input>
              </el-form-item>
            </el-checkbox>
            <el-checkbox
              v-model="preForm.disability_ratio.is_five_percent"
              true-label="1"
              false-label="0"
            >
              5%
              <el-form-item
                label=""
                prop="disability_ratio.five_ratio"
                style="display: inline-block"
                v-if="
                  preForm.planType == 1 &&
                    preForm.disability_ratio.is_five_percent == 1
                "
              >
                <el-input
                  v-model="preForm.disability_ratio.five_ratio"
                  placeholder="请输入投保比例"
                >
                </el-input>
              </el-form-item>
            </el-checkbox>
            <el-checkbox
              v-model="preForm.disability_ratio.is_ten_percent"
              true-label="1"
              false-label="0"
            >
              10%
              <el-form-item
                label=""
                prop="disability_ratio.ten_ratio"
                style="display: inline-block"
                v-if="
                  preForm.planType == 1 &&
                    preForm.disability_ratio.is_ten_percent == 1
                "
              >
                <el-input
                  v-model="preForm.disability_ratio.ten_ratio"
                  placeholder="请输入投保比例"
                >
                </el-input>
              </el-form-item>
            </el-checkbox>
          </el-form-item>
          <el-form-item label="扩展24小时" prop="is_expand_24_hours">
            <el-radio v-model="preForm.is_expand_24_hours" :label="1">
              是
            </el-radio>
            <el-radio v-model="preForm.is_expand_24_hours" :label="0">
              否
            </el-radio>
            <el-form-item
              label=""
              prop="insure_ratio_24_hours"
              class="inblock"
              v-if="preForm.is_expand_24_hours == 1 && preForm.planType == 1"
            >
              <el-input
                v-model="preForm.insure_ratio_24_hours"
                placeholder="请输入系数"
              >
              </el-input>
            </el-form-item>
          </el-form-item>
        </template>
        <template
          v-if="preForm.planType == 2 && preForm.is_expand_24_hours == 1"
        >
          <el-form-item label="扩展24小时配置" prop="is_insure_ratio_24_hours">
            <el-radio v-model="preForm.is_insure_ratio_24_hours" :label="1">
              固定配置
            </el-radio>
            <el-radio v-model="preForm.is_insure_ratio_24_hours" :label="2">
              灵活配置
            </el-radio>
            <el-form-item
              label=""
              prop="insure_ratio_24_hours"
              class="inblock"
              v-if="
                preForm.is_expand_24_hours == 1 &&
                  preForm.planType == 2 &&
                  preForm.is_insure_ratio_24_hours == 1
              "
            >
              <el-input
                v-model="preForm.insure_ratio_24_hours"
                placeholder="请输入系数"
              >
              </el-input>
            </el-form-item>
          </el-form-item>
        </template>
        <el-form-item label="工种库">
          <el-select
            v-model="libraryVal"
            placeholder="请选择"
            @change="changeLibrary"
            filterable
          >
            <el-option
              v-for="item in librarySelect"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="方案信息"
          prop="plan"
          class="tableX"
          v-if="preForm.planType != 3"
        >
          <div>
            <table class="tableBox">
              <tr>
                <th
                  :colspan="
                    preForm.planType == 2
                      ? preForm.is_expand_24_hours == 1 &&
                        preForm.is_insure_ratio_24_hours == 2
                        ? 7
                        : 6
                      : 6
                  "
                >
                  保障明细
                </th>
                <th :colspan="typeId.length" v-if="typeId.length != 0">
                  保费分类表
                </th>
                <th style="width: 80px;">操作</th>
              </tr>
              <tr>
                <th style="min-width: 95px;">
                  死亡、伤残限额(万元)
                </th>
                <th style="min-width: 95px;">医疗费用限额(万元)</th>
                <th style="min-width: 95px;">误工费(元/天)</th>
                <th style="min-width: 95px;">住院津贴(元/天)</th>
                <th style="min-width: 95px;">法律费用(元)</th>
                <th style="min-width: 95px;">
                  方案编码
                </th>
                <th
                  style="min-width: 95px;"
                  v-if="
                    preForm.planType == 2 &&
                      preForm.is_expand_24_hours == 1 &&
                      preForm.is_insure_ratio_24_hours == 2
                  "
                >
                  扩展24小时
                </th>
                <template v-for="(item, index) in typeId">
                  <th :key="index" style="min-width: 115px;">
                    <el-form-item
                      label=""
                      class="leftSelect"
                      :class="typeId[index].length > 0 ? '' : 'is-error'"
                      style="padding-right: 20px;"
                    >
                      <el-select
                        v-model="typeId[index]"
                        placeholder="请选择"
                        multiple
                        filterable
                        @change="changeType"
                        ref="selectType"
                      >
                        <el-option
                          v-for="(item, index) in workCateSelect"
                          :key="index"
                          :label="item.title"
                          :value="item.id + ''"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <i
                      class="el-icon-circle-close"
                      @click="deleType(index)"
                    ></i>
                  </th>
                </template>
                <th>
                  <el-button type="primary" size="mini" @click="addType()">
                    添加职业
                  </el-button>
                  <el-button type="primary" size="mini" @click="addRow()">
                    添加方案
                  </el-button>
                </th>
              </tr>
              <tr v-for="(item, index) in preForm.plan" :key="index">
                <td>
                  <el-form-item
                    label=""
                    :prop="'plan.' + index + '.death_quota'"
                    :rules="preRules.death_quota"
                  >
                    <el-input-number
                      v-model="item.death_quota"
                      :controls="false"
                      placeholder="保障额度"
                    ></el-input-number>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item
                    label=""
                    :prop="'plan.' + index + '.medical_quota'"
                    :rules="preRules.medical_quota"
                  >
                    <el-input-number
                      v-model="item.medical_quota"
                      :controls="false"
                      placeholder="保障额度"
                    ></el-input-number>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item
                    label=""
                    :prop="'plan.' + index + '.loss_work_cost'"
                    :rules="preRules.loss_work_cost"
                  >
                    <el-input-number
                      v-model="item.loss_work_cost"
                      :controls="false"
                      :max="1000"
                      placeholder="误工费"
                    ></el-input-number>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item
                    label=""
                    :prop="'plan.' + index + '.hospital_allowance'"
                    :rules="preRules.hospital_allowance"
                  >
                    <el-input-number
                      v-model="item.hospital_allowance"
                      :controls="false"
                      :max="1000"
                      placeholder="住院津贴"
                    ></el-input-number>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item
                    label=""
                    :prop="'plan.' + index + '.legal_fees'"
                    :rules="preRules.legal_fees"
                  >
                    <el-input-number
                      v-model="item.legal_fees"
                      :controls="false"
                      :max="200000"
                      placeholder="法律费用"
                    ></el-input-number>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item
                    label=""
                    :prop="'plan.' + index + '.scheme_code'"
                    :rules="preRules.scheme_code"
                  >
                    <el-input v-model="item.scheme_code" placeholder="方案编码">
                    </el-input>
                  </el-form-item>
                </td>
                <td
                  v-if="
                    preForm.planType == 2 &&
                      preForm.is_expand_24_hours == 1 &&
                      preForm.is_insure_ratio_24_hours == 2
                  "
                >
                  <el-form-item
                    label=""
                    :prop="'plan.' + index + '.insure_ratio_24_hours'"
                    :rules="preRules.insure_ratio_24_hours"
                  >
                    <el-input
                      v-model="item.insure_ratio_24_hours"
                      placeholder="扩展24小时"
                    >
                    </el-input>
                  </el-form-item>
                </td>
                <template v-for="(v, i) in typeId" min-width="95">
                  <td :key="i">
                    <el-form :model="item" ref="preForm2">
                      <el-form-item
                        label=""
                        :prop="`work_cate.${i}.money`"
                        :rules="preRules.money"
                        v-if="preForm.planType == 1"
                      >
                        <el-input-number
                          v-model="item.work_cate[i].money"
                          :controls="false"
                          size="small"
                          placeholder="保费"
                        ></el-input-number>
                      </el-form-item>
                      <template v-if="preForm.planType == 2">
                        <el-form-item
                          label=""
                          :prop="`work_cate.${i}.one_money`"
                          :rules="preRules.one_money"
                          class="tableRatio"
                          v-if="preForm.disability_ratio.is_one_percent == 1"
                        >
                          <span
                            style="width: 30px;display:inline-block;text-align:right;"
                          >
                            1%
                          </span>
                          <el-input-number
                            v-model="item.work_cate[i].one_money"
                            :controls="false"
                            size="small"
                            placeholder="伤残1%保费"
                          ></el-input-number>
                        </el-form-item>
                        <el-form-item
                          label=""
                          :prop="`work_cate.${i}.five_money`"
                          :rules="preRules.five_money"
                          class="tableRatio"
                          v-if="preForm.disability_ratio.is_five_percent == 1"
                        >
                          <span
                            style="width: 30px;display:inline-block;text-align:right;"
                          >
                            5%
                          </span>
                          <el-input-number
                            v-model="item.work_cate[i].five_money"
                            :controls="false"
                            size="small"
                            placeholder="伤残5%保费"
                          ></el-input-number>
                        </el-form-item>
                        <el-form-item
                          label=""
                          :prop="`work_cate.${i}.ten_money`"
                          :rules="preRules.ten_money"
                          class="tableRatio"
                          v-if="preForm.disability_ratio.is_ten_percent == 1"
                        >
                          <span
                            style="width: 30px;display:inline-block;text-align:right;"
                          >
                            10%
                          </span>
                          <el-input-number
                            v-model="item.work_cate[i].ten_money"
                            :controls="false"
                            size="small"
                            placeholder="伤残10%保费"
                          ></el-input-number>
                        </el-form-item>
                      </template>
                      <el-form-item label="" :prop="`work_cate.${i}.workCode`">
                        <el-input
                          v-model="item.work_cate[i].workCode"
                          size="small"
                          placeholder="方案编码"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                  </td>
                </template>
                <td>
                  <el-button
                    type="text"
                    size="mini"
                    v-if="preForm.plan.length > 1"
                    @click="dele(index)"
                  >
                    删除
                  </el-button>
                </td>
              </tr>
            </table>
          </div>
        </el-form-item>

        <template v-else>
          <el-form-item
            label="职业类别"
            prop="work_cate"
            key="preForm.work_cate"
          >
            <el-select
              v-model="preForm.work_cate"
              placeholder="请选择"
              multiple
              filterable
              ref="selectType22"
            >
              <el-option
                v-for="(v, index) in workCateSelect"
                :key="index + 'workCate'"
                :label="v.title"
                :value="v.id + ''"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="方案信息" prop="plan" class="tableX">
            <div>
              <table class="tableBox">
                <tr>
                  <th colspan="6">保障明细</th>
                  <th :colspan="moonVal.length" v-if="moonVal.length != 0">
                    月份保费对应表
                  </th>
                  <th style="width: 80px;">操作</th>
                </tr>
                <tr>
                  <th style="min-width: 95px;">
                    死亡、伤残限额(万元)
                  </th>
                  <th style="min-width: 95px;">医疗费用限额(万元)</th>
                  <th style="min-width: 95px;">误工费(元/天)</th>
                  <th style="min-width: 95px;">住院津贴(元/天)</th>
                  <th style="min-width: 95px;">法律费用(元)</th>
                  <th style="min-width: 95px;">
                    方案编码
                  </th>
                  <template v-for="(item, index) in moonVal">
                    <th :key="index" style="min-width: 95px;">
                      <el-form
                        :model="preForm.plan[0].month[index]"
                        size="small"
                        ref="preForm3"
                      >
                        <el-form-item
                          label=""
                          class="leftSelect"
                          prop="month"
                          :rules="preRules.month"
                        >
                          <el-select
                            v-model="moonVal[index]"
                            placeholder="请选择"
                            filterable
                            style="width:90px;padding-right:20px"
                            @change="changeMonth"
                          >
                            <el-option
                              v-for="(v, i) in moonArray"
                              :label="v.title"
                              :value="v.id"
                              :disabled="v.disabled"
                              :key="i"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-form>
                      <i
                        class="el-icon-circle-close"
                        @click="deleMonth(index)"
                      ></i>
                    </th>
                  </template>
                  <th>
                    <el-button type="primary" size="mini" @click="addMonth()">
                      添加月份
                    </el-button>
                    <el-button type="primary" size="mini" @click="addRow()">
                      添加方案
                    </el-button>
                  </th>
                </tr>
                <tr v-for="(item, index) in preForm.plan" :key="index">
                  <td>
                    <el-form-item
                      label=""
                      :prop="'plan.' + index + '.death_quota'"
                      :rules="preRules.death_quota"
                    >
                      <el-input-number
                        v-model="item.death_quota"
                        :controls="false"
                        placeholder="保障额度"
                      ></el-input-number>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      label=""
                      :prop="'plan.' + index + '.medical_quota'"
                      :rules="preRules.medical_quota"
                    >
                      <el-input-number
                        v-model="item.medical_quota"
                        :controls="false"
                        placeholder="保障额度"
                      ></el-input-number>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      label=""
                      :prop="'plan.' + index + '.loss_work_cost'"
                      :rules="preRules.loss_work_cost"
                    >
                      <el-input-number
                        v-model="item.loss_work_cost"
                        :controls="false"
                        :max="1000"
                        placeholder="误工费"
                      ></el-input-number>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      label=""
                      :prop="'plan.' + index + '.hospital_allowance'"
                      :rules="preRules.hospital_allowance"
                    >
                      <el-input-number
                        v-model="item.hospital_allowance"
                        :controls="false"
                        :max="1000"
                        placeholder="住院津贴"
                      ></el-input-number>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      label=""
                      :prop="'plan.' + index + '.legal_fees'"
                      :rules="preRules.legal_fees"
                    >
                      <el-input-number
                        v-model="item.legal_fees"
                        :controls="false"
                        :max="200000"
                        placeholder="法律费用"
                      ></el-input-number>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      label=""
                      :prop="'plan.' + index + '.scheme_code'"
                      :rules="preRules.scheme_code"
                    >
                      <el-input
                        v-model="item.scheme_code"
                        placeholder="方案编码"
                      >
                      </el-input>
                    </el-form-item>
                  </td>
                  <template v-for="(v, i) in moonVal" min-width="95">
                    <td :key="i">
                      <el-form :model="item" ref="preForm2">
                        <el-form-item
                          label=""
                          :prop="`month.${i}.money`"
                          :rules="preRules.money"
                        >
                          <el-input-number
                            v-model="item.month[i].money"
                            :controls="false"
                            size="small"
                            placeholder="保费"
                          ></el-input-number>
                        </el-form-item>
                      </el-form>
                    </td>
                  </template>
                  <td>
                    <el-button
                      type="text"
                      size="mini"
                      v-if="preForm.plan.length > 1"
                      @click="dele(index)"
                    >
                      删除
                    </el-button>
                  </td>
                </tr>
              </table>
            </div>
          </el-form-item>
        </template>
      </el-form>
    </el-scrollbar>
    <div class="btnBox">
      <el-button type="primary" size="small" @click="confirm()">确定</el-button>
      <el-button size="small" @click="cancel()">取消</el-button>
    </div>
  </div>
</template>
<script>
import {
  changeProductPlan,
  getProductPlan,
  getWorkLibrarySelect,
  getWorkCateSelect
} from "@/api/productAdmin";
export default {
  name: "groupPrecept",
  props: {
    id: {
      type: [Number, String],
      default: ""
    },
    status: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      // 工种库
      librarySelect: [],
      // 工种库选中
      libraryVal: "",
      // 职业类别选项
      workCateSelect: [],
      // 职业类别选中
      typeId: [[]],
      preForm: {
        planType: 1,
        disability_ratio: {
          is_five_percent: "1",
          five_ratio: "1",
          is_one_percent: "0",
          one_ratio: "1",
          is_ten_percent: "0",
          ten_ratio: "1"
        },
        is_expand_24_hours: 0,
        insure_ratio_24_hours: "",
        is_insure_ratio_24_hours: 1, //灵活系数配置时是否灵活配置24小时 1固定2灵活
        work_cate: [], //无费率时职业类别
        plan: [
          {
            death_quota: "0",
            medical_quota: "0",
            loss_work_cost: "0",
            hospital_allowance: "0",
            legal_fees: "0",
            scheme_code: "0",
            insure_ratio_24_hours: "0",
            work_cate: [
              {
                ids: [],
                money: "0",
                ten_money: "",
                one_money: "",
                five_money: "",
                workCode: ""
              }
            ],
            month: [
              {
                money: "0",
                month: ""
              }
            ]
          }
        ]
      },
      preRules: {
        insure_ratio_24_hours: [
          {
            required: true,
            message: "扩展24小时投保费率不能为空",
            trigger: "blur"
          }
        ],
        "disability_ratio.five_ratio": [
          { required: true, message: "投保比例不能为空", trigger: "blur" }
        ],
        "disability_ratio.one_ratio": [
          { required: true, message: "投保比例不能为空", trigger: "blur" }
        ],
        "disability_ratio.ten_ratio": [
          { required: true, message: "投保比例不能为空", trigger: "blur" }
        ],
        five_money: [
          { required: true, message: "伤残5%保费不能为空", trigger: "blur" }
        ],
        one_money: [
          { required: true, message: "伤残1%保费不能为空", trigger: "blur" }
        ],
        ten_money: [
          { required: true, message: "伤残10%保费不能为空", trigger: "blur" }
        ],
        death_quota: [
          { required: true, message: "死亡、伤残限额不能为空", trigger: "blur" }
        ],
        scheme_code: [
          { required: true, message: "方案编码不能为空", trigger: "blur" }
        ],
        medical_quota: [
          { required: true, message: "医疗责任限额不能为空", trigger: "blur" }
        ],
        loss_work_cost: [
          { required: true, message: "误工费 不能为空", trigger: "blur" }
        ],
        hospital_allowance: [
          { required: true, message: "住院津贴不能为空", trigger: "blur" }
        ],
        legal_fees: [
          { required: true, message: "法律费用不能为空", trigger: "blur" }
        ],
        money: [{ required: true, message: "保费不能为空", trigger: "blur" }],
        type: [
          {
            required: true,
            message: "类别不能为空",
            trigger: ["blur", "change", "focus"]
          }
        ],
        month: [
          {
            required: true,
            message: "月份不能为空",
            trigger: ["blur", "change", "focus"]
          }
        ],
        work_cate: [
          {
            required: true,
            message: "职业类别不能为空",
            trigger: ["blur", "change", "focus"]
          }
        ]
      },
      // 月份数组
      moonArray: [
        { id: 1, title: "一个月", disabled: false },
        { id: 2, title: "二个月", disabled: false },
        { id: 3, title: "三个月", disabled: false },
        { id: 4, title: "四个月", disabled: false },
        { id: 5, title: "五个月", disabled: false },
        { id: 6, title: "六个月", disabled: false },
        { id: 7, title: "七个月", disabled: false },
        { id: 8, title: "八个月", disabled: false },
        { id: 9, title: "九个月", disabled: false },
        { id: 10, title: "十个月", disabled: false },
        { id: 11, title: "十一个月", disabled: false },
        { id: 12, title: "一年", disabled: false }
      ],
      // 月份选中项 默认有一个空的
      moonVal: [""]
    };
  },
  mounted() {
    // 获取方案
    this.getProductPlan();
  },
  methods: {
    // 工种库选择
    changeLibrary(e) {
      console.log(e);
      // 清空选项
      this.typeId.forEach(element => {
        element.forEach((item, index) => {
          element.splice(index, 1);
        });
      });
      this.getWorkCateSelect(e);
      this.preForm.plan.forEach(element => {
        element.work_cate.forEach(item => {
          item.ids.length = 0;
        });
      });
    },
    addType() {
      //添加职业类别
      let that = this;
      this.typeId.push([]);
      that.preForm.plan.forEach(element => {
        element.work_cate.push({
          ids: [],
          money: "0",
          ten_money: "",
          one_money: "",
          five_money: "",
          workCode: ""
        });
      });
    },
    addRow() {
      // 添加方案
      let that = this;
      let _index = that.preForm.plan.length;
      that.preForm.plan.push({
        death_quota: "0",
        medical_quota: "0",
        loss_work_cost: "0",
        hospital_allowance: "0",
        legal_fees: "0",
        scheme_code: "0",
        work_cate: [],
        month: []
      });
      that.typeId.forEach((v, i) => {
        that.preForm.plan[_index].work_cate.push({
          ids: v,
          money: "0",
          ten_money: "",
          one_money: "",
          five_money: "",
          workCode: ""
        });
      });
      that.moonVal.forEach((v, i) => {
        that.preForm.plan[_index].month.push({
          money: "0",
          month: v
        });
      });
    },
    // 无费率~ 月份选中
    changeMonth() {
      let that = this;
      that.moonArray.forEach(element => {
        element.id;
        for (let index = 0; index < that.moonVal.length; index++) {
          element.disabled = false;
          if (that.moonVal[index] == element.id) {
            element.disabled = true;
            break;
          }
        }
      });
      that.preForm.plan.forEach(element => {
        that.moonVal.forEach((item, index) => {
          element.month[index].month = item;
        });
      });
    },
    // 无费率~ 添加月份
    addMonth() {
      let that = this;
      if (this.moonVal.length < 12) {
        this.moonVal.push("");
        that.preForm.plan.forEach(element => {
          element.month.push({
            money: "0",
            month: ""
          });
        });
      } else {
        that.$notification.error({
          title: "错误",
          message: "最大可以添加12个月份"
        });
      }
    },
    deleType(index) {
      // 删除职业类别
      this.typeId.splice(index, 1);
      this.preForm.plan.forEach(element => {
        element.work_cate.splice(index, 1);
      });
    },
    deleMonth(index) {
      // 无费率~ 删除月份
      this.moonVal.splice(index, 1);
      this.preForm.plan.forEach(element => {
        element.month.splice(index, 1);
      });
      // 触发月份改变，解放不可选
      this.changeMonth();
    },
    dele(index) {
      // 删除方案
      this.preForm.plan.splice(index, 1);
    },
    // 方案信息职业类别变化处理
    changeType() {
      let that = this;
      // 方案添加
      that.preForm.plan.forEach(element => {
        that.typeId.forEach((v, i) => {
          element.work_cate[i].ids = v;
        });
      });
    },
    // 点击确认
    confirm(e) {
      let that = this;
      that.$refs.preForm.validate((valid, data) => {
        if (that.$refs.preForm3) {
          //if解决未切换无费率是that.$refs.preForm3 == undefined， .forEach报错
          that.$refs.preForm3.forEach(element => {
            element.validate();
          });
        }
        console.log(valid);
        that.$nextTick(() => {
          if (valid) {
            let error = document.querySelectorAll(".is-error");
            if (error.length > 0) {
              that.$notification.error({
                title: "错误",
                message: "保费、职业类别、月份不能为空"
              });
              that.$nextTick(() => {
                //使用滚动到报错信息位置会出现错行
                document.querySelector(".is-error").scrollIntoView();
              });
            } else {
              let loadind = that.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
              });
              let _data = that.preForm;
              that.preForm.product_id = that.id;
              console.log(_data);
              changeProductPlan(_data)
                .then(function(res) {
                  console.log(res);
                  loadind.close();
                  if (res.code == 1) {
                    that.$message.success(res.msg);
                    that.$emit("upStatus", true);
                    that.cancel();
                  } else {
                    that.$message.error(res.msg);
                  }
                })
                .catch(function(error) {
                  console.log(error);
                });
            }
          } else {
            for (let key in data) {
              data[key].forEach(element => {
                setTimeout(function() {
                  that.$notification.error({
                    title: "错误",
                    message: element.message
                  });
                }, 10);
              });
            }
            that.$nextTick(() => {
              document.querySelector(".is-error").scrollIntoView();
            });
            return false;
          }
        });
      });
    },
    // 取消
    cancel() {
      this.$emit("update:status", false);
    },
    // 获取方案
    getProductPlan() {
      let that = this;
      getProductPlan({ product_id: that.id })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.company_id = res.data.company_id;
            if (res.data.plan) {
              that.getWorkLibrarySelect(res.data.company_id, res.data.lib_id);
              that.preForm = res.data;
              that.typeId = [];
              that.moonVal = [];
              res.data.plan[0].work_cate.forEach(element => {
                that.typeId.push(element.ids);
              });
              if (!res.data.planType) {
                that.$set(that.preForm, "planType", 1);
              }
              if (!res.data.is_insure_ratio_24_hours) {
                that.$set(that.preForm, "is_insure_ratio_24_hours", 1);
              }
              res.data.plan.forEach(element => {
                if (!element.month) {
                  that.$set(element, "month", []);
                }
              });
              res.data.plan[0].month.forEach(element => {
                that.moonVal.push(element.month);
              });
              if (!that.preForm.disability_ratio.five_ratio) {
                that.$set(that.preForm, "disability_ratio", {});
                that.$set(
                  that.preForm.disability_ratio,
                  "is_five_percent",
                  "1"
                );
                that.$set(that.preForm.disability_ratio, "five_ratio", "1");
                that.$set(that.preForm.disability_ratio, "is_one_percent", "0");
                that.$set(that.preForm.disability_ratio, "one_ratio", "1");
                that.$set(that.preForm.disability_ratio, "is_ten_percent", "0");
                that.$set(that.preForm.disability_ratio, "ten_ratio", "1");
              }
              // 无费率时触发 月份不重复选中
              that.changeMonth();
            } else {
              that.getWorkLibrarySelect(res.data.company_id);
            }
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取工种库
    getWorkLibrarySelect(e, l) {
      let that = this;
      getWorkLibrarySelect({ company_id: e })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.librarySelect = res.data;
            if (l) {
              that.libraryVal = l;
            } else {
              that.libraryVal = that.librarySelect[0].id;
            }
            // 获取职业类别
            that.getWorkCateSelect(that.libraryVal);
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取职业类别
    getWorkCateSelect(e) {
      let that = this;
      let _data = {
        lib_id: e,
        company_id: that.company_id
      };
      getWorkCateSelect(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.workCateSelect = res.data;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>
<style lang="scss">
.groupPrecept {
  .el-scrollbar__wrap {
    height: calc(100vh - 201px);
    overflow-x: hidden;
    margin-bottom: 0 !important;
  }
  .btnBox {
    text-align: center;
    padding: 10px 25px;
    background: #efefef;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -30px;
    .el-button {
      width: 80px;
    }
    .el-button + .el-button {
      margin-left: 25px;
    }
  }
  .el-form {
    width: calc(100% - 25px);
    .inblock {
      display: inline-block;
      margin-bottom: 0;
    }
    .el-checkbox {
      .el-form-item {
        margin: 0;
        .el-input {
          width: 130px;
        }
      }
    }
    // .el-table {
    //   .cell {
    //     position: relative;
    //     .el-form-item {
    //       margin: 0;
    //       .el-input {
    //         .el-input__inner {
    //           padding: 0 5px;
    //         }
    //       }
    //     }
    //     .el-form {
    //       width: 100%;
    //       .tableRatio {
    //         font-size: 12px;
    //         .el-input-number {
    //           width: calc(100% - 35px);
    //         }
    //         .el-form-item__error {
    //           display: none;
    //         }
    //       }
    //     }
    //     .el-button + .el-button {
    //       margin-left: 0;
    //     }
    //     & > .leftSelect {
    //       padding-right: 20px;
    //     }
    //     & > .el-icon-circle-close {
    //       position: absolute;
    //       z-index: 2;
    //       top: 50%;
    //       transform: translateY(-50%);
    //       right: 0;
    //       padding: 5px;
    //       border-radius: 50%;
    //       font-size: 18px;
    //       cursor: pointer;
    //       color: #f56c6c;
    //     }
    //     & > .el-icon-circle-close:hover {
    //       color: #f78989;
    //     }
    //     .el-input-number {
    //       width: 100%;
    //     }
    //   }
    // }
    .tableX > .el-form-item__content {
      overflow-x: auto;
    }
    .tableBox {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 10px;
      th {
        background: #f5f7fa;
        padding: 15px 5px !important;
      }
      th,
      td {
        border: 1px solid #ebeef5;
        font-size: 12px;
        position: relative;
        padding: 5px;
        line-height: 1.5;
        .el-form {
          width: 90px;
          margin: 0;
        }
        .el-form-item {
          margin: 0;
          .el-input .el-input__inner {
            padding: 0 5px;
          }
        }
        & > .el-icon-circle-close {
          position: absolute;
          z-index: 2;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          padding: 5px;
          border-radius: 50%;
          font-size: 18px;
          cursor: pointer;
          color: #f56c6c;
        }
        & > .el-icon-circle-close:hover {
          color: #f78989;
        }
        .el-button {
          padding: 5px 7px;
        }
        .el-button + .el-button {
          margin-left: 0;
          margin-top: 2px;
        }
        .el-form-item__content,
        .el-input-number {
          width: 100%;
        }
        .tableRatio .el-input-number {
          width: calc(100% - 35px);
        }
        .el-form-item__error {
          display: none;
        }
      }
    }
  }
}
</style>
