<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { isOperateFun } from "@/utils/isOperateFun.js";
export default {
  name: "App",
  created() {
    isOperateFun();
  },
  methods: {}
};
</script>
