import http from "../utils/axios";
// 系统管理api
// 菜单管理页
// 系统添加菜单
export const addmenu = data => {
  return http({
    url: "/api/menu/addmenu", // 接口
    method: "post",
    data: data
  });
};
//  系统编辑菜单
export const editMenu = data => {
  return http({
    url: "/api/menu/editMenu", // 接口
    method: "post",
    data: data
  });
};
// 系统菜单列表接口
export const getMenuList = data => {
  return http({
    url: "/api/menu/getMenuList", // 接口
    method: "post",
    data: data
  });
};
// 系统菜单列表接口
export const getMenuInfo = data => {
  return http({
    url: "/api/menu/getMenuInfo", // 接口
    method: "post",
    data: data
  });
};
// 系统菜单启用状态
export const setMenuStatus = data => {
  return http({
    url: "/api/menu/setMenuStatus", // 接口
    method: "post",
    data: data
  });
};
// 岗位管理页
// 设置岗位
export const setAuthGroup = data => {
  return http({
    url: "/api/auth/setAuthGroup", // 接口
    method: "post",
    data: data
  });
};
// 获取岗位列表
export const getAuthList = data => {
  return http({
    url: "/api/auth/getAuthList", // 接口
    method: "post",
    data: data
  });
};
// 获取指定岗位组信息
export const getauthinfo = data => {
  return http({
    url: "/api/auth/getauthinfo", // 接口
    method: "post",
    data: data
  });
};
// 获取岗位权限组菜单
export const getAuthMenu = data => {
  return http({
    url: "/api/auth/getAuthMenu", // 接口
    method: "post",
    data: data
  });
};
// 获取指定岗位组权限
export const getAuthRules = data => {
  return http({
    url: "/api/auth/getAuthRules", // 接口
    method: "post",
    data: data
  });
};
// 设置指定岗位组权限
export const setAuthRules = data => {
  return http({
    url: "/api/auth/setAuthRules", // 接口
    method: "post",
    data: data
  });
};
// 指定岗位指定人员授权接口
export const setGroupAuth = data => {
  return http({
    url: "/api/auth/setGroupAuth", // 接口
    method: "post",
    data: data
  });
};
// 指定岗位指定人员授权接口
export const uSetAuth = data => {
  return http({
    url: "/api/auth/uSetAuth", // 接口
    method: "post",
    data: data
  });
};
// 团队
// 添加、编辑
export const changeTeam = data => {
  return http({
    url: "/api/admin/changeTeam", // 接口
    method: "post",
    data: data
  });
};
// 获取团队列表
export const getTeamList = data => {
  return http({
    url: "/api/admin/getTeamList", // 接口
    method: "post",
    data: data
  });
};
// 获取团队列表
export const getTeamInfo = data => {
  return http({
    url: "/api/admin/getTeamInfo", // 接口
    method: "post",
    data: data
  });
};
// 账户管理
// 添加、编辑管理员（账户）
// 获取团队列表
export const changeAdmin = data => {
  return http({
    url: "/api/admin/changeAdmin", // 接口
    method: "post",
    data: data
  });
};
// 获取登录账号所属及其下属团队接口
export const getTeam = data => {
  return http({
    url: "/api/team/getTeam", // 接口
    method: "post",
    data: data
  });
};
// 获取登录账号所属及其下属团队接口
export const getAdmin = data => {
  return http({
    url: "/api/admin/getAdmin", // 接口
    method: "post",
    data: data
  });
};
// 获取管理员列表接口
export const getAdminList = data => {
  return http({
    url: "/api/admin/getAdminList", // 接口
    method: "post",
    data: data
  });
};
// 获取指定账号信息接口
export const getAdminInfo = data => {
  return http({
    url: "/api/admin/getAdminInfo", // 接口
    method: "post",
    data: data
  });
};
// 修改账号密码接口
export const changePassword = data => {
  return http({
    url: "/api/admin/changePassword", // 接口
    method: "post",
    data: data
  });
};
// 获取产品佣金配置信息接口
export const getProductChannelList = data => {
  return http({
    url: "/api/Product_Channel/getProductChannelList", // 接口
    method: "post",
    data: data
  });
};
// 配置可开放产品接口
export const changeProductChannel = data => {
  return http({
    url: "/api/product_channel/changeProductChannel", // 接口
    method: "post",
    data: data
  });
};
// 公告
// 添加编辑公告
export const noticeChange = data => {
  return http({
    url: "/api/notice/noticeChange", // 接口
    method: "post",
    data: data
  });
};
// 通知详情
export const getNoticeInfo = data => {
  return http({
    url: "/api/notice/getNoticeInfo", // 接口
    method: "post",
    data: data
  });
};
// 添加通知列表
export const getNoticeList = data => {
  return http({
    url: "/api/notice/getNoticeList", // 接口
    method: "post",
    data: data
  });
};
