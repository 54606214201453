<template>
  <el-form
    inline
    ref="searchform"
    class="searchform"
    :model="searchform"
    :label-width="labelWidth"
  >
    <el-row>
      <template v-for="(item, index) in searcharray">
        <el-col :span="span" v-if="item.type == 'input'" :key="index">
          <el-form-item :label="item.label" :prop="item.val">
            <el-input
              v-model="searchform[item.val]"
              :placeholder="item.pla"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="span" v-else-if="item.type == 'select'" :key="index">
          <el-form-item :label="item.label" :prop="item.val">
            <el-select
              v-model="searchform[item.val]"
              :multiple="item.multiple"
              :placeholder="item.pla"
              filterable
            >
              <el-option
                :label="v.label"
                :value="v.val"
                v-for="(v, i) in item.option"
                :key="i"
              >
                <template v-if="v.tag">
                  <el-tag size="mini">{{ v.tag }}</el-tag>
                  <span>
                    {{ v.label }}
                  </span>
                </template>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span" v-else-if="item.type == 'date'" :key="index">
          <el-form-item :label="item.label" :prop="item.val">
            <el-date-picker
              v-model="searchform[item.val]"
              type="datetimerange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </template>
      <el-col :span="span">
        <el-form-item>
          <el-button type="primary" size="small" @click="onSubmit()">
            查询
          </el-button>
          <el-button size="small" @click="resetForm()">
            重置
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { shortcutsOne } from "@/utils/dateRange";
export default {
  name: "searchForm",
  data() {
    return {
      searchform: {},
      pickerOptions: {
        shortcuts: shortcutsOne
      }
    };
  },
  props: {
    searcharray: {
      type: Array,
      required: true
    },
    span: {
      type: Number,
      default: 6
    },
    labelWidth: {
      type: String,
      default: "120px"
    }
  },
  methods: {
    onSubmit() {
      let submitdata = {};
      Object.keys(this.searchform).forEach((value, key) => {
        if (this.searchform[value] != "" || this.searchform[value].length > 0) {
          submitdata[value] = this.searchform[value];
        }
      });
      this.$emit("change", submitdata);
    },
    resetForm() {
      let obj = {};
      this.searcharray.forEach(function(v, i) {
        if (v.default) {
          obj[v.val] = v.default;
        } else {
          obj[v.val] = "";
        }
      });
      this.searchform = obj;
      this.$refs.searchform.resetFields();
      this.$emit("reset");
    }
  },
  watch: {
    searcharray: {
      handler: function(val, oldVal) {
        let obj = {};
        val.forEach(function(v, i) {
          if (v.default) {
            obj[v.val] = v.default;
          } else {
            obj[v.val] = "";
          }
        });
        this.searchform = obj;
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
<style lang="scss">
.searchform .el-row .el-form-item {
  width: 100%;
  display: flex;
  .el-form-item__content {
    flex: 1;
    .el-select {
      width: 100%;
    }
    .el-date-editor {
      width: 100%;
      .el-range-separator {
        width: 24px;
      }
    }
    & > button:first-child {
      margin-left: 55px;
    }
  }
}
</style>
