import http from "../utils/axios";
// 登陆
export const login = data => {
  return http({
    url: "/api/user/sysLogin", // 接口
    method: "post",
    data: data
  });
};
// 退出
export const logout = data => {
  return http({
    url: "/api/user/logout", // 接口
    method: "post",
    data: data
  });
};
// 请求路由list
export const routerlist = data => {
  return http({
    url: "/api/common/getMenuList", // 接口
    method: "post",
    data: data
  });
};
