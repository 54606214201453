<template>
    <div class="menuManagement">
        <search-form :searcharray="searcharray" :span="6" @change="search" @reset="reset"></search-form>
        <el-button type="primary" size="small" icon="el-icon-plus" @click="add()">添加
        </el-button>
        <export-excel :id="'menuId'" name="菜单列表" size="small" text="导出Table"></export-excel>
        <el-table :data="tableData" v-loading="loading" element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading" style="width: 100%;margin-top:15px" row-key="id" border stripe lazy
            height="calc(100vh - 220px)" size="small" default-expand-all class="tableAO"
            :tree-props="{ children: 'child', hasChildren: 'hasChildren' }" id="menuId">
            <el-table-column prop="id" label="ID"> </el-table-column>
            <el-table-column prop="title" align="center" label="标题">
            </el-table-column>
            <el-table-column prop="icon" align="center" label="图标">
                <template slot-scope="scope">
                    <i :class="scope.row.icon"></i>
                </template>
            </el-table-column>
            <el-table-column prop="name" align="center" label="URL">
            </el-table-column>
            <el-table-column prop="condition" align="center" label="标识">
            </el-table-column>
            <el-table-column prop="weigh" align="center" label="权重">
            </el-table-column>
            <el-table-column prop="type" align="center" label="类别">
                <template slot-scope="scope">
                    <el-tag type="" size="mini" v-if="scope.row.type == 'menu'">
                        菜单
                    </el-tag>
                    <el-tag type="warning" size="mini" v-else>按钮</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="status" align="center" label="状态">
                <template slot-scope="scope">
                    <el-tag type="success" size="mini" v-if="scope.row.status == 1">
                        正常
                    </el-tag>
                    <el-tag type="danger" size="mini" v-else>隐藏</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="isenable" align="center" label="是否启用">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.isenable" active-color="#13ce66" inactive-color="#ff4949"
                        :active-value="1" :inactive-value="0" :disabled="scope.row.name == '/'" @change="
                            handleSwitch(scope.row.isenable, scope.row.id, scope.$index)
                            ">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--弹窗 -->
        <div class="authority">
            <el-dialog :title="popTitle" :visible.sync="dialogState" width="540px" custom-class="authority"
                @close="dialogClose">
                <el-form :model="authorityForm" :rules="rules" :validate-on-rule-change="false" ref="authorityForm"
                    label-width="100px" class="demo-ruleForm" size="small">
                    <el-form-item label="是否菜单" prop="type">
                        <el-radio v-model="authorityForm.type" label="menu" @change="toggle">
                            菜单
                        </el-radio>
                        <el-radio v-model="authorityForm.type" label="file" @change="toggle">
                            按钮
                        </el-radio>
                    </el-form-item>
                    <el-form-item label="父级" prop="pid">
                        <el-select v-model="authorityForm.pid" filterable placeholder="请选择">
                            <el-option label="无" :value="0"> </el-option>
                            <template v-for="item in selData">
                                <el-option v-if="item.type == 'menu'" :label="item.title" :value="item.id" :key="item.id">
                                </el-option>
                                <template v-if="item.child">
                                    <template v-for="v in item.child">
                                        <el-option v-if="v.type == 'menu'" :label="v.title" :value="v.id"
                                            :key="v.id"></el-option>
                                        <template v-if="v.child">
                                            <template v-for="xv in v.child">
                                                <el-option v-if="xv.type == 'menu'" :label="xv.title" :value="xv.id"
                                                    :key="xv.id"></el-option>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="标题" prop="title">
                        <el-input v-model="authorityForm.title" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="图标" prop="icon">
                        <e-icon-picker ref="iconPicker" v-model="authorityForm.icon" :options="options" size="small" />
                    </el-form-item>
                    <el-form-item label="URL" prop="name">
                        <el-input v-model.trim="authorityForm.name" placeholder="请输入内容" autocomplete="on"></el-input>
                    </el-form-item>
                    <el-form-item label="标识" prop="condition">
                        <el-input v-model.trim="authorityForm.condition" placeholder="请输入内容" autocomplete="on"></el-input>
                    </el-form-item>
                    <el-form-item label="权重" prop="weigh">
                        <!-- <el-input
              v-model.number.trim="authorityForm.weigh"
              placeholder="请输入内容"
            ></el-input> -->
                        <el-input-number v-model="authorityForm.weigh" controls-position="right" :precision="0" :min="0"
                            style="width:100%"></el-input-number>
                    </el-form-item>
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" v-model="authorityForm.remark"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <el-radio v-model="authorityForm.status" :label="1">显示</el-radio>
                        <el-radio v-model="authorityForm.status" :label="0">隐藏</el-radio>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" size="small" @click="dialogOk(authorityForm.id)">
                        确 定
                    </el-button>
                    <el-button @click="dialogClose()" size="small">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import exportExcel from "@/components/common/exportExcel";
import searchForm from "@/components/common/searchForm";
import { EIconPicker, analyzingIconForIconfont } from "e-icon-picker";
// e-icon-picker/lib/index.css，e-icon-picker/dist/index.css
import "e-icon-picker/lib/index.css"; //图标选择器基础样式
import iconfont from "@/assets/font/iconfont/iconfont.json"; //引入json文件

let forIconfont = analyzingIconForIconfont(iconfont);
import {
    addmenu,
    editMenu,
    getMenuList,
    getMenuInfo,
    setMenuStatus
} from "@/api/system";
export default {
    name: "menuManagement",
    data() {
        return {
            searcharray: [
                {
                    label: "菜单名称",
                    val: "title",
                    type: "input",
                    pla: "请输入菜单名称"
                },
                {
                    label: "启用状态",
                    val: "isEnable",
                    type: "select",
                    pla: "请选择启用状态",
                    option: [
                        { label: "启用", val: "1" },
                        { label: "禁用", val: "0" }
                    ]
                },
                {
                    label: "显示状态",
                    val: "status",
                    type: "select",
                    pla: "请选择显示状态",
                    option: [
                        { label: "显示", val: "1" },
                        { label: "隐藏", val: "0" }
                    ]
                }
            ],
            searchVal: {},
            tableData: [],
            loading: true,
            // 弹窗
            dialogState: false,
            popTitle: "添加",
            selData: [], //弹窗筛选
            authorityForm: {
                id: "",
                type: "menu",
                pid: 0,
                title: "",
                icon: "",
                weigh: 100,
                name: "",
                condition: "",
                remark: "",
                status: 1
            },
            rules: {},
            menuRules: {
                title: [
                    {
                        required: true,
                        message: "标题不能为空",
                        trigger: "blur"
                    },
                    { message: "标题最多输入6个字符", min: 0, max: 6, trigger: "blur" }
                ],
                name: [
                    { required: true, message: "URL不能为空", trigger: "blur" },
                    {
                        pattern: /^\//,
                        message: "URL必须/开头",
                        trigger: "blur"
                    }
                ],
                condition: [
                    { required: true, message: "标识不能为空", trigger: "blur" }
                ],
                weigh: [
                    { required: true, message: "权重不能为空", trigger: "blur" },
                    { type: "number", message: "权重必须为数字值", trigger: "blur" }
                ]
            },
            btnRules: {
                pid: [{ required: true, message: "父级不能为空", trigger: "blur" }],
                title: [
                    { required: true, message: "标题不能为空", trigger: "blur" },
                    { message: "标题最多输入8个字符", min: 0, max: 8, trigger: "blur" }
                ],
                name: [{ pattern: /^\//, message: "URL必须/开头", trigger: "blur" }],
                condition: [
                    { required: true, message: "标识不能为空", trigger: "blur" }
                ],
                weigh: [
                    { required: true, message: "权重不能为空", trigger: "blur" },
                    { type: "number", message: "权重必须为数字值", trigger: "blur" }
                ]
            },
            options: {
                FontAwesome: false,
                ElementUI: true,
                eIcon: false, //自带的图标，来自阿里妈妈
                eIconSymbol: false, //是否开启彩色图标
                addIconList: forIconfont.list,
                removeIconList: []
            }
        };
    },
    components: {
        searchForm,
        EIconPicker,
        exportExcel
    },
    mounted() {
        this.menuList();
    },
    methods: {
        // 搜索数据
        search(e) {
            this.searchVal = e;
            this.menuList();
        },
        //搜索重置
        reset() {
            this.searchVal = {};
            this.menuList();
        },
        add() {
            this.authorityForm = {
                id: "",
                type: "menu",
                pid: 0,
                title: "",
                icon: "",
                weigh: 100,
                name: "",
                condition: "",
                remark: "",
                status: 1
            };
            this.popTitle = "添加";
            this.rules = this.menuRules; //默认是菜单验证规则
            this.dialogState = true;
        },
        // 启用禁用
        handleSwitch(val, id, index) {
            console.log(val, id, index);
            let that = this;
            that
                .$confirm("切换后直接影响页面是否看访问, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                .then(() => {
                    let _data = {};
                    _data.mid = id;
                    _data.isEnable = val;
                    setMenuStatus(_data)
                        .then(function (res) {
                            console.log(res);
                            if (res.code == 1) {
                                that.$message.success(res.msg);
                            } else {
                                that.$message.error(res.msg);
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                })
                .catch(() => {
                    this.menuList();
                });
        },
        //点击编辑
        handleEdit(e) {
            let that = this;
            that.popTitle = "编辑";
            that.triggerId = e;
            let _data = { mid: e };
            getMenuInfo(_data)
                .then(function (res) {
                    console.log(res);
                    if (res.code == 1) {
                        that.authorityForm = res.data;
                        if (res.data.type == "menu") {
                            that.rules = that.menuRules;
                        } else {
                            that.rules = that.btnRules;
                        }
                        that.dialogState = true;
                    } else {
                        that.$message.error(res.msg);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        // 是否菜单切换更换验证规则
        toggle(e) {
            this.$refs.authorityForm.clearValidate();
            if (e == "menu") {
                this.rules = this.menuRules;
            } else {
                this.rules = this.btnRules;
            }
        },
        // 添加编辑弹窗确认
        dialogOk(e) {
            this.$refs.authorityForm.validate(valid => {
                let that = this;
                if (valid) {
                    // e=="" id为空时调用添加接口。id有值时调用编辑接口
                    let _data = that.authorityForm;
                    let loadind = that.$loading({
                        lock: true,
                        text: "Loading",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)"
                    });
                    if (e == "") {
                        addmenu(_data)
                            .then(function (res) {
                                console.log(res);
                                loadind.close();
                                if (res.code == 1) {
                                    that.$message.success(res.msg);
                                    that.menuList();
                                    that.dialogClose();
                                } else {
                                    that.$message.error(res.msg);
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    } else {
                        _data.id = e;
                        editMenu(_data)
                            .then(function (res) {
                                console.log(res);
                                loadind.close();
                                if (res.code == 1) {
                                    that.$message.success(res.msg);
                                    that.menuList();
                                    that.dialogClose();
                                } else {
                                    that.$message.error(res.msg);
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        //关闭取消
        dialogClose() {
            this.$nextTick(() => {
                this.$refs["authorityForm"].resetFields();
            });
            this.dialogState = false;
        },
        // 获取菜单列表
        menuList() {
            let that = this;
            that.loading = true;
            getMenuList(that.searchVal)
                .then(function (res) {
                    console.log(res);
                    that.loading = false;
                    if (res.code == 1) {
                        that.tableData = res.data;
                    } else {
                        that.$message.error(res.msg);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            getMenuList()
                .then(function (res) {
                    if (res.code == 1) {
                        that.selData = res.data;
                    } else {
                        that.$message.error(res.msg);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
};
</script>
<style lang="scss">
.menuManagement {
    padding: 30px;
    background: #fff;
    min-height: 100%;
}

.authority .el-dialog__body .el-form-item {
    margin-bottom: 15px;

    .el-form-item__content,
    .el-select {
        width: 300px;
    }

    .el-input-number .el-input__inner {
        text-align: left;
    }
}
</style>
