<script>
export default {
  name: "MenuItem",
  functional: true,
  props: {
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    alert: {
      type: [String, Number],
      default: ""
    }
  },
  render(h, context) {
    const { icon, title, alert } = context.props;
    const vnodes = [];

    if (icon) {
      if (icon.includes("el-icon")) {
        vnodes.push(<i class={[icon]} />);
      } else {
        vnodes.push(<i class={[icon, "iconfont"]} />);
      }
    }
    if (title) {
      vnodes.push(<span slot="title">{title}</span>);
    }
    if (alert && alert != 0) {
      vnodes.push(<sup class="alert">{alert}</sup>);
    }
    return vnodes;
  }
};
</script>
