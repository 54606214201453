// 下载
function down(downurl) {
  let a = document.createElement("a");
  a.href = downurl;
  let _name = downurl.split("/");
  let _index = downurl.split("/").length - 1;
  a.setAttribute("target", "_blank");
  a.setAttribute("download", _name[_index]);
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
export default down;
