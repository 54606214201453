<template>
  <editor v-model="myValue" :init="init" :id="id"></editor>
</template>
<script>
import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/themes/silver"; // 引入主题包 themes.js
import "tinymce/icons/default/icons.js"; //引入图标包 icons.js
// 插件
import "tinymce/plugins/image"; // 插入上传图片插件
import "tinymce/plugins/media"; // 插入视频插件
import "tinymce/plugins/table"; // 插入表格插件
import "tinymce/plugins/lists"; // 列表插件
import "tinymce/plugins/wordcount"; // 字数统计插件
import "tinymce/plugins/code"; // 插入代码
import "tinymce/plugins/paste"; // 图片直接从其他地方，复制粘贴到文本域
export default {
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: "editor"
    }
  },
  data() {
    return {
      init: {
        language_url: `/tinymce/langs/zh_CN.js`, // 语言包的路径
        language: "zh_CN", //语言
        skin_url: `/tinymce/skins/ui/oxide`, // skin路径
        content_css: `/tinymce/skins/content/default/content.css`, //样式
        height: 300, //编辑器高度
        plugins: "lists image media table wordcount code paste",
        toolbar: [
          "undo redo |  formatselect fontsizeselect | bold italic forecolor backcolor subscript superscript | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table | code removeformat"
        ],
        branding: false, //隐藏右下角技术支持
        fontsize_formats: "12px 14px 16px 18px 20px 24px 36px 48px",
        menubar: false, //顶部菜单栏显示
        elementpath: false, //禁用编辑器底部的状态栏formats
        paste_data_images: true, // 允许粘贴图像
        paste_preprocess: function(plugin, args) {
          console.log("---paster");
        },
        // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        images_upload_handler: (blobInfo, success, failure) => {
          const img = "data:image/jpeg;base64," + blobInfo.base64();
          success(img);
        }
      },
      myValue: this.value
    };
  },

  mounted() {
    tinymce.init({});
  },
  methods: {
    // 清空内容
    clear() {
      this.myValue = "";
    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue;
    },
    myValue(newValue) {
      this.$emit("input", newValue);
    },
    disabled(disabled) {
      if (disabled) {
        tinymce.editors[this.id].setMode("readonly");
      } else {
        tinymce.editors[this.id].setMode("design");
      }
    }
  }
};
</script>
<style lang="scss">
.tox-tinymce-aux {
  z-index: 9999 !important;
}
</style>
