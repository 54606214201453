var venify = {
  // 身份证验证
  idNoFormatCheck: function(value) {
    //验证信息
    let num = value.toUpperCase();
    // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
    let reg = /^(\d{18,18}|\d{15,15}|\d{17,17}X)$/;
    if (!reg.test(num)) {
      return false;
    }
    // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
    // 下面分别分析出生日期和校验位
    let len, re;
    len = num.length;
    if (len == 15) {
      re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
      let arrSplit = num.match(re);
      // 检查生日日期是否正确
      let dtmBirth = new Date(
        "19" + arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]
      );
      let bGoodDay;
      bGoodDay =
        dtmBirth.getYear() == Number(arrSplit[2]) &&
        dtmBirth.getMonth() + 1 == Number(arrSplit[3]) &&
        dtmBirth.getDate() == Number(arrSplit[4]);
      if (!bGoodDay) {
        return false;
      }
    }
    if (len == 18) {
      re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
      let arrSplit = num.match(re);
      // 检查生日日期是否正确
      let dtmBirth = new Date(
        arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]
      );
      let bGoodDay;
      bGoodDay =
        dtmBirth.getFullYear() == Number(arrSplit[2]) &&
        dtmBirth.getMonth() + 1 == Number(arrSplit[3]) &&
        dtmBirth.getDate() == Number(arrSplit[4]);
      if (!bGoodDay) {
        return false;
      } else {
        // 检验18位身份证的校验码是否正确。
        // 校验位按照ISO 7064:1983.MOD
        // 11-2的规定生成，X可以认为是数字10。
        let valnum;
        let arrInt = new Array(
          7,
          9,
          10,
          5,
          8,
          4,
          2,
          1,
          6,
          3,
          7,
          9,
          10,
          5,
          8,
          4,
          2
        );
        let arrCh = new Array(
          "1",
          "0",
          "X",
          "9",
          "8",
          "7",
          "6",
          "5",
          "4",
          "3",
          "2"
        );
        let nTemp = 0;
        for (let i = 0; i < 17; i++) {
          nTemp += num.substr(i, 1) * arrInt[i];
        }
        valnum = arrCh[nTemp % 11];
        if (valnum != num.substr(17, 1)) {
          return false;
        }
      }
    }
    return true;
  },
  // 手机号验证
  isPhone(phone) {
    let myreg = /^1[3-9]\d{9}$/;
    if (!myreg.test(phone)) {
      return false;
    } else {
      return true;
    }
  },
  // 营业执照验证
  isBusiness(business) {
    let reg = /(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/;
    if (!reg.test(business)) {
      return false;
    } else {
      return true;
    }
  },
  // 获取年龄
  getAge(identityCard) {
    let len = (identityCard + "").length;
    let strBirthday = "";
    if (len == 18) {
      //处理18位的身份证号码从号码中得到生日和性别代码
      strBirthday =
        identityCard.substr(6, 4) +
        "/" +
        identityCard.substr(10, 2) +
        "/" +
        identityCard.substr(12, 2);
    }
    if (len == 15) {
      let birthdayValue = "";
      birthdayValue = identityCard.charAt(6) + identityCard.charAt(7);
      if (parseInt(birthdayValue) < 10) {
        strBirthday =
          "20" +
          identityCard.substr(6, 2) +
          "/" +
          identityCard.substr(8, 2) +
          "/" +
          identityCard.substr(10, 2);
      } else {
        strBirthday =
          "19" +
          identityCard.substr(6, 2) +
          "/" +
          identityCard.substr(8, 2) +
          "/" +
          identityCard.substr(10, 2);
      }
    }
    //时间字符串里，必须是“/”
    let birthDate = new Date(strBirthday);
    let nowDateTime = new Date();
    let age = nowDateTime.getFullYear() - birthDate.getFullYear();
    //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
    if (
      nowDateTime.getMonth() < birthDate.getMonth() ||
      (nowDateTime.getMonth() == birthDate.getMonth() &&
        nowDateTime.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  }
  // 判断年龄是否在范围
};
export default venify;
