<template>
  <div class="dashboard">
    <p class="tit">数据统计</p>
    <div class="flexBox dataStat">
      <div class="item">
        <div>
          <p>{{ totalData.monthPolicy }}</p>
          <p>当月保单量</p>
        </div>
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-dangyuebaodanliang"></use>
        </svg>
      </div>
      <div class="item">
        <div>
          <p>{{ totalData.monthCheck }}</p>
          <p>当月批单量</p>
        </div>
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-dangyuepidanliang"></use>
        </svg>
      </div>
      <div class="item">
        <div>
          <p>{{ totalData.monthNewPolicy }}</p>
          <p>当月新单保费</p>
        </div>
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-dangyuexinzengbaofei"></use>
        </svg>
      </div>
      <div class="item">
        <div>
          <p>{{ totalData.monthNewCheck }}</p>
          <p>当月批增保费</p>
        </div>
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-dangyuepizengbaofei"></use>
        </svg>
      </div>
    </div>
    <p class="tit">待办事项</p>
    <div class="flexBox backlog">
      <div class="item" @click="skip('/audit/policy')">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-daishenhebiaodan"></use>
        </svg>
        <p>待审核保单</p>
        <el-badge
          :value="totalData.auditPolicy"
          v-show="totalData.auditPolicy != 0"
        ></el-badge>
      </div>
      <div class="item" @click="skip('/audit/policy', '15')">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-daibuchongtoubaoziliao"></use>
        </svg>
        <p>待补充保单资料</p>
        <el-badge
          :value="totalData.policyFile"
          v-show="totalData.policyFile != 0"
        ></el-badge>
      </div>
      <div class="item" @click="skip('/audit/check')">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-daishenhepidan"></use>
        </svg>
        <p>待审核批单</p>
        <el-badge
          :value="totalData.auditCheck"
          v-show="totalData.auditCheck != 0"
        ></el-badge>
      </div>
      <div class="item" @click="skip('/audit/check', '15')">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-daibuchongpigaiziliao"></use>
        </svg>
        <p>待补充批改资料</p>
        <el-badge
          :value="totalData.checkFile"
          v-show="totalData.checkFile != 0"
        ></el-badge>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/font/iconfont/iconfont.js"; // 阿里字体图标
import { getTotalData } from "@/api/public";
export default {
  name: "dashboard",
  data() {
    return {
      totalData: ""
    };
  },
  mounted() {
    this.getTotalData();
  },
  methods: {
    getTotalData() {
      let that = this;
      getTotalData()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.totalData = res.data;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 转跳
    skip(path, status) {
      this.$router.push({
        path: path,
        query: { status: status }
      });
    }
  }
};
</script>
<style lang="scss">
.dashboard {
  padding: 60px !important;
  .tit {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    margin: 0;
    margin-bottom: 35px;
  }
  .flexBox {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 25px;
    .item {
      width: calc(25% - 68px);
      // width: calc(25% - 51px);
      margin-right: 68px;
      background: #ffffff;
      box-shadow: 0px 1px 9px 0px rgba(219, 219, 219, 0.5);
      border-radius: 5px;
      padding: 25px;
      margin-bottom: 40px;
      position: relative;
    }
    // .item:nth-child(4n) {
    //   margin-right: 0;
    // }
  }
  .dataStat .item {
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      margin-right: 48px;
      p {
        font-size: 30px;
        font-weight: 400;
        color: #333333;
        margin: 0;
        text-align: center;
      }
      p + p {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        margin-top: 5px;
      }
    }
    & > svg {
      width: 60px;
    }
  }
  .backlog {
    margin-bottom: 0;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.25s;
      p {
        margin: 15px 0 0;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
      }
      & > svg {
        width: 100px;
      }
      .el-badge {
        position: absolute;
        top: 15px;
        right: 19px;
      }
    }
    .item:hover {
      box-shadow: 0px 1px 20px 11px rgba(219, 219, 219, 0.6);
    }
  }
}
</style>
