import http from "../utils/axios";
// 黑名单
// 关键词黑名单设置
export const changeBackListKeywords = data => {
  return http({
    url: "/api/back_list/changeBackListKeywords", // 接口
    method: "post",
    data: data
  });
};
// 获取关键词列表接口
export const getBackListKeywords = data => {
  return http({
    url: "/api/back_list/getBackListKeywords", // 接口
    method: "post",
    data: data
  });
};
// 获取关键词黑名单详情接口
export const getBackListKeywordsInfo = data => {
  return http({
    url: "/api/back_list/getBackListKeywordsInfo", // 接口
    method: "post",
    data: data
  });
};
//添加、编辑公司黑名单接口
export const changeCompanyBackList = data => {
  return http({
    url: "/api/back_list/changeCompanyBackList", // 接口
    method: "post",
    data: data
  });
};
//获取公司黑名单列表接口
export const getCompanyBackList = data => {
  return http({
    url: "/api/back_list/getCompanyBackList", // 接口
    method: "post",
    data: data
  });
};
//获取公司黑名单详情接口
export const getCompanyBackListInfo = data => {
  return http({
    url: "/api/back_list/getCompanyBackListInfo", // 接口
    method: "post",
    data: data
  });
};
//设置拒保地区接口
export const changeAreaBackList = data => {
  return http({
    url: "/api/back_list/changeAreaBackList", // 接口
    method: "post",
    data: data
  });
};
//获取拒保地区列表接口
export const getAreaBackList = data => {
  return http({
    url: "/api/back_list/getAreaBackList", // 接口
    method: "post",
    data: data
  });
};
//获取拒保地区详情接口
export const getAreaBackListInfo = data => {
  return http({
    url: "/api/back_list/getAreaBackListInfo", // 接口
    method: "post",
    data: data
  });
};
//删除指定产品拒保地区接口
export const unsetProductArea = data => {
  return http({
    url: "/api/back_list/unsetProductArea", // 接口
    method: "post",
    data: data
  });
};
//删除公司黑名单接口
export const unsetCompany = data => {
  return http({
    url: "/api/back_list/unsetCompany", // 接口
    method: "post",
    data: data
  });
};
//获取拒保地区详情接口
export const unsetProductKeywords = data => {
  return http({
    url: "/api/back_list/unsetProductKeywords", // 接口
    method: "post",
    data: data
  });
};
