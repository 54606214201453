import { render, staticRenderFns } from "./keyword.vue?vue&type=template&id=052eba6f&"
import script from "./keyword.vue?vue&type=script&lang=js&"
export * from "./keyword.vue?vue&type=script&lang=js&"
import style0 from "./keyword.vue?vue&type=style&index=0&id=052eba6f&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.2/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports