<template>
  <div class="NavbarBox">
    <div class="Navbarleft">
      <div @click="toggleClick" class="hamburgerbox">
        <svg
          class="hamburger"
          :class="{ isCollapse: !isCollapse }"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
        >
          <path
            d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z"
          />
        </svg>
      </div>
      <el-breadcrumb class="app-breadcrumb" separator="/">
        <transition-group name="breadcrumb">
          <el-breadcrumb-item
            v-for="item in levelList"
            :key="item.path"
            :to="item.path"
            >{{ item.meta.title }}
          </el-breadcrumb-item>
        </transition-group>
      </el-breadcrumb>
    </div>
    <!-- 右侧操作 -->
    <div class="Navbarright">
      <el-dropdown class="avatar-container" trigger="click">
        <!-- 先隐藏头像 -->
        <div class="avatar-wrapper">
          <!-- <el-avatar :size="38" :src="avatarUrl"></el-avatar> -->
          <span id="wateermark">{{ avatarName }}</span>
        </div>
        <el-dropdown-menu>
          <el-dropdown-item @click.native="changePassword">
            修改密码
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span style="display:block;">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 修改密码弹窗 -->
    <el-dialog
      title="修改密码"
      :visible="amendVisible"
      :modal-append-to-body="false"
      @close="closeAmendBtn"
      width="450px"
    >
      <el-form
        :model="amendForm"
        :rules="rules2"
        :validate-on-rule-change="false"
        ref="amendForm"
        label-width="100px"
        class="amendForm"
        size="small"
      >
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="amendForm.password"
            placeholder="密码必须是数字和字母的组合,最少六位"
            show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="repeatPassword">
          <el-input
            v-model="amendForm.repeatPassword"
            placeholder="请输入内容"
            show-password
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="amendOk" size="small">
          确 定
        </el-button>
        <el-button @click="closeAmendBtn" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { changePassword } from "@/api/system";
export default {
  name: "AppMain",
  data() {
    var password2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.amendForm.repeatPassword !== "") {
          this.$refs.amendForm.validateField("repeatPassword");
        }
        callback();
      }
    };
    var repPassWord2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.amendForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      levelList: null,
      amendVisible: false, //修改账号弹窗
      amendForm: {
        id: "",
        password: "",
        repeatPassword: ""
      },
      rules2: {
        password: [
          { required: true, message: "密码不可为空", trigger: "blur" },
          { validator: password2, trigger: "blur" }
        ],
        repeatPassword: [
          { required: true, message: "请确认密码", trigger: "blur" },
          { validator: repPassWord2, trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    // 侧边栏开关
    toggleClick() {
      this.$store.commit("alterCloseSidebar");
    },
    // 面包屑
    getBreadcrumb() {
      let matched = this.$route.matched.filter(
        item => item.meta && item.meta.title
      );
      if (matched[0].path != "/") {
        matched.unshift({ path: "/", meta: { title: "首页" } });
      }
      this.levelList = matched;
    },
    // 退出
    logout() {
      this.$store.dispatch("logout");
    },
    // 修改密码
    changePassword() {
      this.amendForm.id = this.$store.getters.id;
      this.amendVisible = true;
    },
    // 修改密码弹窗关闭
    closeAmendBtn() {
      this.$nextTick(() => {
        this.$refs.amendForm.resetFields();
      });
      this.amendVisible = false;
    },
    // 修改密码弹窗确定
    amendOk() {
      this.$refs.amendForm.validate(valid => {
        if (valid) {
          let that = this;
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          changePassword(that.amendForm)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success("密码修改成功，请重新登陆");
                that.closeAmendBtn();
                that.$store.dispatch("logout");
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  },
  watch: {
    $route: {
      handler: function() {
        this.getBreadcrumb();
      },
      immediate: true
    }
  },
  computed: {
    isCollapse() {
      // 侧边栏是否关闭
      return this.$store.state.closeSidebar;
    },
    avatarUrl() {
      if (this.$store.getters.avatar) {
        return this.$store.getters.avatar;
      } else {
        return require("@/assets/icon/avatar.png");
      }
    },
    avatarName() {
      return this.$store.getters.name;
    }
  }
};
</script>
<style lang="scss" scoped>
.NavbarBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
  z-index: 999;
  .Navbarleft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > .hamburgerbox {
      cursor: pointer;
      padding: 13px 15px;
      .hamburger {
        width: 20px;
        height: 20px;
        margin-top: 3px;
      }
      .hamburger.isCollapse {
        transform: rotate(180deg);
      }
    }
    & > .hamburgerbox:hover {
      background: rgba(0, 0, 0, 0.025);
    }
    & > .app-breadcrumb {
      padding-left: 10px;
      font-size: 15px;
    }
  }
  .Navbarright {
    padding-right: 20px;
    & > .avatar-container .avatar-wrapper {
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span {
        margin-left: 5px;
        font-size: 15px;
      }
    }
  }
}
</style>
