<template>
  <div class="loginpage">
    <div class="logobox">
      <img src="@/assets/images/logo/logo-1.png" alt="" />
    </div>
    <div class="loginbox">
      <div class="tit">锐鹿后台</div>
      <el-form
        :model="login"
        :rules="rules"
        @keypress.native.enter="landing()"
        ref="login"
        label-width="100"
        class="login_Form"
        hide-required-asterisk
        ><el-form-item label="账号" prop="username">
          <el-input
            prefix-icon="el-icon-user"
            v-model="login.username"
            placeholder="请输入账号"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model="login.password"
            autocomplete="off"
            show-password
            prefix-icon="el-icon-lock"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="landing()"
            >登陆</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { login } from "@/api/login";

export default {
  name: "Login",
  data() {
    return {
      login: {
        username: "",
        password: ""
      },
      rules: {
        username: [
          { required: true, message: "请输入账号", trigger: "blur" }
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      loading: false
    };
  },
  methods: {
    landing() {
      let that = this;
      that.$refs.login.validate(valid => {
        if (valid) {
          that.loading = true;
          login(that.login)
            .then(function(res) {
              that.loading = false;
              console.log(res);
              if (res.code == 1) {
                that.$store.commit("addToken", res.data.token);
                that.$store.commit("addUser", res.data);
                that.$router.push({ path: "/" });
                that.$store.state.isLog = true;
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              that.loading = false;
              that.$message.error(error.msg);
              console.log(error);
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss">
.loginpage {
  background-image: url("~@/assets/images/layout/loginbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  // background: #2d3a4b;
  .logobox {
    position: absolute;
    top: 30px;
    left: 55px;
    height: 34px;
    img {
      max-height: 100%;
    }
  }
  .loginbox {
    padding: 45px 35px 80px;
    background-image: url("~@/assets/images/layout/logincentrebg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 440px;
    & > .tit {
      font-size: 24px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.75);
      line-height: 33px;
    }
    & > .login_Form {
      padding-top: 45px;
      .el-form-item {
        margin-bottom: 25px;
        label {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 20px;
          margin-bottom: 8px;
        }
      }
      .el-button {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}
</style>
