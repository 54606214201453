import http from "../utils/axios";
// 审核管理
// 获取保单列表接口
export const getOrderList = data => {
  return http({
    url: "/api/order/getOrderList", // 接口
    method: "post",
    data: data
  });
};
// 获取订单详情接口
export const getOrderInfo = data => {
  return http({
    url: "/api/order/getOrderInfo", // 接口
    method: "post",
    data: data
  });
};
// 获取订单投、被保人接口
export const getOrderInsured = data => {
  return http({
    url: "/api/order/getOrderInsured", // 接口
    method: "post",
    data: data
  });
};
// 获取车险订单投、被保人接口
export const getCarOrderInsured = data => {
  return http({
    url: "/api/order/getCarOrderInsured", // 接口
    method: "post",
    data: data
  });
};
// 获取订单开票信息接口
export const getOrderInvoice = data => {
  return http({
    url: "/api/order/getOrderInvoice", // 接口
    method: "post",
    data: data
  });
};
// 获取保障人员列表接口
export const getOrderPersonnel = data => {
  return http({
    url: "/api/order/getOrderPersonnel", // 接口
    method: "post",
    data: data
  });
};
// 保单审核接口
export const orderCheck = data => {
  return http({
    url: "/api/order/orderCheck", // 接口
    method: "post",
    data: data
  });
};
// 获取批单列表接口
export const getCorrectionList = data => {
  return http({
    url: "/api/order/getCorrectionList", // 接口
    method: "post",
    data: data
  });
};
// 获取批单列表接口
export const getCorrection = data => {
  return http({
    url: "/api/order/getCorrection", // 接口
    method: "post",
    data: data
  });
};
// 获取批单人员列表接口
export const getCorrectionPerson = data => {
  return http({
    url: "/api/order/getCorrectionPerson", // 接口
    method: "post",
    data: data
  });
};
// 保单审核接口
export const correctionCheck = data => {
  return http({
    url: "/api/order/correctionCheck", // 接口
    method: "post",
    data: data
  });
};
// 上传保单、单号、发票
export const uploadPolicyFileInfo = data => {
  return http({
    url: "/api/order/uploadPolicyFileInfo", // 接口
    method: "post",
    data: data
  });
};
// 上传批单、批单号、发票
export const uploadCorrectionFileInfo = data => {
  return http({
    url: "/api/order/uploadCorrectionFileInfo", // 接口
    method: "post",
    data: data
  });
};
// 保单、批单操作记录接口
export const getOperationLog = data => {
  return http({
    url: "/api/order/getOperationLog", // 接口
    method: "post",
    data: data
  });
};
// 电子签章
// 获取电子签章列表
export const getAuthorizationAuditList = data => {
  return http({
    url: "/api/authentication/getAuthorizationAuditList", // 接口
    method: "post",
    data: data
  });
};
// 获取电子签章数据
export const getCertificationInfo = data => {
  return http({
    url: "/api/authentication/getCertificationInfo", // 接口
    method: "post",
    data: data
  });
};
// 获取电子签章日志
export const getRecordLog = data => {
  return http({
    url: "/api/authentication/getRecordLog", // 接口
    method: "post",
    data: data
  });
};
// 电子签章提交审核
export const certificateCheck = data => {
  return http({
    url: "/api/authentication/certificateCheck", // 接口
    method: "post",
    data: data
  });
};
