import { render, staticRenderFns } from "./agencyCo.vue?vue&type=template&id=08ffa08f&"
import script from "./agencyCo.vue?vue&type=script&lang=js&"
export * from "./agencyCo.vue?vue&type=script&lang=js&"
import style0 from "./agencyCo.vue?vue&type=style&index=0&id=08ffa08f&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.2/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports