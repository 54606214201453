<template>
  <div class="checkBox">
    <el-checkbox-group v-model="val">
      <el-checkbox v-for="(v, i) in options" :key="i" :label="i"
        >{{ v }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>
<script>
export default {
  name: "checkBox",
  props: {
    value: {
      type: [Array, String],
      default: Array
    },
    options: {
      type: [Array, Object],
      default: Array
    },
    gap: {
      type: String,
      default: ","
    }
  },
  data() {
    return {
      val: []
    };
  },
  methods: {},
  watch: {
    val: {
      handler: function(val) {
        this.$emit("input", this.val);
      },
      deep: true
    },
    value: {
      handler: function(e) {
        if (Array.isArray(e)) {
          this.val = e;
        } else {
          if (e) {
            this.val = e.split(this.gap);
          } else {
            this.val = [];
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
