<template>
  <div class="app-wrapper" :class="isCollapse ? 'hideSidebar' : ''">
    <sidebar class="sidebar-container" />
    <div class="main-container">
      <div>
        <navbar />
      </div>
      <app-main />
    </div>
  </div>
</template>
<script>
import { Navbar, Sidebar, AppMain } from "./components";
import watermark from "@/utils/watermark.js";
export default {
  name: "Layout",
  components: {
    Navbar,
    Sidebar,
    AppMain
  },
  mounted: function() {
    this.watermarkTit;
  },
  computed: {
    isCollapse() {
      // 侧边栏是否关闭
      return this.$store.state.closeSidebar;
    },
    watermarkTit() {
      return watermark.set(this.$store.getters.name);
    }
  }
};
</script>
<style lang="scss">
@import "@/styles/variables.scss";
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  & > .sidebar-container {
    transition: width 0.28s;
    width: 240px !important;
    background-color: $menuBg;
    height: 100%;
    position: fixed;
    font-size: 0px;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    overflow: hidden;
    & > .logobox {
      background: $logoBg;
    }
    & > .logobox a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 8px;
      height: 50px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    & > .el-scrollbar {
      height: calc(100% - 50px);
      & > .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
  }
  & > .main-container {
    min-height: 100%;
    -webkit-transition: margin-left 0.28s;
    transition: margin-left 0.28s;
    margin-left: 240px;
    position: relative;
  }
}
// 消息徽章
.el-menu {
  border-right: 0;
  .el-submenu i,
  .el-menu-item i {
    color: inherit;
    font-size: inherit;
    width: auto;
    margin-right: 3px;
  }
  .el-submenu span,
  .el-menu-item span {
    padding: 0 3px;
  }
  sup.alert {
    font-size: 12px;
    background: $menBdBg;
    padding: 5px;
    border-radius: 5px;
    line-height: 1;
    color: #fff;
    position: absolute;
    top: 17px;
    right: 16px;
    z-index: 5;
  }
  .el-menu-item sup.alert {
    background: $menBdsubBg;
  }
}
// 侧边栏隐藏时样式
.app-wrapper.hideSidebar {
  & > .sidebar-container {
    transition: width 0.28s;
    width: 65px !important;
    background-color: #304156;
    height: 100%;
    position: fixed;
    font-size: 0px;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    overflow: hidden;
    & > .logobox a {
      justify-content: center;
    }
    .el-menu {
      .el-submenu i + span {
        height: 0;
        width: 0;
        overflow: hidden;
        visibility: hidden;
        display: inline-block;
      }
      .el-submenu i,
      .el-menu-item i {
        font-size: 18px;
      }
      .el-submenu i.el-submenu__icon-arrow {
        display: none;
      }
      sup.alert {
        font-size: 0;
        padding: 3px;
        border-radius: 50%;
        right: 10px;
      }
    }
  }
  & > .main-container {
    min-height: 100%;
    -webkit-transition: margin-left 0.28s;
    transition: margin-left 0.28s;
    margin-left: 65px;
    position: relative;
  }
}
// 侧边栏隐藏时子菜单样式
</style>
