import Layout from "@/layout";
export const loadView = view => {
  // 路由懒加载
  return resolve => require([`@/views/${view}`], resolve);
};
const dataTreating = {
  filterAsyncRouter(asyncRouterMap) {
    //遍历后台传来的路由字符串，转换为组件对象
    const accessedRouters = asyncRouterMap.filter(item => {
      if (item.component) {
        if (item.component === "Layout") {
          //Layout组件特殊处理
          item.component = Layout;
        } else {
          item.component = loadView(item.component);
        }
      }
      if (item.children && item.children.length) {
        item.children = dataTreating.filterAsyncRouter(item.children);
      }
      return true;
    });

    return accessedRouters;
  }
};
export default dataTreating;
