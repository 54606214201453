<template>
  <div class="checkDetails">
    <div class="itemBox">
      <div class="bTit">
        <i class="el-icon-document"></i> 批单信息
        <div class="floatBox" v-if="info">
          <el-button
            type="primary"
            size="small"
            :disabled="info.bill_file == null || info.is_have_bill == 0"
            @click="down(info.bill_file)"
          >
            发票下载
          </el-button>
          <el-button
            type="primary"
            size="small"
            @click="down(info.policy_file)"
            :disabled="info.policy_file == null"
            >电子批单下载
          </el-button>
        </div>
      </div>
      <div class="conBox">
        <div class="guarantee" v-if="info">
          <div class="top">
            <div
              class="imgbox"
              :style="{ background: status[info.status].color }"
            >
              <i class="iconfont" :class="status[info.status].icon"></i>
              {{ info.statusTxt }}
            </div>
            <div class="center">
              <p>批单号：{{ info.correct_num ? info.correct_num : "暂无" }}</p>
              <div>
                <p>流水号：{{ info.num_id }}</p>
                <p>保险公司：{{ info.companyName }}</p>
                <p>批改时间：{{ info.c_time }}</p>
                <p>产品名称：{{ info.productName }}</p>
                <p>保障期限：{{ info.s_time }} - {{ info.e_time }}</p>
                <p>提交人：{{ info.adminName }}</p>
              </div>
            </div>
            <p class="premium">
              保费：<span>{{ info.policy_money }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="itemBox">
      <div class="bTit"><i class="el-icon-document"></i>批单信息</div>
      <div class="conBox">
        <div class="conBoxFlex" v-if="info">
          <div class="left">
            <p>投保人名称：{{ info.applicationName }}</p>
            <p>被保人名称：{{ info.insuredName }}</p>
            <p>生效时间：{{ info.s_time }}</p>
            <p>批增人数：{{ info.add_num }}个</p>
            <p>批减人数: {{ info.change_num }}个</p>
            <p>
              应缴保费：<span class="price">{{ info.policy_money }}元</span>
            </p>
          </div>
          <div class="right" v-if="info.payVoucher">
            <span>缴费凭证扫描件：</span>
            <el-image
              style="width: 100px; height: 100px;margin-right:5px"
              :src="item"
              :preview-src-list="info.payVoucher.payVoucher"
              v-for="(item, index) in info.payVoucher.payVoucher"
              :key="index"
            >
            </el-image>
          </div>
        </div>
      </div>
    </div>
    <div class="itemBox">
      <div class="bTit"><i class="el-icon-document"></i>增加人员</div>
      <div class="conBox">
        <el-table :data="addPerson" style="width: 100%" size="mini">
          <el-table-column type="index" label="序号" width="80" align="center">
          </el-table-column>
          <el-table-column prop="name" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="code" label="身份证号" align="center">
          </el-table-column>
          <el-table-column
            prop="work_cate_title"
            label="工作类型"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="money"
            label="保费金额"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="work_type_title"
            label="工种"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="address" label="保障期限" align="center">
            <template slot-scope="scope">
              {{ scope.row.s_time }} -- {{ scope.row.e_time }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="itemBox">
      <div class="bTit"><i class="el-icon-document"></i>减少人员</div>
      <div class="conBox">
        <el-table :data="delPerson" style="width: 100%" size="mini">
          <el-table-column type="index" label="序号" width="80" align="center">
          </el-table-column>
          <el-table-column prop="name" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="code" label="身份证号" align="center">
          </el-table-column>
          <el-table-column
            prop="work_cate_title"
            label="工作类型"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="money"
            label="保费金额"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="work_type_title"
            label="工种"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="保障期限" align="center">
            <template slot-scope="scope">
              {{ scope.row.s_time }} -- {{ scope.row.e_time }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="itemBox">
      <div class="bTit"><i class="el-icon-document"></i>其他文件</div>
      <div class="conBox">
        <p class="file">
          <span @click="down(info.correct_file)" v-if="info.correct_file">
            文件
          </span>
        </p>
      </div>
    </div>
    <div class="itemBox" v-if="op == 1">
      <div class="bTit"><i class="el-icon-document"></i>批改审核</div>
      <div class="conBox">
        <el-form
          ref="form"
          :model="form"
          label-width="80px"
          style="width:400px"
        >
          <el-form-item label="审核">
            <el-radio-group v-model="form.type">
              <el-radio label="1">审核通过</el-radio>
              <el-radio label="2">驳回</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" v-if="form.type == 2">
            <el-input type="textarea" v-model="form.desc"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" size="small"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="itemBox" v-if="op == 3">
      <div class="bTit"><i class="el-icon-document"></i>上传批单信息</div>
      <div class="conBox">
        <el-form
          ref="form"
          :model="form2"
          label-width="150px"
          style="width:700px"
          size="small"
        >
          <el-form-item label="批单号">
            <el-input
              v-model="form2.correctNum"
              placeholder="请输入批单号"
            ></el-input>
          </el-form-item>
          <el-form-item label="电子批单">
            <click-upload v-model="form2.policyFile"></click-upload>
          </el-form-item>
          <el-form-item label="发票信息" v-if="info.is_have_bill == 1">
            <click-upload v-model="form2.billFile"></click-upload>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit2" size="small">
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="itemBox">
      <div class="bTit"><i class="el-icon-document"></i>操作日志</div>
      <div class="conBox">
        <el-table :data="Log" border style="width: 100%" size="mini">
          <el-table-column prop="createtime" label="操作时间" align="center">
          </el-table-column>
          <el-table-column prop="username" label="操作员" align="center">
          </el-table-column>
          <el-table-column prop="title" label="操作内容" align="center">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getCorrection,
  getCorrectionPerson,
  correctionCheck,
  uploadCorrectionFileInfo,
  getOperationLog
} from "@/api/audit";
import download from "@/utils/download";
import clickUpload from "@/components/common/clickUpload";
export default {
  name: "guaDdetails",
  components: {
    clickUpload
  },
  data() {
    return {
      status: [],
      changeId: "",
      op: "",
      info: "", //订单详情
      delPerson: [], //减少人员
      addPerson: [], //添加人员
      Log: [],
      form: {
        type: "1",
        desc: ""
      },
      form2: {
        correctNum: "",
        policyFile: [],
        billFile: []
      }
    };
  },
  created() {
    this.status = this.$store.state.orderStatus;
    this.changeId = this.$route.query.changeId;
    this.op = this.$route.query.op;
    this.getCorrection(); //获取订单详情
    this.getCorrectionPerson(); //获取批单人员列表
    this.getOperationLog(); //操作记录接口
  },
  methods: {
    //获取订单详情
    getCorrection() {
      let that = this;
      getCorrection({ changeId: this.changeId })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.info = res.data;
            that.form2 = {
              correctNum: res.data.correct_num,
              policyFile: [],
              billFile: []
            };
            if (res.data.policy_file) {
              that.form2.policyFile[0] = {
                url: res.data.policy_file,
                name: "电子批单"
              };
            }
            if (res.data.is_have_bill == 1) {
              if (res.data.bill_file) {
                that.form2.billFile[0] = {
                  url: res.data.bill_file,
                  name: "发票信息"
                };
              }
            }
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }, //获取批单人员列表接口
    getCorrectionPerson() {
      let that = this;
      getCorrectionPerson({ changeId: this.changeId })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.addPerson = res.data.addPerson;
            that.delPerson = res.data.delPerson;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 操作记录
    getOperationLog() {
      let that = this;
      getOperationLog({ changeId: this.changeId })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.Log = res.data;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 下载
    down(downurl) {
      download(downurl);
    },
    // 提交审核
    onSubmit() {
      let that = this;
      let _data = this.form;
      _data.changeId = this.changeId;
      let loadind = that.$loading({
        lock: true,
        text: "已提交审核，邮件发送中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      correctionCheck(_data)
        .then(function(res) {
          console.log(res);
          loadind.close();
          if (res.code == 1) {
            that.$message.success(res.msg);
            that.$router.push({
              path: "/audit/check"
            });
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 上传保单信息
    onSubmit2() {
      let that = this;
      let _data = {};
      _data.changeId = this.changeId;
      _data.correctNum = this.form2.correctNum;
      if (this.form2.policyFile.length > 0) {
        _data.policyFile = this.form2.policyFile[0].url;
      }
      if (this.form2.billFile.length > 0) {
        _data.billFile = this.form2.billFile[0].url;
      }
      if (
        this.form2.correctNum ||
        this.form2.policyFile.length > 0 ||
        this.form2.billFile.length > 0
      ) {
        let loadind = that.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        uploadCorrectionFileInfo(_data)
          .then(function(res) {
            console.log(res);
            loadind.close();
            if (res.code == 1) {
              that.$message.success(res.msg);
              that.$router.push({
                path: "/audit/check"
              });
            } else {
              that.$message.error(res.msg);
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      } else {
        that.$message.error("必须要添加一项内容才可以提交");
      }
    }
  }
};
</script>
<style lang="scss">
.checkDetails {
  .itemBox:nth-child(1) {
    margin: 0;
  }
  .itemBox {
    background: #fff;
    margin-top: 15px;
    .bTit {
      font-size: 16px;
      color: #666;
      padding: 20px;
      margin: 0;
      border-bottom: 1px solid #eee;
      i {
        margin-right: 5px;
        background: #4f8bf0;
        padding: 5px;
        border-radius: 50%;
        color: #fff;
        font-size: 12px;
      }
      .floatBox {
        float: right;
        margin-top: -4px;
      }
    }
    .conBox {
      padding: 20px 45px;

      p {
        margin: 0 0 15px;
        font-size: 12px;
        color: #333;
      }
      .guarantee {
        .top {
          display: flex;
          justify-content: space-between;
          .imgbox {
            width: 80px;
            height: 80px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            border-radius: 5px;
            color: #fff;
            font-size: 14px;
            i {
              font-size: 30px;
            }
          }
          .center {
            flex: 1;
            padding-left: 20px;
            & > p {
              font-weight: 600;
              font-size: 20px;
              margin: 0;
              span {
                margin-left: 25px;
                color: #999;
                font-size: 12px;
                font-weight: 400;
              }
            }
            & > div {
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              p {
                width: 33%;
                padding-right: 15px;
                margin: 8px 0 0;
                color: #999;
                font-size: 13px;
              }
            }
          }
          .premium {
            font-weight: 600;
            color: #333;
            font-size: 12px;
            margin: 55px 0 0;
            span {
              font-style: normal;
              color: #dc3545;
              font-size: 19px;
            }
          }
        }
      }
      .conBoxFlex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .left {
          min-width: 550px;
          margin-right: 20px;
          padding-right: 20px;
          border-right: 1px solid #eee;
          .price {
            color: #dc3545;
            font-size: 19px;
          }
          .link {
            color: #5b8cff;
            cursor: pointer;
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          span {
            font-size: 12px;
            color: #333;
          }
        }
      }
      .file {
        margin: 0 0 15px;
        font-size: 12px;
        color: #333;
        span:hover {
          color: #5b8cff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
