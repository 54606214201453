import http from "../utils/axios";

// 获取产品分类 - 筛选用接口
export const getProductCate = data => {
  return http({
    url: "/api/product/getProductCate", // 接口
    method: "post",
    data: data
  });
};

// 获取保司信息 - 筛选用接口
export const getCompany = data => {
  return http({
    url: "/api/company/getCompany", // 接口
    method: "post",
    data: data
  });
};
// 获取产品列表 - 下拉框使用接口
export const getSelectProductList = data => {
  return http({
    url: "/api/product/getSelectProductList", // 接口
    method: "post",
    data: data
  });
};
// 获取地区库接口
export const getAreaList = data => {
  return http({
    url: "/api/common/getAreaList", // 接口
    method: "post",
    data: data
  });
};
// 获取后台管理系统首页统计数据
export const getTotalData = data => {
  return http({
    url: "/api/order/getTotalData", // 接口
    method: "post",
    data: data
  });
};
