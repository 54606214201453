<template>
  <div class="signature">
    <div class="itemBox">
      <div class="bTit"><i class="el-icon-document"></i>企业信息</div>
      <div class="conBox">
        <div class="conBoxFlex">
          <div class="left">
            <p>企业名称：{{ info.name }}</p>
            <p>社会信用代码：{{ info.regCode }}</p>
            <p>法人姓名：{{ info.legalPersonName }}</p>
          </div>
          <div class="right">
            <span>营业执照：</span>
            <el-image
              style="width: 100px; height: 100px"
              :src="info.businessLicense"
              :preview-src-list="[info.businessLicense]"
            >
            </el-image>
          </div>
        </div>
      </div>
    </div>
    <div class="itemBox">
      <div class="bTit"><i class="el-icon-document"></i>企业代理人资料</div>
      <div class="conBox">
        <div class="conBoxFlex">
          <div class="left">
            <p>平台注册用户名:{{ info.uName }}</p>
            <p>代理人姓名：{{ info.legalPerson }}</p>
            <p>代理人身份证号：{{ info.legalPersonIdentity }}</p>
            <p>代理人手机号：{{ info.mobile }}</p>
          </div>
          <div class="right">
            <span>企业代理人身份证：</span>
            <el-image
              style="width: 100px; height: 100px"
              :src="info.idCard"
              :preview-src-list="[info.idCard]"
            >
            </el-image>
          </div>
        </div>
      </div>
    </div>
    <div class="itemBox">
      <div class="bTit"><i class="el-icon-document"></i>授权书资料</div>
      <div class="conBox">
        <el-image
          style="width: 100px; height: 100px"
          :src="info.authorization"
          :preview-src-list="[info.authorization]"
        >
        </el-image>
      </div>
    </div>
    <div class="itemBox">
      <div class="bTit"><i class="el-icon-document"></i>企业联系人资料</div>
      <div class="conBox">
        <div class="left">
          <p>联系人姓名：{{ info.contactsName }}</p>
          <p>联系人手机号：{{ info.contactsMobile }}</p>
          <p>联系人邮箱：{{ info.contactsEmail }}</p>
        </div>
      </div>
    </div>
    <div class="itemBox" v-if="op == 1">
      <div class="bTit"><i class="el-icon-document"></i>保单审核</div>
      <div class="conBox">
        <el-form
          ref="form"
          :model="form"
          label-width="150px"
          style="width:400px"
        >
          <el-form-item label="审核">
            <el-radio-group v-model="form.checkStatus">
              <el-radio label="5">审核通过</el-radio>
              <el-radio label="3">驳回</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" v-if="form.type == 3">
            <el-input type="textarea" v-model="form.remark"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" size="small">
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="itemBox">
      <div class="bTit"><i class="el-icon-document"></i>操作日志</div>
      <div class="conBox">
        <el-table :data="Log" border style="width: 100%" size="mini">
          <el-table-column prop="created_at" label="操作时间" align="center">
          </el-table-column>
          <el-table-column prop="username" label="操作员" align="center">
          </el-table-column>
          <el-table-column prop="action" label="操作内容" align="center">
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getCertificationInfo,
  certificateCheck,
  getRecordLog
} from "@/api/audit";
export default {
  name: "guaDdetails",
  data() {
    return {
      status: [],
      regCode: "",
      id: "",
      op: "",
      info: "", //详情
      Log: [],
      form: {
        checkStatus: "5",
        remark: ""
      }
    };
  },
  created() {
    this.regCode = this.$route.query.regCode;
    this.id = this.$route.query.id;
    this.op = this.$route.query.op;
    this.getCertificationInfo(); //获取详情
    this.getOperationLog(); //日志
  },
  methods: {
    //获取订单详情
    getCertificationInfo() {
      let that = this;
      getCertificationInfo({
        regCode: this.regCode,
        id: this.id,
        uid: this.$store.getters.id
      })
        .then(function(res) {
          console.log(res);
          if (res.code == 200) {
            that.info = res.data;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 操作记录
    getOperationLog() {
      let that = this;
      getRecordLog({ targetId: this.id, type: 5, uid: this.$store.getters.id })
        .then(function(res) {
          console.log(res);
          if (res.code == 200) {
            that.Log = res.data.list;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 提交审核
    onSubmit() {
      let that = this;
      let _data = this.form;
      _data.checkId = this.id;
      _data.uid = this.$store.getters.id;
      let loadind = that.$loading({
        lock: true,
        text: "loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      certificateCheck(_data)
        .then(function(res) {
          console.log(res);
          loadind.close();
          if (res.code == 200) {
            that.$message.success(res.msg);
            that.$router.push({
              path: "/audit/signature"
            });
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>
<style lang="scss">
.signature {
  .itemBox:nth-child(1) {
    margin: 0;
  }
  .itemBox {
    background: #fff;
    margin-top: 15px;
    .bTit {
      font-size: 16px;
      color: #666;
      padding: 20px;
      margin: 0;
      border-bottom: 1px solid #eee;
      i {
        margin-right: 5px;
        background: #4f8bf0;
        padding: 5px;
        border-radius: 50%;
        color: #fff;
        font-size: 12px;
      }
      .floatBox {
        float: right;
        margin-top: -4px;
      }
    }
    .conBox {
      padding: 20px 45px;
      p {
        margin: 0 0 15px;
        font-size: 12px;
        color: #333;
      }
      .guarantee {
        .top {
          display: flex;
          justify-content: space-between;
          .imgbox {
            width: 80px;
            height: 80px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            border-radius: 5px;
            color: #fff;
            font-size: 14px;
            i {
              font-size: 30px;
            }
          }
          .center {
            flex: 1;
            padding-left: 20px;
            & > p {
              font-weight: 600;
              font-size: 20px;
              margin: 0;
            }
            & > div {
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              p {
                width: 33%;
                padding-right: 15px;
                margin: 8px 0 0;
                color: #999;
                font-size: 13px;
              }
            }
          }
          .premium {
            font-weight: 600;
            color: #333;
            font-size: 12px;
            margin: 55px 0 0;
            span {
              font-style: normal;
              color: #dc3545;
              font-size: 19px;
            }
          }
        }
        .bottom {
          padding: 20px 30px;
          background: #f5f5f5;
          border-radius: 8px;
          margin-top: 25px;
          position: relative;
          & > p {
            font-weight: 600;
            font-size: 14px;
            margin: 0 0 15px;
          }
          & > div {
            font-size: 12px;
            color: #333;
            span {
              display: inline-block;
              margin-right: 20px;
            }
          }
        }
        .bottom:before {
          content: "";
          width: 10px;
          height: 10px;
          background: #f5f5f5;
          display: block;
          position: absolute;
          top: -5px;
          left: 50px;
          transform: rotate(45deg);
        }
      }
      .conBoxFlex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .left {
          min-width: 550px;
          margin-right: 20px;
          padding-right: 20px;
          border-right: 1px solid #eee;
          .price {
            color: #dc3545;
            font-size: 19px;
          }
          .link {
            color: #5b8cff;
            cursor: pointer;
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          span {
            font-size: 12px;
            color: #333;
          }
        }
      }
      .file {
        margin: 0 0 15px;
        font-size: 12px;
        color: #333;
        span {
          color: #5b8cff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
