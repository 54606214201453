<template>
  <div class="workList" v-if="routeClass == 2">
    <search-form
      :searcharray="searcharray"
      :span="6"
      @change="search"
      @reset="reset"
    ></search-form>
    <el-button type="primary" size="small" icon="el-icon-plus" @click="add()"
      >添加
    </el-button>
    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%;margin-top:15px"
      row-key="id"
      border
      stripe
      lazy
      size="small"
      class="teamTtable"
    >
      <el-table-column prop="id" label="ID" align="center" width="80px">
      </el-table-column>
      <el-table-column prop="company_name" align="center" label="保险公司">
      </el-table-column>
      <el-table-column prop="title" align="center" label="工种库名称">
      </el-table-column>
      <el-table-column prop="u_time" align="center" label="最后编辑时间">
      </el-table-column>
      <el-table-column prop="state" align="center" label="状态">
        <template slot-scope="scope">
          <el-tag type="success" size="mini" v-if="scope.row.status == 1">
            启用
          </el-tag>
          <el-tag type="danger" size="mini" v-else>禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="handleEdit(scope.row.id)">
            编辑
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="look(scope.row.company_id, scope.row.id)"
          >
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :page-sizes="pageSizeArray"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!--弹窗 -->
    <div class="teamPop">
      <el-dialog
        :title="popTitle"
        :visible.sync="dialogState"
        width="540px"
        custom-class="teamPop"
        @close="dialogClose"
      >
        <el-form
          :model="workForm"
          :rules="rules"
          :validate-on-rule-change="false"
          ref="workForm"
          label-width="130px"
          class="workForm"
          size="small"
        >
          <el-form-item label="保险公司" prop="company_id">
            <el-select
              filterable
              v-model="workForm.company_id"
              placeholder="请选择"
              style="width:100%"
            >
              <el-option
                v-for="(item, index) in searcharray[1].option"
                :label="item.label"
                :value="item.val"
                :key="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工种库名称" prop="title">
            <el-input
              v-model="workForm.title"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="工种库状态" prop="status">
            <el-radio v-model="workForm.status" :label="1">启用</el-radio>
            <el-radio v-model="workForm.status" :label="0">禁用</el-radio>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="dialogOk()">
            确 定
          </el-button>
          <el-button @click="dialogClose()" size="small">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
  <router-view v-else />
</template>
<script>
import searchForm from "@/components/common/searchForm";
import { getCompany } from "@/api/public";
import {
  changeWorkLibrary,
  getWorkLibrary,
  getWorkLibraryList
} from "@/api/productAdmin";
export default {
  name: "workList",
  data() {
    return {
      routeClass: "",
      searcharray: [
        {
          label: "工种库名称",
          val: "title",
          type: "input",
          pla: "请输入工种库"
        },
        {
          label: "保险公司",
          val: "company_id",
          type: "select",
          pla: "请选择保险公司",
          option: []
        },
        {
          label: "工种库状态",
          val: "status",
          type: "select",
          pla: "请选择工种库状态",
          option: [
            { label: "启用", val: "1" },
            { label: "禁用", val: "0" }
          ]
        }
      ],
      searchData: {},
      loading: false,
      tableData: [{ id: 1 }],
      // 分页 每页条数
      pageSize: 15,
      // 分页 每页条数可选项
      pageSizeArray: [15, 30, 45, 60],
      // 当前页
      page: 1,
      // 总页数
      total: 0,
      popTitle: "",
      dialogState: false,
      workForm: {
        company_id: "",
        title: "",
        status: 1
      },
      rules: {
        company_id: [
          { required: true, message: "保险公司不能为空", trigger: "blur" }
        ],
        title: [
          { required: true, message: "工种库名称不能为空", trigger: "blur" }
        ]
      }
    };
  },
  components: {
    searchForm
  },
  mounted() {
    // 获取列表
    this.getWorkLibraryList();
    // 获取保司
    this.getCompany();
    // 获取当前路由等级
    this.routeClass = this.$route.matched.length;
  },
  methods: {
    // 搜索数据
    search(e) {
      this.page = 1;
      this.searchData = e;
      console.log(e);
      this.getWorkLibraryList();
    },
    //搜索重置
    reset(e) {
      this.page = 1;
      this.searchData = {};
      this.getWorkLibraryList();
    },
    // 获取列表
    getWorkLibraryList() {
      let that = this;
      let _data = this.searchData;
      _data.page = this.page;
      _data.limit = this.pageSize;
      that.loading = true;
      getWorkLibraryList(_data)
        .then(function(res) {
          that.loading = false;
          console.log(res);
          if (res.code == 1) {
            that.tableData = res.data.list;
            that.total = res.data.total;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 每页几条
    sizeChange() {
      this.getWorkLibraryList();
    },
    // 页码变化
    currentChange() {
      this.getWorkLibraryList();
    },
    // 获取保司列表
    getCompany() {
      let that = this;
      getCompany()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            res.data.forEach((item, index) => {
              let _obj = { label: "", val: "" };
              _obj.label = item.name;
              _obj.val = item.id;
              that.searcharray[1].option.push(_obj);
            });
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 打开弹窗
    add() {
      this.workForm = {
        company_id: "",
        title: "",
        status: 1
      };
      this.popTitle = "添加";
      this.dialogState = true;
    }, // 编辑
    handleEdit(e) {
      console.log(e);
      let that = this;
      that.dialogState = true;
      let _data = {
        id: e
      };
      getWorkLibrary(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.popTitle = "编辑";
            that.workForm = {
              id: res.data.id,
              title: res.data.title,
              company_id: res.data.company_id,
              status: res.data.status
            };
            that.dialogState = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 弹窗确定
    dialogOk() {
      this.$refs.workForm.validate(valid => {
        let that = this;
        if (valid) {
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          changeWorkLibrary(that.workForm)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.getWorkLibraryList();
                that.dialogClose();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 查看工种库
    look(company_id, lib_id) {
      console.log(company_id, lib_id);
      this.$router.push({
        path: "/work/details",
        query: { company_id: company_id, lib_id: lib_id }
      });
    },
    //关闭取消
    dialogClose() {
      this.$nextTick(() => {
        this.$refs.workForm.resetFields();
      });
      this.dialogState = false;
    }
  }
};
</script>
<style lang="scss">
.workList {
  background: #fff;
  .workForm {
    width: calc(100% - 100px);
  }
}
</style>
