import http from "../utils/axios";
// 产品管理
// 获取产品基础配置
export const getProductBase = data => {
  return http({
    url: "/api/product/getProductBase", // 接口
    method: "post",
    data: data
  });
};
// 添加、编辑产品信息接口
export const changeProduct = data => {
  return http({
    url: "/api/product/changeProduct", // 接口
    method: "post",
    data: data
  });
};
// 获取产品列表接口
export const getProductList = data => {
  return http({
    url: "/api/product/getProductList", // 接口
    method: "post",
    data: data
  });
};
// // 获取保司信息 - 筛选用接口
// export const getCompany = data => {
//   return http({
//     url: "/api/company/getCompany", // 接口
//     method: "post",
//     data: data
//   });
// };
// // 获取产品分类 - 筛选用接口
// export const getProductCate = data => {
//   return http({
//     url: "/api/product/getProductCate", // 接口
//     method: "post",
//     data: data
//   });
// };
// 添加、编辑产品特殊属性接口
export const changeProAttr = data => {
  return http({
    url: "/api/product/changeProAttr", // 接口
    method: "post",
    data: data
  });
};
// 获取产品特殊属性列表接口
export const getProductAttrList = data => {
  return http({
    url: "/api/product/getProductAttrList", // 接口
    method: "post",
    data: data
  });
};
// 获取产品特殊属性列表接口
export const getProductAttr = data => {
  return http({
    url: "/api/product/getProductAttr", // 接口
    method: "post",
    data: data
  });
};
// 获取产品特殊属性信息接口
export const getProductAttrInfo = data => {
  return http({
    url: "/api/product/getProductAttrInfo", // 接口
    method: "post",
    data: data
  });
};
// 添加产品特殊属性字段接口
export const changeProductAttrValue = data => {
  return http({
    url: "/api/product/changeProductAttrValue", // 接口
    method: "post",
    data: data
  });
};
// 获取产品特殊属性字段列表接口
export const getProductAttrFieldList = data => {
  return http({
    url: "/api/product/getProductAttrFieldList", // 接口
    method: "post",
    data: data
  });
};
// 获取产品特殊属性字段信息接口
export const getProductAttrFileInfo = data => {
  return http({
    url: "/api/product/getProductAttrFileInfo", // 接口
    method: "post",
    data: data
  });
};
// 添加、编辑产品投保规则接口
export const changeProductRule = data => {
  return http({
    url: "/api/product_rule/changeProductRule", // 接口
    method: "post",
    data: data
  });
};
// 获取产品投保规则信息接口
export const getProductRule = data => {
  return http({
    url: "/api/product_rule/getProductRule", // 接口
    method: "post",
    data: data
  });
};
//  、/////工种
// 添加、编辑工种库接口
export const changeWorkLibrary = data => {
  return http({
    url: "/api/work/changeWorkLibrary", // 接口
    method: "post",
    data: data
  });
};
// 获取工种库信息接口
export const getWorkLibrary = data => {
  return http({
    url: "/api/work/getWorkLibrary", // 接口
    method: "post",
    data: data
  });
};
// 获取工种库列表接口
export const getWorkLibraryList = data => {
  return http({
    url: "/api/work/getWorkLibraryList", // 接口
    method: "post",
    data: data
  });
};
// 添加、编辑 职业类别接口
export const changeWorkCategory = data => {
  return http({
    url: "/api/work/changeWorkCategory", // 接口
    method: "post",
    data: data
  });
};
// 获取职业类别列表接口
export const getWorkCategoryList = data => {
  return http({
    url: "/api/work/getWorkCategoryList", // 接口
    method: "post",
    data: data
  });
};
// 获取职业类别信息接口
export const getWorkCategoryInfo = data => {
  return http({
    url: "/api/work/getWorkCategoryInfo", // 接口
    method: "post",
    data: data
  });
};
// 添加、编辑工种接口
export const changeWorkType = data => {
  return http({
    url: "/api/work/changeWorkType", // 接口
    method: "post",
    data: data
  });
};
// 获取工种列表接口
export const getWorkTypeList = data => {
  return http({
    url: "/api/work/getWorkTypeList", // 接口
    method: "post",
    data: data
  });
};
// 获取工种信息接口
export const getWorkType = data => {
  return http({
    url: "/api/work/getWorkType", // 接口
    method: "post",
    data: data
  });
};
// 批量上传工种接口
export const uploadWorkType = data => {
  return http({
    url: "/api/work/uploadWorkType", // 接口
    method: "post",
    data: data
  });
};
//添加、编辑产品方案接口
export const changeProductPlan = data => {
  return http({
    url: "/api/product_plan/changeProductPlan", // 接口
    method: "post",
    data: data
  });
};
//获取产品方案信息接口
export const getProductPlan = data => {
  return http({
    url: "/api/product_plan/getProductPlan", // 接口
    method: "post",
    data: data
  });
};
//获取工种库 - 下拉框使用接口
export const getWorkLibrarySelect = data => {
  return http({
    url: "/api/work/getWorkLibrarySelect", // 接口
    method: "post",
    data: data
  });
};
//获取职业类别 - 下拉框使用接口
export const getWorkCateSelect = data => {
  return http({
    url: "/api/work/getWorkCateSelect", // 接口
    method: "post",
    data: data
  });
};
//配置产品佣金接口
export const changeProductCommission = data => {
  return http({
    url: "/api/product_commission/changeProductCommission", // 接口
    method: "post",
    data: data
  });
};
//获取产品佣金配置信息接口
export const getProductCommission = data => {
  return http({
    url: "/api/product_commission/getProductCommission", // 接口
    method: "post",
    data: data
  });
};
//获取经代公司 - 下拉框使用接口
export const getAgentCompanyList = data => {
  return http({
    url: "/api/agent/getAgentCompanyList", // 接口
    method: "post",
    data: data
  });
};
//批量更新保前审核、工种状态接口
export const changeAllWorkType = data => {
  return http({
    url: "/api/work/changeAllWorkType", // 接口
    method: "post",
    data: data
  });
};
//获取等级账户列表接口
export const getAdminLevelList = data => {
  return http({
    url: "/api/admin/getAdminLevelList", // 接口
    method: "post",
    data: data
  });
};
//批量设置指定账户产品佣金接口
export const changeProductAllChannel = data => {
  return http({
    url: "/api/product_channel/changeProductAllChannel", // 接口
    method: "post",
    data: data
  });
};
// 产品一键复制
export const copyProductInfo = data => {
  return http({
    url: "/api/product/copyProductInfo", // 接口
    method: "post",
    data: data
  });
};
// 批量设置负责人
export const batchFuzeren = data => {
    return http({
      url: "/api/product/batchFuzeren", // 接口
      method: "post",
      data: data
    });
  };
