<template>
  <div class="fieldsList">
    <search-form
      :searcharray="searcharray"
      :span="6"
      @change="search"
      @reset="reset"
    ></search-form>
    <el-button type="primary" size="small" icon="el-icon-plus" @click="add()">
      添加
    </el-button>
    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%;margin-top:15px"
      row-key="id"
      border
      stripe
      lazy
      size="small"
      class="tableList"
    >
      <el-table-column prop="id" label="ID" align="center" width="80px">
      </el-table-column>
      <el-table-column prop="title" align="center" label="名称">
      </el-table-column>
      <el-table-column prop="cate" align="center" label="类型">
        <template slot-scope="scope">
          {{ changeText[scope.row.cate] + " (" + scope.row.cate + ")" }}
        </template>
      </el-table-column>
      <el-table-column prop="c_time" align="center" label="创建时间">
      </el-table-column>
      <el-table-column prop="state" align="center" label="字段状态">
        <template slot-scope="scope">
          <el-tag type="success" size="mini" v-if="scope.row.status == 1">
            启用
          </el-tag>
          <el-tag type="danger" size="mini" v-else>禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="desc" align="center" label="备注">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="handleEdit(scope.row.id)">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :page-sizes="pageSizeArray"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!--弹窗 -->
    <div class="fieldsPop">
      <el-dialog
        :title="popTitle"
        :visible.sync="dialogState"
        width="540px"
        @close="dialogClose"
      >
        <el-form
          :model="fieldsForm"
          :rules="rules"
          :validate-on-rule-change="false"
          ref="fieldsForm"
          label-width="100px"
          size="small"
          class="fieldsForm"
        >
          <el-form-item label="名称" prop="title">
            <el-input
              v-model="fieldsForm.title"
              placeholder="请输入内容"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="类别" prop="cate">
            <el-select
              filterable
              v-model="fieldsForm.cate"
              placeholder="请选择"
              style="width:100%"
            >
              <el-option
                v-for="(item, index) in changeText"
                :key="index"
                :label="item"
                :value="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="选项"
            prop="value"
            v-if="fieldsForm.cate == 'select' || fieldsForm.cate == 'ratio'"
          >
            <el-input
              v-model.trim="fieldsForm.value"
              placeholder="以键值形式输入多个使用英文逗号“,” 分隔。 如：0:选项一,1:选项二"
              type="textarea"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-radio v-model="fieldsForm.status" :label="1">启用</el-radio>
            <el-radio v-model="fieldsForm.status" :label="0">禁用</el-radio>
          </el-form-item>
          <el-form-item label="备注" prop="desc">
            <el-input
              v-model.trim="fieldsForm.desc"
              placeholder="请输入内容"
              type="textarea"
              show-word-limit
              maxlength="120"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="dialogOk()">
            确 定
          </el-button>
          <el-button @click="dialogClose()" size="small">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import searchForm from "@/components/common/searchForm";
import {
  changeProductAttrValue,
  getProductAttrFieldList,
  getProductAttrFileInfo
} from "@/api/productAdmin";
export default {
  name: "fields",
  components: {
    searchForm
  },
  data() {
    return {
      searcharray: [
        {
          label: "名称",
          val: "title",
          type: "input",
          pla: "请输入名称"
        },
        {
          label: "状态",
          val: "status",
          type: "select",
          pla: "请选择状态",
          option: [
            { label: "启用", val: "1" },
            { label: "禁用", val: "0" }
          ]
        }
      ],
      changeText: {
        input: "输入框",
        inputnum: "数字输入框",
        ratio: "单选",
        select: "下拉框",
        text: "多行输入框",
        richtext: "富文本",
        file: "上传单个",
        files: "上传多个"
      },
      // 搜索数据
      searchVal: {},
      id: "",
      // 列表数据
      tableData: [],
      // 分页 每页条数
      pageSize: 15,
      // 分页 每页条数可选项
      pageSizeArray: [15, 30, 45, 60],
      // 当前页
      page: 1,
      // 总页数
      total: 0,
      // 列表lodind
      loading: false,
      popTitle: "添加",
      dialogState: false,
      fieldsForm: {
        attr_id: "",
        id: "",
        title: "",
        cate: "",
        value: "",
        status: 1,
        desc: ""
      },
      rules: {
        title: [
          { required: true, message: "类别名称不能为空", trigger: "blur" }
        ],
        cate: [{ required: true, message: "类别不能为空", trigger: "blur" }],
        value: [{ required: true, message: "选项不能为空", trigger: "blur" }]
      }
    };
  },
  mounted() {
    var attr_id = this.$route.query.id;
    this.attr_id = attr_id;
    this.getProductAttrFieldList();
  },
  methods: {
    search(e) {
      //搜索
      this.page = 1;
      this.searchVal = e;
      this.getProductAttrFieldList();
    },
    reset() {
      //重置
      this.page = 1;
      this.searchVal = {};
      this.getProductAttrFieldList();
    },
    //添加类别弹窗
    add() {
      this.attr_id;
      this.fieldsForm = {
        attr_id: this.attr_id,
        id: "",
        title: "",
        cate: "",
        value: "",
        status: 1,
        desc: ""
      };
      this.popTitle = "添加";
      this.dialogState = true;
    },
    //编辑类别弹窗
    handleEdit(e) {
      let that = this;
      let _data = {
        id: e
      };
      getProductAttrFileInfo(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.popTitle = "编辑";
            that.fieldsForm = {
              id: res.data.id,
              attr_id: res.data.attr_id,
              title: res.data.title,
              cate: res.data.cate,
              value: res.data.value,
              status: res.data.status,
              desc: res.data.desc
            };
            that.dialogState = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 关闭弹窗
    dialogClose() {
      this.$nextTick(() => {
        this.$refs["fieldsForm"].resetFields();
      });
      this.dialogState = false;
    },
    // 弹窗确认
    dialogOk() {
      this.$refs.fieldsForm.validate(valid => {
        let that = this;
        if (valid) {
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          changeProductAttrValue(that.fieldsForm)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.getProductAttrFieldList();
                that.dialogClose();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取列表
    getProductAttrFieldList() {
      let that = this;
      let _data = that.searchVal;
      _data.attr_id = that.attr_id;
      _data.page = this.page;
      _data.limit = this.pageSize;
      that.loading = true;
      getProductAttrFieldList(_data)
        .then(function(res) {
          console.log(res);
          that.loading = false;
          if (res.code == 1) {
            that.tableData = res.data.list;
            that.total = res.data.total;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 每页几条
    sizeChange() {
      this.getProductAttrFieldList();
    },
    // 页码变化
    currentChange() {
      this.getProductAttrFieldList();
    }
  }
};
</script>
<style lang="scss">
.fieldsList {
  padding: 30px;
  background: #fff;
  min-height: 100%;
  .fieldsPop .fieldsForm {
    width: calc(100% - 100px);
  }
}
</style>
