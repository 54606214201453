<template>
  <div class="agencyCo">
    <searchForm
      :searcharray="searcharray"
      :span="6"
      @change="search"
      @reset="reset"
    ></searchForm>
    <el-button type="primary" size="small" icon="el-icon-plus" @click="add()">
      添加
    </el-button>
    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%;margin-top:15px"
      row-key="id"
      border
      stripe
      lazy
      size="small"
      class="tableList"
    >
      <el-table-column prop="id" label="ID" align="center" width="80px">
      </el-table-column>
      <el-table-column prop="title" align="center" label="公司名称">
      </el-table-column>
      <el-table-column prop="legal_person" align="center" label="法人代表">
      </el-table-column>
      <el-table-column prop="phone" align="center" label="电话">
      </el-table-column>
      <el-table-column prop="credit_code" align="center" label="信用编码">
      </el-table-column>
      <el-table-column prop="state" align="center" label="信息状态">
        <template slot-scope="scope">
          <el-tag type="success" size="mini" v-if="scope.row.status == 1">
            发布
          </el-tag>
          <el-tag type="danger" size="mini" v-else-if="scope.row.status == 0">
            不发布
          </el-tag>
          <el-tag type="warning" size="mini" v-else>整理中</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="350">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="addScheme(scope.row.id)">
            添加合作方案
          </el-button>
          <el-button type="text" size="mini" @click="addBranch(scope.row.id)">
            添加分支机构管理
          </el-button>
          <el-button type="text" size="mini" @click="handleEdit(scope.row.id)">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :page-sizes="pageSizeArray"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!--弹窗 -->
    <div class="agencyPop">
      <el-dialog
        :title="popTitle"
        :visible.sync="dialogState"
        top="15px"
        width="850px"
        custom-class="agencyPop"
        @close="dialogClose"
      >
        <el-scrollbar>
          <el-form
            :model="agencyForm"
            :rules="rules"
            :validate-on-rule-change="false"
            ref="agencyForm"
            label-width="150px"
            class="agencyForm"
            size="small"
            v-if="option == 'agency'"
          >
            <el-form-item label="公司名称" prop="title">
              <el-input
                v-model="agencyForm.title"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="经代扣费" prop="deduction">
              <el-input
                v-model="agencyForm.deduction"
                placeholder="经代扣费为 1-100百分比"
              ></el-input>
            </el-form-item>
            <el-form-item label="注册资本" prop="capital">
              <el-input
                v-model="agencyForm.capital"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="成立时间" prop="establishment">
              <el-input
                v-model="agencyForm.establishment"
                placeholder="请输入日期。如：2021-05-06"
              ></el-input>
            </el-form-item>
            <el-form-item label="法人代表" prop="legal_person">
              <el-input
                v-model="agencyForm.legal_person"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="信用编码" prop="credit_code">
              <el-input
                v-model="agencyForm.credit_code"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司地址" prop="company_address">
              <el-input
                v-model="agencyForm.company_address"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="经营范围" prop="business_nature">
              <el-input
                v-model="agencyForm.business_nature"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="营业执照" prop="business_license">
              <click-upload
                v-model="agencyForm.business_license"
                :multiple="false"
                accept=".jpg, .jpeg, .png"
              ></click-upload>
            </el-form-item>
            <el-form-item label="业务许可证" prop="business_permit">
              <click-upload
                v-model="agencyForm.business_permit"
                :multiple="false"
                accept=".jpg, .jpeg, .png"
              ></click-upload>
            </el-form-item>
            <el-form-item label="经代/保司图片" prop="agent_img">
              <click-upload
                v-model="agencyForm.agent_img"
                :multiple="false"
                accept=".jpg, .jpeg, .png"
              ></click-upload>
            </el-form-item>
            <el-form-item label="经代/保司域名" prop="agent_domain">
              <el-input
                v-model="agencyForm.agent_domain"
                placeholder="请输入完整域名，如：https://www.baidu.com"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="保单验证方式"
              prop="policy_verification_method"
            >
              <tinymce-editor
                id="isform"
                v-model="agencyForm.policy_verification_method"
              ></tinymce-editor>
            </el-form-item>
            <el-form-item label="客户服务及投诉渠道" prop="customer_service">
              <tinymce-editor
                id="complaint"
                v-model="agencyForm.customer_service"
              ></tinymce-editor>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input
                v-model="agencyForm.phone"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="status">
              <el-radio v-model="agencyForm.status" :label="0">不发布</el-radio>
              <el-radio v-model="agencyForm.status" :label="1">发布</el-radio>
              <el-radio v-model="agencyForm.status" :label="2">整理中</el-radio>
            </el-form-item>
          </el-form>
          <el-form
            :model="schemeForm"
            :rules="rules"
            :validate-on-rule-change="false"
            ref="schemeForm"
            label-width="140px"
            class="schemeForm"
            size="small"
            v-else-if="option == 'scheme'"
          >
            <div v-for="(item, index) in schemeForm.company" :key="index">
              <el-form-item
                label="合作产品"
                :prop="`company.${index}.pro_idArray`"
                :rules="rules.pro_idArray"
              >
                <el-select
                  v-model="item.pro_idArray"
                  multiple
                  filterable
                  placeholder="请选择"
                  style="width:100%"
                  @change="changePro_idArray"
                >
                  <el-option
                    v-for="(v, i) in proArray"
                    :label="v.product_name"
                    :value="v.id + ''"
                    :key="i"
                  >
                  </el-option>
                </el-select>
                <el-button
                  v-if="index == 0"
                  type="primary"
                  @click="addCompanyData"
                  style="padding:5px"
                >
                  添加
                </el-button>
                <el-button
                  v-else
                  type="danger"
                  style="padding:5px"
                  @click="deleteCompany(index)"
                >
                  删除
                </el-button>
              </el-form-item>
              <el-form-item
                label="备案名称"
                :prop="`company.${index}.title`"
                :rules="rules.title2"
              >
                <el-input
                  v-model="item.title"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="合作开始时间"
                :prop="`company.${index}.cooperation_start_time`"
                :rules="rules.cooperation_start_time"
              >
                <el-input
                  v-model="item.cooperation_start_time"
                  placeholder="请输入日期。如：2021-05-06"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="合作结束时间"
                :prop="`company.${index}.cooperation_end_time`"
                :rules="rules.cooperation_end_time"
              >
                <el-input
                  v-model="item.cooperation_end_time"
                  placeholder="请输入日期。如：2021-05-06"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="承保公司"
                :prop="`company.${index}.insurance_company`"
                :rules="rules.insurance_company"
              >
                <el-select
                  filterable
                  v-model="item.insurance_company"
                  placeholder="请选择"
                  style="width:100%"
                >
                  <el-option
                    v-for="(v, i) in insuranceCompanyArray"
                    :label="v.name"
                    :value="v.id"
                    :key="i"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="备案编号"
                :prop="`company.${index}.record_num`"
                :rules="rules.record_num"
              >
                <el-input
                  v-model="item.record_num"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="保险条款"
                :prop="`company.${index}.insurance_clauses`"
                :rules="rules.insurance_clauses"
              >
                <click-upload
                  v-model="item.insurance_clauses"
                  :multiple="true"
                  style="width: 100%;"
                ></click-upload>
              </el-form-item>
            </div>
          </el-form>
          <el-form
            :model="branchForm"
            :rules="rules"
            :validate-on-rule-change="false"
            ref="branchForm"
            label-width="140px"
            class="branchForm"
            size="small"
            v-else
          >
            <div v-for="(item, index) in branchForm.branch" :key="index">
              <el-form-item
                label="分支机构名称"
                :prop="`branch.${index}.title`"
                :rules="rules.branchTitle"
              >
                <el-input
                  v-model="item.title"
                  placeholder="请输入内容"
                ></el-input>
                <el-button
                  v-if="index == 0"
                  type="primary"
                  @click="addBranchData"
                  style="padding:5px"
                >
                  添加
                </el-button>
                <el-button
                  v-else
                  type="danger"
                  style="padding:5px"
                  @click="deleteBranch(index)"
                >
                  删除
                </el-button>
              </el-form-item>
              <el-form-item
                label="查询页面"
                :prop="`branch.${index}.domain`"
                :rules="rules.branchDomain"
              >
                <el-input
                  v-model="item.domain"
                  placeholder="请输入完整域名，如：https://www.baidu.com"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </el-scrollbar>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            size="small"
            @click="dialogOk(option + 'Form')"
          >
            确 定
          </el-button>
          <el-button @click="dialogClose()" size="small">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import searchForm from "@/components/common/searchForm";
import clickUpload from "@/components/common/clickUpload";
import tinymceEditor from "@/components/common/tinymceEditor";
import {
  changeAgent,
  getAgentList,
  getAgentInfo,
  changeAgentBranch,
  getAgentBranch,
  changeAgentCompany,
  getAgentCompany
} from "@/api/icAdmin";
import { getCompany, getSelectProductList } from "@/api/public";
export default {
  name: "agencyCo",
  components: {
    searchForm,
    clickUpload,
    tinymceEditor
  },
  data() {
    return {
      searcharray: [
        {
          label: "公司名称",
          val: "title",
          type: "input",
          pla: "请输入公司名称"
        },
        {
          label: "法人代表",
          val: "legal_person",
          type: "input",
          pla: "请输入法人代表"
        },
        {
          label: "信息状态",
          val: "status",
          type: "select",
          pla: "请选择信息状态",
          option: [
            { label: "不发布 ", val: "0" },
            { label: "发布 ", val: "1" },
            { label: "整理中 ", val: "2" }
          ]
        }
      ],
      // 搜索数据
      searchVal: {},
      // 表格数据
      tableData: [],
      //表格loading
      loading: false,
      // 分页 每页条数
      pageSize: 15,
      // 分页 每页条数可选项
      pageSizeArray: [15, 30, 45, 60],
      // 当前页
      page: 1,
      // 总页数
      total: 0,
      popTitle: "添加",
      dialogState: false,
      option: "agency",
      // 产品列表
      proArray: [],
      // 保司
      insuranceCompanyArray: [],
      agencyForm: {
        id: "",
        title: "",
        deduction: "",
        capital: "",
        establishment: "",
        legal_person: "",
        credit_code: "",
        company_address: "",
        business_nature: "",
        business_license: [],
        business_permit: [],
        agent_img: [],
        agent_domain: "",
        policy_verification_method: "",
        customer_service: "",
        status: 2,
        phone: ""
      },
      schemeForm: {
        agent_id: "",
        company: [
          {
            pro_idArray: [],
            pro_id: "",
            title: "",
            cooperation_start_time: "",
            cooperation_end_time: "",
            insurance_company: "",
            record_num: "",
            insurance_clauses: []
          }
        ]
      },
      branchForm: {
        agent_id: "",
        branch: [{ title: "", domain: "" }]
      },
      rules: {
        title: [
          { required: true, message: "公司名称不能为空", trigger: "blur" }
        ],
        deduction: [
          { required: true, message: "经代扣费不能为空", trigger: "blur" },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: "经代扣费必须为数字值",
            trigger: "blur"
          },
          {
            validator: function(rule, value, callback) {
              if (value > 100) {
                callback(new Error("请输入0-100范围数字"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ],
        capital: [
          { required: true, message: "注册资本不能为空", trigger: "blur" }
        ],
        establishment: [
          {
            required: true,
            message: "成立时间不能为空",
            trigger: "blur"
          }
        ],
        legal_person: [
          { required: true, message: "法人代表不能为空", trigger: "blur" }
        ],
        credit_code: [
          { required: true, message: "信用编码不能为空", trigger: "blur" }
        ],
        company_address: [
          { required: true, message: "经代/保司地址不能为空", trigger: "blur" }
        ],
        business_nature: [
          { required: true, message: "经营范围不能为空", trigger: "blur" }
        ],
        business_license: [
          {
            required: true,
            message: "营业执照不能为空",
            trigger: ["change", "blur"]
          }
        ],
        business_permit: [
          {
            required: true,
            message: "业务许可证不能为空",
            trigger: ["change", "blur"]
          }
        ],
        agent_img: [
          {
            required: true,
            message: "经代/保司图片不能为空",
            trigger: ["change", "blur"]
          }
        ],
        agent_domain: [
          { required: true, message: "经代/保司域名不能为空", trigger: "blur" },
          {
            pattern: /(http|https):\/\/([\w.]+\/?)\S*/,
            message: "经代/保司域名必须是http://,或https://开头",
            trigger: "blur"
          }
        ],
        policy_verification_method: [
          {
            required: true,
            message: "保单验证方式不能为空",
            trigger: ["change", "blur"]
          }
        ],
        customer_service: [
          {
            required: true,
            message: "客户服务及投诉渠道不能为空",

            trigger: ["change", "blur"]
          }
        ],
        phone: [{ required: true, message: "电话不能为空", trigger: "blur" }],
        branchTitle: [
          { required: true, message: "分支机构名称不能为空", trigger: "blur" }
        ],
        branchDomain: [
          { required: true, message: "查询页面不能为空", trigger: "blur" },
          {
            pattern: /(http|https):\/\/([\w.]+\/?)\S*/,
            message: "查询页面必须是http://,或https://开头",
            trigger: "blur"
          }
        ],
        pro_idArray: [
          { required: true, message: "合作产品不能为空", trigger: "blur" }
        ],
        title2: [
          { required: true, message: "备案名称不能为空", trigger: "blur" }
        ],
        cooperation_start_time: [
          { required: true, message: "合作开始时间不能为空", trigger: "blur" }
        ],
        cooperation_end_time: [
          { required: true, message: "合作结束时间不能为空", trigger: "blur" }
        ],
        insurance_company: [
          { required: true, message: "承保公司不能为空", trigger: "blur" }
        ],
        record_num: [
          { required: true, message: "备案文号不能为空", trigger: "blur" }
        ],
        insurance_clauses: [
          {
            required: true,
            message: "保险条款不能为空",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  mounted() {
    this.getAgentList();
  },
  methods: {
    // 搜索数据
    search(e) {
      this.page = 1;
      this.searchVal = e;
      this.getAgentList();
    },
    //搜索重置
    reset(e) {
      this.page = 1;
      this.searchVal = {};
      this.getAgentList();
    },
    add() {
      this.agencyForm = {
        id: "",
        title: "",
        deduction: "",
        capital: "",
        establishment: "",
        legal_person: "",
        credit_code: "",
        company_address: "",
        business_nature: "",
        business_license: [],
        business_permit: [],
        agent_img: [],
        agent_domain: "",
        policy_verification_method: "",
        customer_service: "",
        status: 2,
        phone: ""
      };
      this.popTitle = "添加";
      this.option = "agency";
      this.dialogState = true;
    },
    handleEdit(e) {
      console.log(e);
      let that = this;
      let _data = {
        id: e
      };
      getAgentInfo(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.popTitle = "编辑";
            that.option = "agency";
            that.agencyForm = res.data;
            that.dialogState = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 添加合作方案
    addScheme(e) {
      let that = this;
      that.getCompany();
      that.getSelectProductList();
      getAgentCompany({ agent_id: e })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            if (res.data.length > 0) {
              that.schemeForm.company = res.data;
              that.schemeForm.company.forEach(element => {
                that.$set(element, "pro_idArray", element.pro_id.split(","));
              });
            } else {
              that.schemeForm.agent_id = e;
              that.schemeForm.company = [
                {
                  pro_idArray: [],
                  pro_id: "",
                  title: "",
                  cooperation_start_time: "",
                  cooperation_end_time: "",
                  insurance_company: "",
                  record_num: "",
                  insurance_clauses: []
                }
              ];
            }
            that.schemeForm.agent_id = e;
            that.popTitle = "添加合作方案";
            that.option = "scheme";
            that.dialogState = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });

      console.log(e);
    },
    // 添加分支机构
    addBranch(e) {
      this.branchForm.agent_id = e;
      let that = this;
      getAgentBranch({ agent_id: e })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            if (res.data.length > 0) {
              that.branchForm.branch = res.data;
            } else {
              that.branchForm.agent_id = e;
              that.branchForm.branch = [{ title: "", domain: "" }];
            }
            that.popTitle = "添加分支机构";
            that.option = "branch";
            that.dialogState = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 弹窗点击确定
    dialogOk(e) {
      let that = this;
      console.log(e);
      that.$refs[e].validate(valid => {
        if (valid) {
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          if (e == "agencyForm") {
            changeAgent(that.agencyForm)
              .then(function(res) {
                console.log(res);
                loadind.close();
                if (res.code == 1) {
                  that.$message.success(res.msg);
                  that.getAgentList();
                  that.dialogClose();
                } else {
                  that.$message.error(res.msg);
                }
              })
              .catch(function(error) {
                console.log(error);
              });
          } else if (e == "branchForm") {
            changeAgentBranch(that.branchForm)
              .then(function(res) {
                console.log(res);
                loadind.close();
                if (res.code == 1) {
                  that.$message.success(res.msg);
                  that.getAgentList();
                  that.dialogClose();
                } else {
                  that.$message.error(res.msg);
                }
              })
              .catch(function(error) {
                console.log(error);
              });
          } else {
            // let _data=that.schemeForm
            changeAgentCompany(that.schemeForm)
              .then(function(res) {
                console.log(res);
                loadind.close();
                if (res.code == 1) {
                  that.$message.success(res.msg);
                  that.getAgentList();
                  that.dialogClose();
                } else {
                  that.$message.error(res.msg);
                }
              })
              .catch(function(error) {
                console.log(error);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //弹窗关闭取消
    dialogClose(e) {
      this.$nextTick(() => {
        this.$refs[this.option + "Form"].resetFields();
      });
      this.dialogState = false;
    },
    // 添加合作方案
    addCompanyData() {
      this.schemeForm.company.push({
        pro_id: "",
        title: "",
        cooperation_start_time: "",
        cooperation_end_time: "",
        insurance_company: "",
        record_num: "",
        insurance_clauses: []
      });
    },
    // 删除合作方案
    deleteCompany(e) {
      this.schemeForm.company.splice(e, 1);
    },
    // 添加分支
    addBranchData() {
      this.branchForm.branch.push({ title: "", domain: "" });
    },
    // 删除分支
    deleteBranch(e) {
      this.branchForm.branch.splice(e, 1);
    },
    //获取列表
    getAgentList() {
      let that = this;
      let _data = this.searchVal;
      _data.page = this.page;
      _data.limit = this.pageSize;
      that.loading = true;
      getAgentList(_data)
        .then(function(res) {
          console.log(res);
          that.loading = false;
          if (res.code == 1) {
            that.tableData = res.data.list;
            that.total = res.data.total;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          that.$message.error(error.msg);
          console.log(error);
        });
    },
    // 每页几条
    sizeChange() {
      this.getAgentList();
    },
    // 页码变化
    currentChange() {
      this.getAgentList();
    },
    // 获取保司列表
    getCompany() {
      let that = this;
      getCompany()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.insuranceCompanyArray = res.data;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取产品
    getSelectProductList() {
      let that = this;
      getSelectProductList()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.proArray = res.data;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 选中产品时触发
    changePro_idArray(e) {
      this.schemeForm.company.forEach(element => {
        element.pro_id = element.pro_idArray.join(",");
      });
    }
  }
};
</script>
<style lang="scss">
.agencyCo {
  background: #fff;
  .agencyPop {
    .el-scrollbar__wrap {
      height: calc(100vh - 250px);
      overflow-x: hidden;
      margin-bottom: 0 !important;
    }
    .agencyForm {
      width: calc(100% - 100px);
    }
    .branchForm,
    .schemeForm {
      width: calc(100% - 150px);
      & > div {
        padding-bottom: 20px;
      }
      .el-form-item__content {
        display: flex;
        justify-content: flex-start;
        & > button {
          margin-left: 20px;
          position: absolute;
          right: -60px;
          top: 5px;
        }
      }
    }
  }
}
</style>
