<template>
  <div class="signatureAudit" v-if="routeClass == 2">
    <search-form
      :searcharray="searcharray"
      :span="8"
      labelWidth="140px"
      @change="search"
      @reset="reset"
    ></search-form>
    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%;margin-top:15px"
      row-key="id"
      border
      stripe
      lazy
      size="small"
      class="teamTtable"
    >
      <el-table-column prop="com_name" align="center" label="企业名称">
      </el-table-column>
      <el-table-column
        prop="credit_code"
        align="center"
        label="统一社会信用代码"
      >
      </el-table-column>
      <el-table-column prop="legal_person_name" align="center" label="法人姓名">
      </el-table-column>
      <el-table-column prop="created_at" align="center" label="创建时间">
      </el-table-column>
      <el-table-column prop="is_certified" align="center" label="审核状态">
        <template slot-scope="scope">
          <span>{{ status[scope.row.is_certified] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="desc" align="center" label="备注">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="look(scope.row.id, scope.row.credit_code, 1)"
            v-if="scope.row.is_certified == 6"
          >
            审核
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="look(scope.row.id, scope.row.credit_code, 2)"
            v-else
          >
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :page-sizes="pageSizeArray"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
  <router-view v-else />
</template>
<script>
import searchForm from "@/components/common/searchForm";
import { getAuthorizationAuditList } from "@/api/audit";
export default {
  name: "policy_audit",
  data() {
    return {
      routeClass: "",
      searcharray: [
        {
          label: "企业名称",
          val: "comName",
          type: "input",
          pla: "请输入投保人名称"
        },
        {
          label: "法人名称",
          val: "legalPersonName",
          type: "input",
          pla: "请输入被保人名称"
        },
        {
          label: "状态",
          val: "status",
          type: "select",
          pla: "请选择保单状态",
          option: [
            { label: "未认证", val: 0 },
            { label: "已认证", val: 1 },
            { label: "认证中", val: 2 },
            { label: "驳回", val: 3 },
            { label: "审核通过", val: 5 },
            { label: "审核中", val: 6 }
          ]
        },
        {
          label: "统一社会信用代码",
          val: "creditCode",
          type: "input",
          pla: "请输入统一社会信用代码"
        },
        {
          label: "创建时间",
          val: "cTime",
          type: "date"
        }
      ],
      status: {
        0: "未认证",
        1: "已认证",
        2: "认证中",
        3: "驳回",
        5: "审核通过",
        6: "审核中"
      },
      searchData: {},
      loading: false,
      tableData: [],
      // 分页 每页条数
      pageSize: 15,
      // 分页 每页条数可选项
      pageSizeArray: [15, 30, 45, 60],
      // 当前页
      page: 1,
      // 总页数
      total: 0
    };
  },
  components: {
    searchForm
  },
  mounted() {
    // 获取当前路由等级
    this.routeClass = this.$route.matched.length;
    // 获取列表
    this.getAuthorizationAuditList();
  },
  methods: {
    // 搜索数据
    search(e) {
      this.page = 1;
      this.searchData = e;
      this.getAuthorizationAuditList();
    },
    //搜索重置
    reset(e) {
      this.page = 1;
      this.searchData = {};
      this.getAuthorizationAuditList();
    },
    // 获取列表
    getAuthorizationAuditList() {
      let _data = this.searchData;
      let that = this;
      _data.page = this.page;
      _data.limit = this.pageSize;
      _data.identification = 2;
      that.loading = true;
      getAuthorizationAuditList(_data)
        .then(function(res) {
          console.log(res);
          that.loading = false;
          if (res.code == 200) {
            that.tableData = res.data.list;
            that.total = res.data.total;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 每页几条
    sizeChange() {
      this.getAuthorizationAuditList();
    },
    // 页码变化
    currentChange() {
      this.getAuthorizationAuditList();
    },
    look(id, regCode, op) {
      // 审核1 查看2
      this.$router.push({
        path: "/audit/signature/details",
        query: { id: id, regCode: regCode, op: op }
      });
    }
  }
};
</script>
<style lang="scss">
.signatureAudit {
  background: #fff;
}
</style>
