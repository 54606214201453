<template>
  <div class="based">
    <el-scrollbar>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="基础配置" name="basic">
          <custom-form
            :dataArray="dataArray[1]"
            v-model="basedForm"
            :verify.sync="isBasedForm"
            ref="basedForm"
          ></custom-form>
          <!-- <el-form label-width="150px">
            <el-form-item label="特殊配置">
              <el-radio v-model="isSpecial" :label="0">不启用 </el-radio>
              <el-radio v-model="isSpecial" :label="1">启用 </el-radio>
            </el-form-item>
          </el-form> -->
        </el-tab-pane>
        <el-tab-pane label="产品配置" name="product">
          <custom-form
            :dataArray="dataArray[2]"
            v-model="proForm"
            :verify.sync="isProForm"
            ref="proForm"
          ></custom-form>
        </el-tab-pane>
        <el-tab-pane label="文件配置" name="file">
          <custom-form
            :dataArray="dataArray[3]"
            v-model="fileForm"
            :verify.sync="isFileForm"
            ref="fileForm"
          ></custom-form>
        </el-tab-pane>
        <el-tab-pane label="特殊配置" name="special" v-if="isSpecial == 1">
          <el-form label-width="150px">
            <el-form-item label="选择特殊配置">
              <el-select
                v-model="addState"
                placeholder="请选择"
                size="small"
                filterable
                style="width:100%"
              >
                <el-option
                  v-for="(item, index) in specData"
                  :label="item.title"
                  :value="item.id"
                  :key="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <custom-form
            :dataArray="specArray"
            v-model="specForm"
            :verify.sync="isSpecForm"
            ref="specForm"
          ></custom-form>
        </el-tab-pane>
      </el-tabs>
    </el-scrollbar>
    <div class="btnBox">
      <el-button type="primary" size="small" @click="confirm()">确定</el-button>
      <el-button size="small" @click="cancel()">取消</el-button>
    </div>
  </div>
</template>
<script>
import customForm from "@/components/common/customForm";
import {
  getProductBase,
  changeProduct,
  getProductAttr
} from "@/api/productAdmin";
export default {
  name: "based",
  components: {
    customForm
  },
  props: {
    id: {
      type: [Number, String],
      default: ""
    },
    status: {
      type: Boolean,
      required: true
    },
    identi: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      activeName: "basic",
      isSpecial: 0, //是否启用特殊配置
      dataArray: {},
      basedForm: {},
      isBasedForm: false,
      proForm: {},
      isProForm: false,
      fileForm: {},
      isFileForm: false,

      specData: [], //特殊配置列表
      addState: "", //选择的特殊配置id
      specForm: {},
      isSpecForm: false,
      specArray: [], //特殊选项配置项
      specRules: {
        addState: [
          { required: true, message: "请选择特殊配置", trigger: "blur" }
        ]
      }
    };
  },
  mounted() {
    this.getProductBase();
    this.getProductAttr(); //获取特殊配置
  },
  methods: {
    // 获取页面数据
    getProductBase() {
      let that = this;
      let _data = {
        identi: this.identi,
        id: this.id
      };
      getProductBase(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.dataArray = res.data.proInfo;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 点击确认
    confirm(e) {
      let that = this;
      let _data = {
        identi: this.identi,
        id: this.id
      };
      console.log(_data);
      that.$refs.basedForm.submit();
      console.log(that.basedForm);
      console.log(this.isBasedForm);
      if (!that.isBasedForm) {
        that.activeName = "basic";
        that.$nextTick(() => {
          document.querySelector(".is-error").scrollIntoView();
        });
        return;
      }
      that.$refs.proForm.submit();
      if (!that.isProForm) {
        that.activeName = "product";
        that.$nextTick(() => {
          document.querySelector(".is-error").scrollIntoView();
        });
        return;
      }
      that.$refs.fileForm.submit();
      if (!that.isFileForm) {
        that.activeName = "file";
        that.$nextTick(() => {
          document.querySelector(".is-error").scrollIntoView();
        });
        return;
      }
      if (that.isSpecial == 1) {
        that.$refs.specForm.submit();
        if (!that.isSpecForm) {
          that.activeName = "special";
          that.$nextTick(() => {
            document.querySelector(".is-error").scrollIntoView();
          });
          return;
        }
      }
      Object.assign(_data, that.basedForm, that.proForm, that.fileForm);
      if (that.isSpecial == 1) {
        //添加特殊项
        Object.assign(_data, that.specForm);
      }
      let loadind = that.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      changeProduct(_data)
        .then(function(res) {
          console.log(res);
          loadind.close();
          if (res.code == 1) {
            that.$message.success(res.msg);
            that.cancel();
            that.$emit("upStatus", "");
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
      //
    },
    // 取消
    cancel() {
      this.$refs.basedForm.resetForm();
      this.$refs.proForm.resetForm();
      this.$refs.fileForm.resetForm();
      this.$emit("update:status", false);
    },
    getProductAttr() {
      let that = this;
      getProductAttr()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.specData = res.data;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  },
  watch: {
    addState(e) {
      //监听特殊选项id变化
      console.log(e);
    }
  }
};
</script>
<style lang="scss">
.based {
  .el-scrollbar__wrap {
    height: calc(100vh - 201px);
    overflow-x: hidden;
    margin-bottom: 0 !important;
  }
  .el-tabs {
    width: 100%;
    .el-form {
      width: calc(100% - 100px);
    }
  }
  .btnBox {
    text-align: center;
    padding: 10px 25px;
    background: #efefef;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -30px;
    .el-button {
      width: 80px;
    }
    .el-button + .el-button {
      margin-left: 25px;
    }
  }
}
</style>
