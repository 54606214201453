<template>
  <div class="postManagement">
    <div class="flexbox">
      <div class="left">
        <el-button
          v-if="rulesTag.includes('gwAdd')"
          size="small"
          type="primary"
          @click="postBtn"
        >
          添加岗位
        </el-button>
        <el-tree
          style="margin-top:20px"
          :data="postList"
          @node-click="handleNodeClick"
          :current-node-key="optPost"
          accordion
          highlight-current
          :props="{ children: 'child', label: 'name' }"
          node-key="id"
          ref="postList"
        >
          <div slot-scope="{ node, data }" class="conBox">
            <span>{{ node.label }}</span>
            <el-button
              v-if="rulesTag.includes('gwEditName')"
              type="text"
              icon="el-icon-edit"
              class="editBtn"
              @click.stop="edit(data.id, node)"
            ></el-button>
          </div>
        </el-tree>
      </div>
      <div class="right">
        <el-tabs v-model="activeName" @tab-click="tabClick">
          <el-tab-pane label="岗位员工" name="staff">
            <el-button
              type="primary"
              size="mini"
              class="releBtn"
              @click="releBtn"
              >关联员工</el-button
            >
            <el-table
              :data="tableData"
              stripe
              style="width: 100%"
              size="small"
              v-loading="loading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
            >
              <el-table-column prop="id" label="ID" align="center">
              </el-table-column>
              <el-table-column prop="username" label="账号" align="center">
              </el-table-column>
              <el-table-column prop="nickname" label="姓名" align="center">
              </el-table-column>
              <el-table-column prop="phone" label="手机号" align="center">
              </el-table-column>
              <el-table-column prop="teamTitle" label="团队" align="center">
              </el-table-column>
              <el-table-column prop="parent" label="上级" align="center">
              </el-table-column>
              <el-table-column prop="status" align="center" label="状态">
                <template slot-scope="scope">
                  <el-tag
                    type="success"
                    size="mini"
                    v-if="scope.row.status == 1"
                  >
                    正常
                  </el-tag>
                  <el-tag type="danger" size="mini" v-else>禁用</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click="handleDelete(scope.row.id)"
                    style="color:#f56c6c"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @size-change="sizeChange"
              @current-change="currentChange"
              :page-sizes="pageSizeArray"
              :page-size.sync="pageSize"
              :current-page.sync="page"
              :total="total"
              background
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </el-tab-pane>
          <el-tab-pane label="岗位权限" name="authority">
            <el-button-group>
              <el-button
                size="mini"
                :disabled="!rulesTag.includes('gwEditAuth')"
                @click="unfoCheckChange('check')"
                :class="isCheck ? 'activate' : ''"
              >
                选中全部
              </el-button>
              <el-button
                size="mini"
                @click="unfoCheckChange('unfo')"
                :class="isUnfo ? 'activate' : ''"
              >
                展开全部
              </el-button>
            </el-button-group>
            <el-tree
              :data="authList"
              @check="authCheck"
              default-expand-all
              node-key="id"
              ref="selectTree"
              show-checkbox
              :class="rulesTag.includes('gwEditAuth') ? '' : 'is-disabled'"
              :props="{ children: 'child', label: 'title' }"
            >
              <div
                slot-scope="{ node, data }"
                :class="!data.child && node.parent.parent ? 'nodeInline' : ''"
              >
                <span>{{ node.label }}</span>
              </div>
            </el-tree>
            <el-button
              v-if="rulesTag.includes('gwEditAuth')"
              size="mini"
              type="primary"
              style="margin:20px 20px 0"
              @click="editAuth"
            >
              修改
            </el-button>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!--添加岗位弹窗 -->
    <el-dialog
      :title="postTitle"
      :visible="postVisible"
      @close="closePostBtn"
      width="440px"
    >
      <el-form
        :model="postForm"
        :rules="rules"
        :validate-on-rule-change="false"
        ref="postForm"
        label-width="100px"
        class="postForm"
        size="small"
      >
        <el-form-item label="父级" prop="pid">
          <el-select
            filterable
            v-model="postForm.pid"
            placeholder="请选择"
            style="width:200px;"
            popper-class="blankLevel"
          >
            <template v-for="item in f_postList">
              <el-option :label="item.name" :value="item.id" :key="item.id">
              </el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input
            v-model="postForm.name"
            placeholder="请输入内容"
            style="width:200px;"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio v-model="postForm.status" label="1">启用</el-radio>
          <el-radio v-model="postForm.status" label="0">禁用</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="postOk" size="small">
          确 定
        </el-button>
        <el-button @click="closePostBtn" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 关联员工 -->
    <el-dialog
      title="关联员工"
      :visible="releVisible"
      @close="closeBtn"
      width="600px"
    >
      <search-form
        :searcharray="searcharray"
        :span="12"
        @change="search"
        @reset="reset"
        class="releSearch"
        ref="searchForm"
      ></search-form>
      <el-table
        :data="releData"
        style="width: 100%"
        row-key="id"
        stripe
        size="small"
        height="450px"
        ref="releData"
        @selection-change="selectTable"
        ><el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="username" label="账号"> </el-table-column>
        <el-table-column prop="nickname" label="姓名"> </el-table-column>
        <el-table-column prop="teamTitle" label="团队"> </el-table-column>
        <el-table-column prop="phone" label="手机号"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="releOk" size="small">
          确 定
        </el-button>
        <el-button @click="closeBtn" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import searchForm from "@/components/common/searchForm";
import {
  setAuthGroup,
  getAuthList,
  getauthinfo,
  getAuthMenu,
  getAuthRules,
  setAuthRules,
  getAdmin,
  getAdminList,
  setGroupAuth,
  uSetAuth
} from "@/api/system";
import recursionChildData from "@/utils/recursionChildData";
export default {
  name: "postManagement",
  data() {
    return {
      // 当前账号可操作权限标识
      rulesTag: this.$store.state.rulesTag,
      //岗位列表
      postList: [],
      // 当前选中岗位
      optPost: "1",
      // 只可用状态岗位列表
      f_postList: [],
      // 权限菜单列表
      authList: [],
      // 当前选中权限
      optauth: "",
      // 所有权限id
      allAuth: [],
      activeName: "staff",
      tableData: [],
      // 员工列表loading
      loading: false,
      // 分页 每页条数
      pageSize: 15,
      // 分页 每页条数可选项
      pageSizeArray: [15, 30, 45, 60],
      // 当前页
      page: 1,
      // 总页数
      total: 0,
      postVisible: false, //岗位弹窗
      postTitle: "添加",
      postForm: {
        id: "",
        pid: "",
        name: "",
        status: "1"
      },
      rules: {
        pid: [{ required: true, message: "父级不能为空", trigger: "blur" }],
        name: [
          { required: true, message: "名称不能为空", trigger: "blur" },
          { message: "名称最多输入10个字符", min: 0, max: 10, trigger: "blur" }
        ]
      },
      releVisible: false, //关联员工弹窗
      releData: [], //关联员工
      selectTableData: "",
      searcharray: [
        //关联员工搜索
        {
          label: "",
          val: "keywords",
          type: "input",
          pla: "请输入账号、姓名、手机号"
        }
      ],
      isCheck: false,
      isUnfo: true
    };
  },
  components: {
    searchForm
  },
  mounted() {
    this.getAuthList(); // 获取岗位列表
  },
  methods: {
    // 获取岗位列表
    getAuthList(status) {
      let that = this;
      let _data = {};
      if (status) {
        _data.status = status;
      }
      getAuthList(_data)
        .then(function(res) {
          if (res.code == 1) {
            if (!status) {
              that.postList = res.data;
              that.$nextTick(() => {
                that.optPost = that.postList[0].id;
                that.$refs.postList.setCurrentKey(that.optPost);
              });
            } else {
              //递归处理
              that.f_postList = recursionChildData(res.data, "child");
            }
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 点击岗位切换
    handleNodeClick(e) {
      let that = this;
      that.optPost = e.id;
      that.activeName = "staff";
      that.page = 1;
      that.tabClick({ name: that.activeName });
    },
    // 打开岗位弹窗
    postBtn() {
      this.postTitle = "添加";
      this.postForm = {
        id: "",
        pid: "",
        name: "",
        status: "1"
      };
      this.postVisible = true;
      this.getAuthList(1); //获取可用岗位列表
    },
    // 编辑打开岗位弹窗
    edit(id, node) {
      let that = this;
      let _data = { id: id };
      that.getAuthList(1); //获取可用岗位列表
      getauthinfo(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.postTitle = "编辑";
            that.postForm = res.data;
            that.postVisible = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    //关闭岗位弹窗
    closePostBtn() {
      this.$nextTick(() => {
        this.$refs.postForm.resetFields();
      });
      this.postVisible = false;
    },
    // 岗位弹窗确定
    postOk() {
      let that = this;
      that.$refs.postForm.validate(valid => {
        let that = this;
        if (valid) {
          let loadind = that.$loading({
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          setAuthGroup(that.postForm)
            .then(function(res) {
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.getAuthList(); //重新获取岗位列表更新
                that.closePostBtn();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 标签页切换
    tabClick(e) {
      if (e.name == "authority") {
        this.getAuthMenu(); //获取权限组菜单
        this.getAuthRules(); //获取岗位对应权限
      }
    },
    // 获取账户列表
    getAdminList() {
      let _data = { group: this.optPost };
      let that = this;
      _data.page = that.page;
      _data.limit = that.pageSize;
      that.loading = true;
      getAdminList(_data)
        .then(function(res) {
          console.log(res);
          that.loading = false;
          if (res.code == 1) {
            that.tableData = res.data.list;
            that.total = res.data.total;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 岗位员工列表删除
    handleDelete(e) {
      console.log(e);
      let that = this;
      let _data = {
        group: that.optPost,
        uid: e
      };
      that
        .$confirm("此操作将修改当前岗位权限, 是否继续?", "提示", {
          type: "warning"
        })
        .then(() => {
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          uSetAuth(_data)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.getAdminList();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    // 每页几条
    sizeChange() {
      this.getAdminList();
    },
    // 页码变化
    currentChange() {
      this.getAdminList();
    },
    // 获取权限组菜单
    getAuthMenu() {
      let that = this;
      getAuthMenu()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.authList = res.data;
            that.allAuth = that.dgFor(res.data);
            that.$nextTick().then(function() {
              that.changeStyle();
            });
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    //获取岗位对应权限
    getAuthRules() {
      console.log(this.optPost);
      let that = this;
      let _data = {
        gid: that.optPost
      };
      getAuthRules(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.optauth = res.data.rules;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.lo(error);
        });
    },
    //打开关联员工弹窗
    releBtn() {
      this.getReleData();
      this.releVisible = true;
    },
    //关闭关联员工弹窗
    closeBtn() {
      this.$refs.searchForm.resetForm();
      this.$refs.releData.clearSelection();
      this.releVisible = false;
    },
    // 关联员工搜索
    search(e) {
      this.getReleData(e);
    },
    // 关联员工重置
    reset() {
      this.getReleData();
    },
    // 勾选
    selectTable(e) {
      console.log(e);
      let _data = [];
      e.forEach(item => {
        _data.push(item.id);
      });
      this.selectTableData = _data.join(",");
    },
    // 关联员工确认
    releOk() {
      let that = this;
      let _data = {
        group: that.optPost,
        uid: that.selectTableData
      };
      if (that.selectTableData == "") {
        that.$message.error("请选择要关联的账号");
        return;
      }
      setGroupAuth(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.getAdminList();
            that.closeBtn();
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    //获取当前登录账号下可用账号列表
    getReleData(data) {
      let that = this;
      getAdmin(data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.releData = res.data;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    //编辑岗位权限
    editAuth() {
      let that = this;
      let _data = {
        gid: that.optPost,
        rules: that.optauth
      };

      that
        .$confirm("此操作将修改当前岗位权限, 是否继续?", "提示", {
          type: "warning"
        })
        .then(() => {
          let loadind = that.$loading({
            // lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          setAuthRules(_data)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.getAuthRules(); //重新获取岗位权限
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    // 修改岗位权限树排列
    changeStyle() {
      var leafNode = document.getElementsByClassName("nodeInline");
      for (var i = 0; i < leafNode.length; i++) {
        leafNode[i].parentNode.parentNode.style.cssFloat = "left";
      }
    },
    //岗位权限全选&展开按钮组
    unfoCheckChange(e) {
      if (e == "unfo") {
        if (this.isUnfo) {
          this.isUnfo = false;
        } else {
          this.isUnfo = true;
        }
        this.unfoldAllChange(this.isUnfo);
      } else {
        if (this.isCheck) {
          this.isCheck = false;
        } else {
          this.isCheck = true;
        }
        this.checkAllChange(this.isCheck);
      }
    },
    // 岗位权限选择权限
    authCheck(data, node) {
      this.optauth = node.checkedKeys.join(",");
      this.isAllCheck();
    },
    // 选中全部
    checkAllChange(val) {
      if (val) {
        this.$refs.selectTree.setCheckedKeys(this.allAuth);
        this.optauth = this.allAuth.join(",");
      } else {
        this.$refs.selectTree.setCheckedKeys([]);
        this.optauth = "";
      }
    },
    // 展开收起
    unfoldAllChange(val) {
      for (
        var i = 0;
        i < this.$refs.selectTree.store._getAllNodes().length;
        i++
      ) {
        this.$refs.selectTree.store._getAllNodes()[i].expanded = val;
      }
    },
    //递归权限id
    dgFor(list) {
      var _arr = [];
      let fun = function(list) {
        for (let index = 0; index < list.length; index++) {
          _arr.push(list[index].id);
          if (list[index].child) {
            fun(list[index].child);
          }
        }
      };
      fun(list);
      return _arr;
    },
    //判断是否是全选状态
    isAllCheck() {
      if (this.allAuth.length == this.optauth.split(",").length) {
        if (!this.isCheck) {
          this.isCheck = true;
        }
      } else {
        this.isCheck = false;
      }
    }
  },
  watch: {
    allAuth: {
      handler(e) {
        this.isAllCheck();
      },
      deep: true
    },
    optauth(e) {
      this.$refs.selectTree.setCheckedKeys(e.split(","));
      this.isAllCheck();
    },
    optPost() {
      this.getAdminList();
    }
  }
};
</script>
<style lang="scss">
.postManagement {
  padding: 30px;
  height: 100%;
  .flexbox {
    display: flex;
    justify-content: flex-start;
    & > .left {
      width: 300px;
      padding: 20px;
      background: #fff;
      cursor: pointer;
      .el-tree .el-tree-node__content {
        height: 40px;
        .conBox {
          height: 100%;
          width: calc(100% - 24px);
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 5px;
          & > span {
            width: calc(100% - 30px);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .editBtn {
            opacity: 0;
            padding: 7px;
            transition: 0.3s;
          }
        }
        .conBox:hover .editBtn {
          opacity: 1;
        }
      }
    }
    & > .right {
      flex: 1;
      background: #fff;
      margin-left: 30px;
      padding: 20px;
      .el-tabs .el-tabs__item {
        font-size: 16px;
      }
      .el-tabs .el-tabs__content {
        .releBtn {
          margin: 15px 0;
        }
        .el-button-group .el-button.activate {
          color: #fff;
          background-color: #409eff;
          border-color: #409eff;
        }
        .el-tree {
          margin-top: 25px;
          .el-tree-node__content:hover,
          .el-tree-node:focus .el-tree-node__content {
            background: transparent;
          }
          .el-tree-node__content {
            height: 30px;
            width: 100%;
            .nodeInline {
              padding-right: 10px;
            }
          }
        }
        .el-tree.is-disabled {
          .el-checkbox {
            pointer-events: none;
          }
        }
      }
    }
  }
  // 弹窗
  .postForm {
    overflow: auto;
    .el-form-item {
      margin-bottom: 15px;
    }
  }
}
</style>
