import Vue from "vue";
import Cookies from "js-cookie";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () => import("@views/login/index"),
    hidden: true
  },
  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true
  },
  {
    path: "/",
    component: () => import("@/layout"),
    redirect: "/",
    children: [
      {
        path: "/",
        name: "dashboard",
        component: () => import("@/views/dashboard.vue"),
        meta: { title: "首页", icon: "el-icon-s-home" }
      }
    ]
  }
  // {
  //   path: "/home",
  //   component: Layout,
  //   redirect: "/home/home",
  //   meta: { title: "第二个", icon: "icon-iconfonticon-jianko", alert: "5" },
  //   children: [
  //     {
  //       path: "home",
  //       name: "home",
  //       component: () => import("@/views/Home.vue"),
  //       meta: { title: "第二home", icon: "icon-iconfonticon-jianko" }
  //     },
  //     {
  //       path: "ddd",
  //       name: "dddd",
  //       component: () => import("@/views/dddd.vue"),
  //       meta: {
  //         title: "第二dddd",
  //         icon: "icon-iconfonticon-shebei",
  //         alert: "666"
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: "/e",
  //   component: Layout,
  //   redirect: "/e/e",
  //   children: [
  //     {
  //       path: "e",
  //       name: "E",
  //       component: () => import("@/views/eee.vue"),
  //       meta: { title: "第三", icon: "icon-football_name" }
  //     }
  //   ]
  // },
  // {
  //   path: "/icon",
  //   component: Layout,
  //   redirect: "/icon/index",
  //   childre: [
  //     {
  //       path: "index",
  //       name: "icon",
  //       component: () => import("@/views/icon/index"),
  //       meta: {
  //         title: "图标库",
  //         icon: "icon-football_name"
  //       }
  //     }
  //   ]
  // },
  // { path: "*", redirect: "/404", hidden: true }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  if (Cookies.get("RL_ADMINTO_KENKEY")) {
    //判断本地是否存在RL_ADMINTO_KENKEY
    if (store.state.routerList) {
      next();
    } else {
      store.dispatch("getRouterlist").then(() => {
        // 如果直接使用 next() 刷新后会一直白屏
        // next({ ...to, replace: true });
        next();
      });
    }
    // 判断是否有请求权限
    if (store.state.isRules) {
      next();
    } else {
      store.dispatch("getAuthRules").then(() => {
        next();
      });
    }
  } else {
    if (to.path === "/login") {
      next();
    } else {
      next({ path: "/login" });
    }
  }
  /*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
  if (to.fullPath == "/login") {
    if (Cookies.get("RL_ADMINTO_KENKEY")) {
      next({ path: from.fullPath });
    } else {
      next();
    }
  }
  // 设置标题
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});
// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
export default router;
