<template>
  <div class="blackDistrict">
    <search-form
      :searcharray="searcharray"
      :span="6"
      @change="search"
      @reset="reset"
    ></search-form>
    <el-button type="primary" size="small" icon="el-icon-plus" @click="add()"
      >添加
    </el-button>
    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%;margin-top:15px"
      row-key="id"
      border
      stripe
      lazy
      size="small"
    >
      <el-table-column type="index" label="序号" align="center" width="80px">
      </el-table-column>
      <el-table-column prop="productName" align="center" label="产品">
      </el-table-column>
      <el-table-column align="center" label="拒保省份">
        <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.province" :key="index">
            <i v-if="index > 0">，</i>{{ item }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="拒保市区">
        <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.city" :key="index">
            <i v-if="index > 0">，</i>{{ item }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="拒保区县">
        <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.area" :key="index">
            <i v-if="index > 0">，</i>{{ item }}
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="state" align="center" label="状态">
        <template slot-scope="scope">
          <el-tag type="danger" size="mini" v-if="scope.row.status == 1">
            拒保
          </el-tag>
          <el-tag type="success" size="mini" v-else>不拒保</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="handleEdit(scope.row.productId)"
          >
            编辑
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="handleDele(scope.row.productId)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :page-sizes="pageSizeArray"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!--弹窗 -->
    <div>
      <el-dialog
        :title="popTitle"
        :visible.sync="dialogState"
        width="540px"
        @close="dialogClose"
      >
        <el-form
          :model="form"
          :rules="rules"
          :validate-on-rule-change="false"
          ref="form"
          label-width="130px"
          class="form"
          size="small"
        >
          <el-form-item label="地区库">
            <el-cascader
              :options="regionArray"
              :props="{
                multiple: true,
                children: 'child',
                label: 'name',
                value: 'id'
              }"
              filterable
              style="width:100%"
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.name }}</span>
                <el-button
                  v-if="!optArray.includes(data.id)"
                  type="text"
                  size="mini"
                  @click="addPCA(node.level, data.id, data.name)"
                >
                  加入
                </el-button>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item label="拒保省份" prop="province" :rules="rules.PCA">
            <el-tag
              size="mini"
              type="danger"
              closable
              v-for="(item, index) in form.province"
              :key="index"
              @close="delePCA(1, item.id)"
            >
              {{ item.name }}
            </el-tag>
            <p
              style="margin:0;padding: 0;font-size: 12px;color: #999;line-height: 1;position: absolute;bottom:-12px;"
            >
              点击地区库加入按钮设置拒保省份
            </p>
          </el-form-item>
          <el-form-item label="拒保市区" prop="city" :rules="rules.PCA">
            <el-tag
              size="mini"
              type="danger"
              closable
              v-for="(item, index) in form.city"
              :key="index"
              @close="delePCA(2, item.id)"
            >
              {{ item.name }}
            </el-tag>
            <p
              style="margin:0;padding: 0;font-size: 12px;color: #999;line-height: 1;position: absolute;bottom:-12px;"
            >
              点击地区库加入按钮设置拒保市区
            </p>
          </el-form-item>
          <el-form-item label="拒保区县" prop="area" :rules="rules.PCA">
            <el-tag
              size="mini"
              type="danger"
              closable
              v-for="(item, index) in form.area"
              :key="index"
              @close="delePCA(3, item.id)"
            >
              {{ item.name }}
            </el-tag>
            <p
              style="margin:0;padding: 0;font-size: 12px;color: #999;line-height: 1;position: absolute;bottom:-12px;"
            >
              点击地区库加入按钮设置拒保区县
            </p>
          </el-form-item>
          <el-form-item label="产品名称" prop="productIds">
            <el-select
              v-model="form.productIds"
              placeholder="请选择"
              style="width:100%"
              filterable
              multiple
            >
              <el-option
                v-for="(item, index) in proArray"
                :label="item.product_name"
                :value="item.id + ''"
                :key="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="状态" prop="status">
            <el-radio v-model="form.status" :label="1">拒保</el-radio>
            <el-radio v-model="form.status" :label="0">不拒保</el-radio>
          </el-form-item> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="dialogOk()">
            确 定
          </el-button>
          <el-button @click="dialogClose()" size="small">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import searchForm from "@/components/common/searchForm";
import {
  changeAreaBackList,
  getAreaBackList,
  getAreaBackListInfo,
  unsetProductArea
} from "@/api/blackList";
import { getSelectProductList, getAreaList } from "@/api/public";
export default {
  name: "blackCn",
  data() {
    return {
      searcharray: [
        {
          label: "产品",
          val: "productId",
          type: "select",
          pla: "请选择产品",
          option: []
        }
      ],
      searchData: {},
      proArray: [], //产品列表
      regionArray: [], //地区库
      loading: false,
      tableData: [{ id: 1 }],
      // 分页 每页条数
      pageSize: 15,
      // 分页 每页条数可选项
      pageSizeArray: [15, 30, 45, 60],
      // 当前页
      page: 1,
      // 总页数
      total: 0,
      popTitle: "",
      dialogState: false,
      optArray: [], //已选中黑名单id
      form: {
        province: [], //选中的省
        city: [], //选中的市
        area: [], //选中的县
        productIds: [],
        title: "",
        status: 1
      },
      rules: {
        PCA: [
          {
            validator: (rule, value, callback) => {
              if (
                this.form.province.length > 0 ||
                this.form.city.length > 0 ||
                this.form.area.length > 0
              ) {
                callback();
              } else {
                callback(new Error("拒保地区省份、市区、区县，必须有一个"));
              }
            },
            trigger: ["blur", "change"]
          }
        ],
        productIds: [
          { required: true, message: "产品不能为空", trigger: "blur" }
        ]
      }
    };
  },
  components: {
    searchForm
  },
  mounted() {
    // 获取列表
    this.getAreaBackList();
    // 获取产品列表
    this.getSelectProductList();
    //获取地区库
    this.getAreaList();
  },
  methods: {
    // 搜索数据
    search(e) {
      this.page = 1;
      this.searchData = e;
      console.log(e);
      this.getAreaBackList();
    },
    //搜索重置
    reset(e) {
      this.page = 1;
      this.searchData = {};
      this.getAreaBackList();
    },
    // 获取列表
    getAreaBackList() {
      let that = this;
      let _data = this.searchData;
      _data.page = this.page;
      _data.limit = this.pageSize;
      that.loading = true;
      getAreaBackList(_data)
        .then(function(res) {
          that.loading = false;
          console.log(res);
          if (res.code == 1) {
            that.tableData = res.data.list;
            that.total = res.data.total;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取产品
    getSelectProductList() {
      let that = this;
      getSelectProductList()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.proArray = res.data;
            that.searcharray[0].option = [];
            res.data.forEach(element => {
              let _data = {};
              _data.label = element.product_name;
              _data.val = element.id;
              that.searcharray[0].option.push(_data);
            });
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取地区
    getAreaList() {
      let that = this;
      getAreaList()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.regionArray = res.data;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 每页几条
    sizeChange() {
      this.getAreaBackList();
    },
    // 页码变化
    currentChange() {
      this.getAreaBackList();
    },
    // 打开弹窗
    add() {
      this.optArray = [];
      this.form = {
        province: [], //选中的省
        city: [], //选中的市
        area: [], //选中的县
        productIds: [],
        status: 1
      };
      this.optArray = [];
      this.popTitle = "添加";
      this.dialogState = true;
    }, // 编辑
    handleEdit(e) {
      console.log(e);
      let that = this;
      that.dialogState = true;
      let _data = {
        productId: e
      };
      getAreaBackListInfo(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.popTitle = "编辑";
            that.form.productId = res.data.productId;
            that.form.productIds.push(res.data.productId);
            that.form.productName = res.data.productName;
            that.form.status = res.data.status;
            that.form.province = that.objKey(res.data.province, "id", "name");
            that.form.city = that.objKey(res.data.city, "id", "name");
            that.form.area = that.objKey(res.data.area, "id", "name");
            that.optArray = [
              ...Object.keys(res.data.province),
              ...Object.keys(res.data.city),
              ...Object.keys(res.data.area)
            ];
            that.optArray = that.optArray.map(Number);
            console.log(that.form);
            that.dialogState = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 弹窗确定
    dialogOk() {
      this.$refs.form.validate(valid => {
        let that = this;
        if (valid) {
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          let _data = {};
          _data.province = that.form.province.map(item => item.id);
          _data.city = that.form.city.map(item => item.id);
          _data.area = that.form.area.map(item => item.id);
          _data.productIds = that.form.productIds;
          _data.status = that.form.status;
          if (that.form.productId) {
            _data.productId = that.form.productId;
          }
          changeAreaBackList(_data)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.getAreaBackList();
                that.dialogClose();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭取消
    dialogClose() {
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      });
      this.dialogState = false;
    }, //删除数据
    handleDele(e) {
      console.log(e);
      let that = this;
      this.$confirm("此操作将删除本条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          //确定执行
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          unsetProductArea({ productId: e })
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.getAreaBackList();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        })
        .catch(e => {
          //取消执行
          console.log(e);
        });
    },
    // 添加省市县
    addPCA(level, id, name) {
      if (!this.optArray.includes(id)) {
        this.optArray.push(id);
        if (level == 1) {
          this.form.province.push({ id: id, name: name });
        } else if (level == 2) {
          this.form.city.push({ id: id, name: name });
        } else {
          this.form.area.push({ id: id, name: name });
        }
      }
      this.$refs.form.validateField(["province", "city", "area"]);
    }, //删除选中省市县
    delePCA(level, id) {
      console.log(level, id);
      let _index = this.optArray.indexOf(id);
      console.log(_index);
      this.optArray.splice(_index, 1);
      if (level == 1) {
        this.utilsDele(this.form.province, id);
      } else if (level == 2) {
        this.utilsDele(this.form.city, id);
      } else {
        this.utilsDele(this.form.area, id);
      }
    },
    // 工具删除
    utilsDele(array, id) {
      let _i = "";
      for (let index = 0; index < array.length; index++) {
        if (array[index].id == id) {
          _i = index;
          break;
        }
      }
      array.splice(_i, 1);
    },
    // 处理对象下标
    objKey(obj, id, name) {
      let _obj = Object.keys(obj).map(index => ({
        [id]: index,
        [name]: obj[index]
      }));
      return _obj;
    }
  }
};
</script>
<style lang="scss">
.blackDistrict {
  background: #fff;
  .form {
    width: calc(100% - 100px);
    .el-tag + .el-tag {
      margin-left: 5px;
    }
    .el-form-item__error {
      background: #fff;
    }
  }
}
.el-cascader-node {
  .el-checkbox {
    display: none;
  }
  .el-button {
    float: right;
    margin-top: 3px;
    margin-left: 15px;
  }
}
</style>
