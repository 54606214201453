<template>
  <div class="blackCn">
    <search-form
      :searcharray="searcharray"
      :span="6"
      @change="search"
      @reset="reset"
    ></search-form>
    <el-button type="primary" size="small" icon="el-icon-plus" @click="add()"
      >添加
    </el-button>
    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%;margin-top:15px"
      row-key="id"
      border
      stripe
      lazy
      size="small"
    >
      <el-table-column type="index" label="序号" align="center" width="80px">
      </el-table-column>
      <el-table-column prop="name" align="center" label="公司名称">
      </el-table-column>
      <el-table-column prop="code" align="center" label="信用代码">
      </el-table-column>
      <el-table-column prop="productName" align="center" label="产品名称">
        <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.productName" :key="index">
            <i v-if="index > 0">，</i>{{ item }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="state" align="center" label="状态">
        <template slot-scope="scope">
          <template v-for="(item, index) in scope.row.status">
            <el-tag type="danger" size="mini" v-if="item == 1" :key="index">
              不允许投保
            </el-tag>
            <el-tag type="warning" size="mini" v-if="item == 2" :key="index">
              不允许增加
            </el-tag>
            <el-tag type="info" size="mini" v-if="item == 3" :key="index">
              不允许替换
            </el-tag>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="handleEdit(scope.row.id)">
            编辑
          </el-button>
          <el-button type="text" size="mini" @click="handleDele(scope.row.id)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :page-sizes="pageSizeArray"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!--弹窗 -->
    <div>
      <el-dialog
        :title="popTitle"
        :visible.sync="dialogState"
        width="540px"
        @close="dialogClose"
      >
        <el-form
          :model="form"
          :rules="rules"
          :validate-on-rule-change="false"
          ref="form"
          label-width="130px"
          class="form"
          size="small"
        >
          <el-form-item label="公司名称" prop="companyName">
            <el-input
              v-model="form.companyName"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="信用代码" prop="companyCode">
            <el-input
              v-model="form.companyCode"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="产品名称" prop="productIds">
            <el-select
              v-model="form.productIds"
              placeholder="请选择"
              style="width:100%"
              filterable
              multiple
            >
              <el-option
                v-for="(item, index) in proArray"
                :label="item.product_name"
                :value="item.id + ''"
                :key="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-checkbox-group v-model="form.status">
              <el-checkbox label="1">不允许投保</el-checkbox>
              <el-checkbox label="2">不允许增加</el-checkbox>
              <el-checkbox label="3">不允许替换</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="dialogOk()">
            确 定
          </el-button>
          <el-button @click="dialogClose()" size="small">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import searchForm from "@/components/common/searchForm";
import {
  changeCompanyBackList,
  getCompanyBackList,
  getCompanyBackListInfo,
  unsetCompany
} from "@/api/blackList";
import { getSelectProductList } from "@/api/public";
import verify from "@/utils/verify";
export default {
  name: "blackCn",
  data() {
    return {
      searcharray: [
        {
          label: "公司名称",
          val: "companyName",
          type: "input",
          pla: "请输入公司名称"
        },
        {
          label: "信用代码",
          val: "companyCode",
          type: "input",
          pla: "请输入信用代码"
        },
        {
          label: "产品",
          val: "productId",
          type: "select",
          pla: "请选择产品",
          option: []
        }
      ],
      searchData: {},
      proArray: [],
      loading: false,
      tableData: [{ id: 1 }],
      // 分页 每页条数
      pageSize: 15,
      // 分页 每页条数可选项
      pageSizeArray: [15, 30, 45, 60],
      // 当前页
      page: 1,
      // 总页数
      total: 0,
      popTitle: "",
      dialogState: false,
      form: {
        companyName: "",
        companyCode: "",
        productIds: [],
        status: []
      },
      rules: {
        companyName: [
          { required: true, message: "公司名称不能为空", trigger: "blur" }
        ],
        companyCode: [
          { required: true, message: "信用代码不能为空", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (!verify.isBusiness(value)) {
                callback(new Error("信用代码不正确"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ],
        productIds: [
          { required: true, message: "产品方案不能为空", trigger: "blur" }
        ]
      }
    };
  },
  components: {
    searchForm
  },
  mounted() {
    // 获取列表
    this.getCompanyBackList();
    // 获取产品列表
    this.getSelectProductList();
  },
  methods: {
    // 搜索数据
    search(e) {
      this.page = 1;
      this.searchData = e;
      console.log(e);
      this.getCompanyBackList();
    },
    //搜索重置
    reset(e) {
      this.page = 1;
      this.searchData = {};
      this.getCompanyBackList();
    },
    // 获取列表
    getCompanyBackList() {
      let that = this;
      let _data = this.searchData;
      _data.page = this.page;
      _data.limit = this.pageSize;
      that.loading = true;
      getCompanyBackList(_data)
        .then(function(res) {
          that.loading = false;
          console.log(res);
          if (res.code == 1) {
            that.tableData = res.data.list;
            that.total = res.data.total;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取产品列表
    getSelectProductList() {
      let that = this;
      getSelectProductList()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.proArray = res.data;
            that.searcharray[2].option = [];
            res.data.forEach(element => {
              let _data = {};
              _data.label = element.product_name;
              _data.val = element.id;
              that.searcharray[2].option.push(_data);
            });
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 每页几条
    sizeChange() {
      this.getCompanyBackList();
    },
    // 页码变化
    currentChange() {
      this.getCompanyBackList();
    },
    // 打开弹窗
    add() {
      this.form = {
        companyName: "",
        companyCode: "",
        productIds: [],
        status: []
      };
      this.popTitle = "添加";
      this.dialogState = true;
    }, // 编辑
    handleEdit(e) {
      console.log(e);
      let that = this;
      that.dialogState = true;
      let _data = {
        bcId: e
      };
      getCompanyBackListInfo(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.popTitle = "编辑";
            that.form = {
              bcId: res.data.id,
              companyName: res.data.name,
              companyCode: res.data.code,
              productIds: res.data.product_id,
              status: res.data.status
            };
            that.dialogState = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 弹窗确定
    dialogOk() {
      this.$refs.form.validate(valid => {
        let that = this;
        if (valid) {
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          changeCompanyBackList(that.form)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.getCompanyBackList();
                that.dialogClose();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭取消
    dialogClose() {
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      });
      this.dialogState = false;
    }, //删除数据
    handleDele(e) {
      console.log(e);
      let that = this;
      this.$confirm("此操作将删除本条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          //确定执行
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          unsetCompany({ id: e })
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.getCompanyBackList();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        })
        .catch(e => {
          //取消执行
          console.log(e);
        });
    }
  }
};
</script>
<style lang="scss">
.blackCn {
  background: #fff;
  .el-table {
    .el-tag {
      margin: 0 5px;
    }
  }
  .form {
    width: calc(100% - 100px);
  }
}
</style>
