<template>
  <div class="accountList">
    <search-form
      :searcharray="searcharray"
      :span="8"
      @change="search"
      @reset="reset"
      class="releSearch"
    ></search-form>
    <el-button type="primary" size="small" @click="addAccountBtn">
      添加账户
    </el-button>
    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%;margin-top:15px"
      row-key="id"
      border
      stripe
      lazy
      size="small"
    >
      <el-table-column prop="id" label="ID" width="80px" align="center">
      </el-table-column>
      <el-table-column
        prop="username"
        align="center"
        width="100px"
        label="账号"
      >
      </el-table-column>
      <el-table-column prop="nickname" align="center" label="真实姓名">
      </el-table-column>
      <el-table-column prop="phone" align="center" label="手机号">
      </el-table-column>
      <el-table-column prop="email" align="center" label="邮箱">
      </el-table-column>
      <el-table-column prop="parent" align="center" label="上级">
      </el-table-column>
      <el-table-column prop="teamTitle" align="center" label="团队">
      </el-table-column>
      <el-table-column prop="group" align="center" label="岗位">
        <template slot-scope="scope" v-if="scope.row.group">
          <el-tag
            size="mini"
            style="margin:0 5px"
            v-for="(item, index) in scope.row.group.split('、')"
            :key="index"
          >
            {{ item }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="status" align="center" label="状态" width="80">
        <template slot-scope="scope">
          <el-tag type="success" size="mini" v-if="scope.row.status == 1">
            正常
          </el-tag>
          <el-tag type="danger" size="mini" v-else-if="scope.row.status == 2">
            审核中
          </el-tag>
          <el-tag type="danger" size="mini" v-else-if="scope.row.status == 3">
            拒绝
          </el-tag>
          <el-tag type="danger" size="mini" v-else>停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="logintime" align="center" label="最后登陆时间">
      </el-table-column>
      <el-table-column prop="loginip" align="center" label="最后登陆IP">
      </el-table-column>
      <el-table-column label="操作" align="center" width="230">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="handleEdit(scope.row.id)">
            编辑
          </el-button>
          <el-button
            type="text"
            size="mini"
            v-if="rulesTag.includes('zhEditMm')"
            @click="handleAmend(scope.row.id)"
          >
            修改密码
          </el-button>
          <el-button
            type="text"
            size="mini"
            v-if="
              rulesTag.includes('zhSetPro') && scope.row.is_open_product == 1
            "
            @click="handleSet(scope.row.id)"
          >
            设置产品
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :page-sizes="pageSizeArray"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 添加编辑弹窗 -->
    <el-dialog
      :title="accountTitle"
      :visible="accountVisible"
      @close="closeAccountBtn"
      width="600px"
    >
      <el-form
        :model="accountForm"
        :rules="rules"
        :validate-on-rule-change="false"
        ref="accountForm"
        label-width="120px"
        class="accountForm"
        size="small"
      >
        <el-form-item label="账号" prop="username">
          <el-input
            v-model="accountForm.username"
            placeholder="请输入内容"
            :disabled="accountDis"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="真实姓名" prop="nickname">
          <el-input v-model="accountForm.nickname" placeholder="请输入内容">
          </el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-if="!accountDis">
          <el-input
            v-model="accountForm.password"
            placeholder="密码必须是数字和字母的组合,最少六位"
            show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="repeatPassword" v-if="!accountDis">
          <el-input
            v-model="accountForm.repeatPassword"
            placeholder="请输入内容"
            show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item label="岗位" prop="group">
          <el-select
            v-model="accountForm.group"
            placeholder="请选择"
            multiple
            filterable
            style="width:100%;"
            popper-class="blankLevel"
          >
            <template v-for="item in postList">
              <el-option :label="item.name" :value="item.id" :key="item.id">
              </el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="accountForm.phone" placeholder="请输入内容">
          </el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="accountForm.email" placeholder="请输入内容">
          </el-input>
        </el-form-item>
        <el-form-item label="上级" prop="pid">
          <el-select
            v-model="accountForm.pid"
            placeholder="请选择"
            filterable
            style="width:100%;"
          >
            <el-option
              :label="item.nickname"
              :value="item.id"
              v-for="(item, index) in adminList"
              :key="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="团队" prop="team">
          <el-select
            filterable
            v-model="accountForm.team"
            placeholder="请选择"
            style="width:100%;"
          >
            <el-option
              :label="item.title"
              :value="item.id"
              v-for="(item, index) in teamList"
              :key="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否分配佣金" prop="is_can_commission">
          <el-radio v-model="accountForm.is_can_commission" :label="0">
            否
          </el-radio>
          <el-radio v-model="accountForm.is_can_commission" :label="1">
            是
          </el-radio>
        </el-form-item>
        <el-form-item label="是否开放产品" prop="is_open_product">
          <el-radio v-model="accountForm.is_open_product" :label="0">
            否
          </el-radio>
          <el-radio v-model="accountForm.is_open_product" :label="1">
            是
          </el-radio>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <template v-if="accountFormStatusCut">
            <el-radio v-model="accountForm.status" label="1">正常</el-radio>
            <el-radio v-model="accountForm.status" label="3">拒绝</el-radio>
          </template>
          <template v-else>
            <el-radio v-model="accountForm.status" label="1">正常</el-radio>
            <el-radio v-model="accountForm.status" label="0">停用</el-radio>
            <el-radio
              v-model="accountForm.status"
              label="3"
              v-if="accountForm.status == 3"
              >拒绝</el-radio
            >
          </template>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="accountOk" size="small">
          确 定
        </el-button>
        <el-button @click="closeAccountBtn" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 修改密码弹窗 -->
    <el-dialog
      title="修改密码"
      :visible="amendVisible"
      @close="closeAmendBtn"
      width="550px"
    >
      <el-form
        :model="amendForm"
        :rules="rules2"
        :validate-on-rule-change="false"
        ref="amendForm"
        label-width="100px"
        class="amendForm"
        size="small"
      >
        <el-form-item label="新密码" prop="password">
          <el-input
            v-model="amendForm.password"
            placeholder="密码必须是数字和字母的组合,最少六位"
            show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="repeatPassword">
          <el-input
            v-model="amendForm.repeatPassword"
            placeholder="请输入内容"
            show-password
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="amendOk" size="small">
          确 定
        </el-button>
        <el-button @click="closeAmendBtn" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 设置产品 -->
    <el-dialog
      title="设置产品"
      :visible="setVisible"
      @close="closeSetBtn"
      width="1000px"
    >
      <el-form
        :model="setForm"
        :rules="rules3"
        ref="setForm"
        label-width="0"
        class="setForm"
        size="small"
      >
      产品统一：
      <el-radio-group v-model="yh.productStatus" @change="setProductStatus">
        <el-radio :label="0">关闭</el-radio>
        <el-radio :label="1">开放</el-radio>
      </el-radio-group>
        <el-form-item prop="list" style="margin-top: 15px;">
          <el-table :data="setForm.list" stripe border style="width: 100%">
            <el-table-column
              prop="product_name"
              label="产品名称"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="status"
              label="是否开放"
              width="130"
              align="center"
            >
              <template slot-scope="scope">
                <el-radio-group v-model="scope.row.status">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
            <el-table-column label="佣金结算方式" align="center" width="220">
              <template slot-scope="scope">
                <el-radio-group v-model="scope.row.commission_type">
                  <el-radio :label="1">固定佣金</el-radio>
                  <el-radio :label="2">阶梯佣金</el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
            <el-table-column
              prop="max_commission"
              label="最大佣金"
              width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column label="佣金比例" align="center" width="330">
              <template slot-scope="scope">
                <template v-if="scope.row.commission_type == 1">
                  <el-form-item
                    :prop="'list.' + scope.$index + '.promotion_commission'"
                    :rules="rules3.promotion_commission"
                  >
                    <el-input
                      v-model="scope.row.promotion_commission"
                      placeholder="请输入"
                      size="mini"
                    >
                    </el-input>
                  </el-form-item>
                </template>
                <template v-else>
                  <template
                    v-if="
                      scope.row.step_commission != '' ||
                        scope.row.step_commission.length > 0
                    "
                  >
                    <div
                      class="box"
                      v-for="(item, index) in scope.row.step_commission"
                      :key="index"
                    >
                      <el-form :model="item" :rules="rules3" ref="setFormItem">
                        <el-form-item
                          prop="policyCost"
                          :rules="rules3.policyCost"
                        >
                          保费满
                          <el-input
                            v-model="item.policyCost"
                            placeholder="请输入"
                            size="mini"
                          >
                          </el-input
                          >万元，
                        </el-form-item>
                        <el-form-item
                          prop="ratio"
                          :rules="rules3.ratio"
                          :class="
                            item.ratio > scope.row.max_commission
                              ? 'is-error'
                              : ''
                          "
                        >
                          佣金比例
                          <el-input
                            v-model="item.ratio"
                            placeholder="请输入"
                            size="mini"
                            @change="
                              maxRatio(
                                scope.row.product_name,
                                item.ratio,
                                scope.row.max_commission
                              )
                            "
                          >
                          </el-input>
                        </el-form-item>
                        <el-button
                          v-if="index == 0"
                          type="primary"
                          size="mini"
                          @click="addStep(scope.$index)"
                        >
                          添加
                        </el-button>
                        <el-button
                          v-else
                          type="danger"
                          size="mini"
                          @click="deleStep(scope.$index, index)"
                        >
                          删除
                        </el-button>
                      </el-form>
                    </div>
                  </template>
                  <el-button
                    v-else
                    type="primary"
                    size="mini"
                    @click="addStep(scope.$index)"
                  >
                    添加
                  </el-button>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setOk" size="small">
          确 定
        </el-button>
        <el-button @click="closeSetBtn" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import searchForm from "@/components/common/searchForm";
import {
  changeAdmin,
  getAuthList,
  getTeam,
  getAdmin,
  getAdminList,
  getAdminInfo,
  changePassword,
  getProductChannelList,
  changeProductChannel
} from "@/api/system";
import recursionChildData from "@/utils/recursionChildData";
export default {
  components: {
    searchForm
  },
  data() {
    var password = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.accountForm.repeatPassword !== "") {
          this.$refs.accountForm.validateField("repeatPassword");
        }
        callback();
      }
    };
    var repPassWord = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.accountForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var password2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.amendForm.repeatPassword !== "") {
          this.$refs.amendForm.validateField("repeatPassword");
        }
        callback();
      }
    };
    var repPassWord2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.amendForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
        yh:{
            productStatus:0,
        },
      // 当前账号可操作权限标识
      rulesTag: this.$store.state.rulesTag,
      disabled: false,
      searcharray: [
        {
          label: "搜索内容",
          val: "keywords",
          type: "input",
          pla: "请输入账号、姓名、手机号"
        },
        {
          label: "状态",
          val: "status",
          type: "select",
          pla: "请选择状态",
          option: [
            { label: "正常", val: "1" },
            { label: "待审核", val: "2" },
            { label: "拒绝", val: "3" },
            { label: "停用", val: "0" }
          ]
        }
      ],
      // 搜索数据
      searchData: {},
      // 表格数据
      tableData: [],
      loading: true,
      // 分页 每页条数
      pageSize: 15,
      // 分页 每页条数可选项
      pageSizeArray: [15, 30, 45, 60],
      // 当前页
      page: 1,
      // 总页数
      total: 0,
      accountTitle: "添加",
      accountDis: false, //用于出来弹窗编辑状态
      accountVisible: false,
      //岗位列表
      postList: [],
      // 团队列表
      teamList: [],
      // 上级列表
      adminList: [],
      accountFormStatusCut: false, //账号是否是待审核 是true 否false
      accountForm: {
        id: "",
        username: "",
        nickname: "",
        password: "",
        repeatPassword: "",
        group: [],
        phone: "",
        email: "",
        pid: "",
        team: "",
        is_can_commission: 0,
        is_open_product: 0,
        status: "1"
      },
      rules: {
        username: [
          { required: true, message: "账号不可为空", trigger: "blur" }
        ],
        nickname: [
          { required: true, message: "真实姓名不可为空", trigger: "blur" },
          {
            pattern: /[\u4e00-\u9fa5]+/g,
            message: "真实姓名请输入中文",
            trigger: "blur"
          }
        ],
        password: [
          { required: true, message: "密码不可为空", trigger: "blur" },
          {
            min: 6,
            message: "密码长度不少于6个字符",
            trigger: "blur"
          },
          {
            pattern: /^(?![^a-zA-Z]+$)(?!\D+$)/,
            message: "密码必须是数字和字母的组合",
            trigger: "blur"
          },
          { validator: password, trigger: "blur" }
        ],
        repeatPassword: [
          { required: true, message: "请确认密码", trigger: "blur" },
          { validator: repPassWord, trigger: "blur" }
        ],
        group: [{ required: true, message: "请选择所属岗位", trigger: "blur" }],
        phone: [
          { required: true, message: "手机号不可为空", trigger: "blur" },
          {
            pattern: /^1[0-9]{10}$/,
            message: "手机号格式不正确",
            trigger: "blur"
          }
        ],
        email: [
          { required: true, message: "邮箱不可为空", trigger: "blur" },
          { type: "email", message: "邮箱格式不正确", trigger: "blur" }
        ],
        pid: [{ required: true, message: "请选择上级", trigger: "blur" }],
        team: [{ required: true, message: "请选择团队", trigger: "blur" }],
        is_can_commission: [
          { required: true, message: "请选择能否分配佣金", trigger: "blur" }
        ],
        is_open_product: [
          { required: true, message: "请选择是否开放产品", trigger: "blur" }
        ],
        status: [{ required: true, message: "请选择状态", trigger: "blur" }]
      },
      amendVisible: false, //修改账号弹窗
      amendForm: {
        id: "",
        password: "",
        repeatPassword: ""
      },
      rules2: {
        password: [
          { required: true, message: "新密码不可为空", trigger: "blur" },
          {
            min: 6,
            message: "新密码长度不少于6个字符",
            trigger: "blur"
          },
          {
            pattern: /^(?![^a-zA-Z]+$)(?!\D+$)/,
            message: "新密码必须是数字和字母的组合",
            trigger: "blur"
          },
          { validator: password2, trigger: "blur" }
        ],
        repeatPassword: [
          { required: true, message: "请确认新密码", trigger: "blur" },
          { validator: repPassWord2, trigger: "blur" }
        ]
      },
      setVisible: false, //设置产品
      setForm: {
        list: [
          {
            product_name: "产品名称",
            status: 0,
            commission_type: 1,
            promotion_commission: "50",
            max_commission: "",
            step_commission: [
              {
                policyCost: "",
                ratio: ""
              }
            ]
          }
        ]
      },
      rules3: {
        promotion_commission: [
          { required: true, message: "佣金比例不可为空", trigger: "blur" },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: "佣金比例必须为数字值",
            trigger: "blur"
          },
          {
            validator: (rule, value, callback, pars) => {
              let key = Object.keys(pars)[0].split(".")[1];
              console.log(key);
              if (
                Number(value) <= Number(this.setForm.list[key].max_commission)
              ) {
                callback();
              } else {
                callback(
                  new Error(
                    "佣金比例不能大于" +
                      Number(this.setForm.list[key].max_commission)
                  )
                );
              }
            },
            trigger: "blur"
          }
        ],
        policyCost: [
          { required: true, message: "金额不可为空", trigger: "blur" },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: "金额必须为数字值",
            trigger: "blur"
          }
        ],
        ratio: [
          { required: true, message: "佣金比例不可为空", trigger: "blur" },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: "佣金比例必须为数字值",
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    this.getAdminList();
  },
  methods: {
    // 设置产品全部开放、关闭
    setProductStatus(e){
        this.setForm.list.forEach(item => {
            item.status = e
        })
    },
    // 搜索
    search(e) {
      this.page = 1;
      this.searchData = e;
      this.getAdminList();
    },
    // 重置
    reset(e) {
      this.page = 1;
      this.searchData = {};
      this.getAdminList();
    },
    // 获取账户列表
    getAdminList() {
      let _data = this.searchData;
      let that = this;
      _data.page = that.page;
      _data.limit = that.pageSize;
      that.loading = true;
      getAdminList(_data)
        .then(function(res) {
          console.log(res);
          that.loading = false;
          if (res.code == 1) {
            that.tableData = res.data.list;
            that.total = res.data.total;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 添加账户
    addAccountBtn() {
      this.accountTitle = "添加";
      this.accountDis = false;
      this.accountForm = {
        id: "",
        username: "",
        nickname: "",
        password: "",
        repeatPassword: "",
        group: [],
        phone: "",
        email: "",
        pid: "",
        team: "",
        is_can_commission: 0,
        is_open_product: 0,
        status: "1"
      };
      this.getAuthList();
      this.getTeam();
      this.getAdmin();
      this.accountVisible = true;
    },
    // 弹窗取消
    closeAccountBtn() {
      this.$nextTick(() => {
        this.$refs.accountForm.resetFields();
      });
      this.accountVisible = false;
    },
    //弹窗确定
    accountOk(e) {
      this.$refs.accountForm.validate(valid => {
        let that = this;
        console.log(valid);
        if (valid) {
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          let _data = {
            id: that.accountForm.id,
            username: that.accountForm.username,
            nickname: that.accountForm.nickname,
            password: that.accountForm.password,
            repeatPassword: that.accountForm.repeatPassword,
            group: that.accountForm.group.join(","),
            phone: that.accountForm.phone,
            email: that.accountForm.email,
            pid: that.accountForm.pid,
            team: that.accountForm.team,
            status: that.accountForm.status,
            is_can_commission: that.accountForm.is_can_commission,
            is_open_product: that.accountForm.is_open_product
          };
          console.log(that.accountForm);
          changeAdmin(_data)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.getAdminList();
                that.closeAccountBtn();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑
    handleEdit(e) {
      console.log(e);
      let that = this;
      let _data = { aid: e };
      that.accountTitle = "编辑";
      that.accountDis = true;
      that.getAuthList();
      that.getTeam();
      that.getAdmin();
      getAdminInfo(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.accountForm = res.data;
            if (that.accountForm.status == 2) {
              that.accountFormStatusCut = true;
              that.accountForm.status = "3";
            } else {
              that.accountFormStatusCut = false;
            }
            that.accountVisible = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 修改密码按钮
    handleAmend(e) {
      console.log(e);
      this.amendForm.id = e;
      this.amendVisible = true;
    },
    // 修改密码弹窗关闭
    closeAmendBtn() {
      this.$nextTick(() => {
        this.$refs.amendForm.resetFields();
      });
      this.amendVisible = false;
    },
    // 修改密码弹窗确定
    amendOk() {
      this.$refs.amendForm.validate(valid => {
        if (valid) {
          let that = this;
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          changePassword(that.amendForm)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.closeAmendBtn();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 设置产品弹窗打开
    handleSet(e) {
      this.setForm.id = e;
      let that = this;
      getProductChannelList({ admin_id: e })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.setForm.list = res.data;
            that.setVisible = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 设置产品弹窗关闭
    closeSetBtn() {
      this.$nextTick(() => {
        this.$refs.setForm.resetFields();
      });
      this.setVisible = false;
    },
    // 设置产品弹窗阶梯添加
    addStep(i) {
      if (this.setForm.list[i].step_commission) {
        this.setForm.list[i].step_commission.push({
          policyCost: "0",
          ratio: "0"
        });
      } else {
        this.setForm.list[i].step_commission = [];
        this.setForm.list[i].step_commission.push({
          policyCost: "0",
          ratio: "0"
        });
      }
    },
    // 设置产品弹窗阶梯添加
    deleStep(i, e) {
      this.setForm.list[i].step_commission.splice(e, 1);
    },
    //佣金比例设置超过最大佣金
    maxRatio(t, v, b) {
      console.log(v, b);
      if (parseFloat(v) > parseFloat(b)) {
        this.$message.error(t + "佣金比例设置不可以大于最大佣金" + b);
      }
    },
    // 设置产品确定
    setOk() {
      let that = this;
      this.$refs.setForm.validate(valid => {
        if (valid) {
          if (that.$refs.setFormItem) {
            that.$refs.setFormItem.forEach(element => {
              element.validate(v => {});
            });
          }
          that.$nextTick(() => {
            let error = document.querySelectorAll(".is-error");
            if (error.length > 0) {
              that.$nextTick(() => {
                document.querySelector(".is-error").scrollIntoView();
              });
            } else {
              let loadind = that.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
              });
              let _data = {};
              _data.channelData = that.setForm.list;
              _data.admin_id = that.setForm.id;
              console.log(_data);
              changeProductChannel(_data)
                .then(function(res) {
                  console.log(res);
                  loadind.close();
                  if (res.code == 1) {
                    that.$message.success(res.msg);
                    that.closeSetBtn();
                  } else {
                    that.$message.error(res.msg);
                  }
                })
                .catch(function(error) {
                  console.log(error);
                });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 每页几条
    sizeChange() {
      this.getAdminList();
    },
    // 页码变化
    currentChange() {
      this.getAdminList();
    },
    // 获取岗位列表
    getAuthList() {
      let that = this;
      let _data = { status: 1 };
      getAuthList(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            //递归处理
            that.postList = recursionChildData(res.data, "child");
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    //获取登录账号所属及其下属团队
    getTeam() {
      let that = this;
      getTeam()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.teamList = res.data;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    //获取当前登录账号下可用账号列表
    getAdmin() {
      let that = this;
      getAdmin()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.adminList = res.data;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>
<style lang="scss">
.accountList {
  padding: 30px;
  background: #fff;
  min-height: 100%;
  // 弹窗
  .accountForm,
  .amendForm {
    width: calc(100% - 100px);
    .el-form-item {
      margin-bottom: 15px;
    }
  }
  .setForm {
    .el-form-item {
      margin: 0;
      .el-input {
        width: 50px;
        display: inline-block;
        input {
          padding: 0 5px;
        }
      }
    }
    .cell {
      .box .el-form {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        .el-form-item__error {
          display: none;
        }
        .el-button {
          padding: 3px 6px;
          margin-left: 15px;
        }
      }
      .box + .box {
        border-top: 1px solid #7eb1e733;
        margin-top: 5px;
        padding-top: 5px;
      }
    }
  }
}
</style>
