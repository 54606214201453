import Vue from "vue";
import Vuex from "vuex";
import Cookies from "js-cookie";
import router from "@/router";
const baseUrlS = require("@/config/index.js");
import { routerlist, logout } from "@/api/login";
import { getAuthRules } from "@/api/system";
import dataTreating from "@/utils/dataTreating";
import { Message } from "element-ui";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 侧边栏是否关闭默认展开
    closeSidebar: false,
    token: Cookies.get("RL_ADMINTO_KENKEY"),
    user: Cookies.getJSON("RL_ADMINTO_USER"),
    // 动态路由
    routerList: "",
    // routerList: router.options.routes,
    // 判断是否已动态加载路由用于侧边栏消息更新避免路由重复 true
    isRouter: false,
    // 路径、请求地址
    baseUrl: baseUrlS.baseUrl,
    // 权限id
    rules: [],
    // 权限标识
    rulesTag: [],
    // 是否以更新权限
    isRules: false,
    isLog: false, //登陆后账号异常抛出消息处理
    // 保单状态
    orderStatus: [
      {
        status:
          "1待支付  2 待审核  3 审核成功  4 驳回  5 待生效  6 保障中  7 已过期  8 已取消 9申请退款 10已退款 11待上传资料 12空缺 13保前审核"
      },
      { color: "#B9A083", text: "待支付", icon: "icon-daizhifu" },
      { color: "#B9A083", text: "待审核", icon: "icon-daishenhe" },
      { color: "#B9A083", text: "审核成功", icon: "icon-daishengxiao" },
      { color: "#B9A083", text: "驳回", icon: "icon-chengbaoshibai" },
      { color: "#B9A083", text: "待生效", icon: "icon-daishengxiao" },
      { color: "#3F5DCA", text: "保障中", icon: "icon-baozhang" },
      { color: "#B9A083", text: "已过期", icon: "icon-icon-test" },
      { color: "#B9A083", text: "已取消", icon: "icon-yiquxiao" },
      { color: "#B9A083", text: "申请退款", icon: "icon-shenqingtuikuan" },
      { color: "#B9A083", text: "已退款", icon: "icon-shenqingtuikuan" },
      { color: "#B9A083", text: "待上传资料", icon: "icon-shangchuanziliao" },
      { color: "#B9A083", text: "空缺", icon: "icon-daishenhe" },
      { color: "#B9A083", text: "保前审核", icon: "icon-daishenhe" }
    ]
  },
  getters: {
    token: state => {
      if (state.user) {
        return state.user.token;
      }
    }, //头像
    avatar: state => {
      if (state.user) {
        return state.user.avatar;
      }
    }, //名字
    name: state => {
      if (state.user) {
        return state.user.username;
      }
    }, //id
    id: state => {
      if (state.user) {
        return state.user.id;
      }
    }, // 岗位id
    group: state => {
      if (state.user) {
        return state.user.group_id;
      }
    }
  },
  mutations: {
    // 操作侧边栏
    alterCloseSidebar(state) {
      if (state.closeSidebar) {
        state.closeSidebar = false;
      } else {
        state.closeSidebar = true;
      }
    }, //添加token
    addToken(state, token) {
      state.token = token;
      Cookies.set("RL_ADMINTO_KENKEY", token);
    }, //添加账户信息
    addUser(state, user) {
      state.user = user;
      Cookies.set("RL_ADMINTO_USER", user);
    }, // 转跳登陆页
    goLogin(state, msg) {
      if (msg && state.isLog) {
        state.isLog = false;
        Message.error(msg);
      }
      state.user = "";
      state.token = "";
      Cookies.remove("RL_ADMINTO_KENKEY");
      Cookies.remove("RL_ADMINTO_USER");
      router.push("/login");
    }
  },
  actions: {
    // 退出
    logout(context) {
      let _data = {};
      logout(_data)
        .then(function(res) {
          if (res.code == 1) {
            context.state.user = "";
            context.state.token = "";
            context.state.routerList = "";
            context.state.isRouter = false;
            context.state.isRules = false;
            Cookies.remove("RL_ADMINTO_KENKEY");
            Cookies.remove("RL_ADMINTO_USER");
            router.push("/login");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 请求路由
    getRouterlist(context) {
      let _data = {};
      routerlist(_data)
        .then(function(res) {
          if (res.code == 1) {
            let routeArray = dataTreating.filterAsyncRouter(res.data.router);
            context.state.routerList = routeArray;
            if (!context.state.isRouter) {
              context.state.routerList.forEach(item => {
                router.addRoute(item);
              });
              router.addRoute({ path: "*", redirect: "/404", hidden: true });
              router.options.routes = context.state.routerList;
              context.state.isRouter = true;
            }
            // 数据不返回首页时开启
            // router.options.routes.unshift({
            //   path: "/",
            //   component: "Layout",
            //   redirect: "/",
            //   children: [
            //     {
            //       path: "/",
            //       name: "dashboard",
            //       component: "dashboard",
            //       meta: { title: "首页", icon: "el-icon-s-home" }
            //     }
            //   ]
            // });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取用户权限
    getAuthRules(context) {
      let that = this;
      let _data = { gid: context.getters.group };
      getAuthRules(_data)
        .then(function(res) {
          if (res.code == 1) {
            context.state.rules = res.data.rules.split(",");
            context.state.rulesTag = res.data.condition.split(",");
            context.state.isRules = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  },
  modules: {}
});
