//日期选择范围 快捷按钮
export function newDate() {
  let newData = new Date();
  let year = newData.getFullYear();
  let month = newData.getMonth() + 1;
  let date = newData.getDate();
  newData = year + "/" + month + "/" + date;
  return newData;
}
// 今天
export const today = {
  text: "今天",
  onClick(picker) {
    const end = new Date(newDate() + " " + "23:59:59");
    const start = new Date(newDate() + " " + "00:00:00");
    start.setTime(start);
    end.setTime(end);
    picker.$emit("pick", [start, end]);
  }
}; // 昨天
export const yester = {
  text: "昨天",
  onClick(picker) {
    const end = new Date(newDate() + " " + "23:59:59");
    const start = new Date(newDate() + " " + "00:00:00");
    start.setTime(start.getTime() - 3600 * 1000 * 24);
    end.setTime(end.getTime() - 3600 * 1000 * 24);
    picker.$emit("pick", [start, end]);
  }
};
// 最近7天
export const recent7 = {
  text: "最近7天",
  onClick(picker) {
    const end = new Date(newDate() + " " + "23:59:59");
    const start = new Date(newDate() + " " + "00:00:00");
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
    picker.$emit("pick", [start, end]);
  }
};
// 最近30天
export const recent30 = {
  text: "最近30天",
  onClick(picker) {
    const end = new Date(newDate() + " " + "23:59:59");
    const start = new Date(newDate() + " " + "00:00:00");
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
    picker.$emit("pick", [start, end]);
  }
};
// 本月
export const currentMonth = {
  text: "本月",
  onClick(picker) {
    let newData = new Date();
    let year = newData.getFullYear();
    let month = newData.getMonth();
    const start = new Date(year, month, 1);
    const end = new Date(year, month + 1, 0);
    end.setTime(end.getTime() + 3600 * 1000 * 24 - 1);
    picker.$emit("pick", [start, end]);
  }
};
// 上月
export const lastMonth = {
  text: "上月",
  onClick(picker) {
    let newData = new Date();
    let year = newData.getFullYear();
    let month = newData.getMonth();
    const start = new Date(year, month - 1, 1);
    const end = new Date(year, month, 0);
    end.setTime(end.getTime() + 3600 * 1000 * 24 - 1);
    picker.$emit("pick", [start, end]);
  }
};
// shortcutsOne 按钮组【今天、昨天，最近7天、最近30天、本月、上月】
export const shortcutsOne = [
  today,
  yester,
  recent7,
  recent30,
  currentMonth,
  lastMonth
];
