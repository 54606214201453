<template>
  <div class="productList">
    <el-tabs v-model="identi" type="card" @tab-click="handleClick">
      <el-tab-pane label="非车业务" name="1"></el-tab-pane>
      <el-tab-pane label="车险" name="2"></el-tab-pane>
      <el-tab-pane label="寿险" name="3"></el-tab-pane>
    </el-tabs>
    <search-form
      ref="searchform"
      :searcharray="searcharray"
      :span="6"
      @change="search"
      @reset="reset"
    ></search-form>
    <el-button
      type="primary"
      size="small"
      icon="el-icon-plus"
      @click="add()"
      v-if="rulesTag.includes('cpAdd')"
    >
      添加
    </el-button>
    <el-button
      type="primary"
      size="small"
      @click="batchSte()"
      v-if="rulesTag.includes('cpBatchSte')"
    >
      产品佣金批量设置
    </el-button>
    <el-button
      type="primary"
      size="small"
      @click="batchFuzeren()"
    >
      设置负责人
    </el-button>
    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%;margin-top:15px"
      row-key="id"
      border
      stripe
      lazy
      size="small"
      class="tableList"
      @selection-change="handleSelectionChange"
    >
    <el-table-column
      type="selection"
      width="55">
    </el-table-column>
      <el-table-column prop="id" label="ID" align="center" width="80px">
      </el-table-column>
      <el-table-column
        prop="product_num"
        align="center"
        label="产品编号"
        width="150px"
      >
      </el-table-column>
      <el-table-column prop="product_name" align="center" label="产品名称">
      </el-table-column>
      <el-table-column prop="company" align="center" label="保险公司">
      </el-table-column>
      <el-table-column prop="product_cate" align="center" label="分类">
      </el-table-column>
      <el-table-column prop="sort" align="center" label="排序" width="100px">
      </el-table-column>
      <el-table-column
        prop="product_status"
        align="center"
        label="产品状态"
        width="150px"
      >
      </el-table-column>
      <el-table-column label="操作" align="center" width="400">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="precept(scope.row.id)"
            v-if="rulesTag.includes('cpSetGz')"
          >
            设置规则
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="scheme(scope.row.id)"
            v-if="rulesTag.includes('cpSetFa')"
          >
            设置方案
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="brokerage(scope.row.id)"
            v-if="rulesTag.includes('cpSetYj')"
          >
            设置佣金
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="edit(scope.row.id)"
            v-if="rulesTag.includes('cpEdit')"
          >
            编辑
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="oneCopy(scope.row.id)"
          >
            一键复制
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :page-sizes="pageSizeArray"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!--弹窗 -->
    <div class="boxPop">
      <el-dialog
        :title="popTitle"
        :visible.sync="dialogState"
        top="15px"
        width="1200px"
        @close="dialogClose"
        :close-on-click-modal="false"
      >
        <group-based
          :id="id"
          :identi="identi"
          :status.sync="dialogState"
          @upStatus="getProductList()"
          v-if="isBased && dialogState"
        ></group-based>
        <group-regular
          :id="id"
          :status.sync="dialogState"
          v-if="isRegular && dialogState"
        ></group-regular>
        <group-precept
          :id="id"
          :status.sync="dialogState"
          v-if="isPrecept && dialogState"
        ></group-precept>
      </el-dialog>
      <el-dialog
        title="设置佣金"
        :visible.sync="dialogState2"
        top="100px"
        width="700px"
        @close="dialogClose2"
        :close-on-click-modal="false"
      >
        <group-brokerage
          :id="id"
          :status.sync="dialogState2"
          v-if="dialogState2"
        ></group-brokerage>
      </el-dialog>
    </div>
    <div class="boxPop">
        <el-dialog
          :title="popTitle"
          :visible.sync="dialogStateFuzeren"
          top="15px"
          width="1200px"
          @close="dialogCloseFuzeren"
          :close-on-click-modal="false"
        >
        <el-form
            inline
            ref="searchform"
            class="searchform"
        >
                <el-form-item label="负责人姓名">
                    <el-input v-model="fuzerenName" placeholder="输入负责人名字">
                </el-input>
                </el-form-item>
            </el-form>
            <el-button
            type="primary"
            size="small"
            @click="batchFuzerenSave()"
            >
            保存
            </el-button>
        </el-dialog>
        
      </div>
    <!-- 抽屉佣金批量设置 -->
    <el-drawer
      title="产品佣金批量设置"
      :visible.sync="drawer"
      @close="closeSetBtn"
      size="1150px"
      class="setDrawer"
    >
      <el-input
        placeholder="请选择级别"
        suffix-icon="el-icon-arrow-down"
        v-model="drawerLevel"
        size="small"
        readonly
        class="drawerLevelBtn"
        @click.native="triggerLevelVisible"
      >
      </el-input>
      <el-form
        :model="setForm"
        :rules="rules3"
        ref="setForm"
        label-width="0"
        class="setForm"
        size="small"
      >
        <el-form-item prop="list">
          <el-table
            :data="setForm.list"
            height="calc(100vh - 220px)"
            stripe
            style="width: 100%"
          >
            <el-table-column
              prop="product_name"
              show-overflow-tooltip
              label="产品名称"
            >
            </el-table-column>
            <el-table-column prop="status" label="是否开放" width="145">
              <template slot-scope="scope">
                <el-radio-group v-model="scope.row.status">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
            <el-table-column label="佣金结算方式" width="220">
              <template slot-scope="scope">
                <el-radio-group v-model="scope.row.commission_type">
                  <el-radio :label="1">固定佣金</el-radio>
                  <el-radio :label="2">阶梯佣金</el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
            <el-table-column prop="max_commission" label="最大佣金" width="90">
            </el-table-column>
            <el-table-column label="佣金比例" width="370">
              <template slot-scope="scope">
                <template v-if="scope.row.commission_type == 1">
                  <el-form-item
                    :prop="'list.' + scope.$index + '.promotion_commission'"
                    :rules="rules3.promotion_commission"
                  >
                    <el-input
                      v-model="scope.row.promotion_commission"
                      placeholder="请输入"
                      size="mini"
                      @change="
                        maxRatio(
                          scope.row.product_name,
                          scope.row.promotion_commission,
                          scope.row.max_commission
                        )
                      "
                    >
                    </el-input>
                  </el-form-item>
                </template>
                <template v-else>
                  <template
                    v-if="
                      scope.row.step_commission != '' ||
                        scope.row.step_commission.length > 0
                    "
                  >
                    <div
                      class="box"
                      v-for="(item, index) in scope.row.step_commission"
                      :key="index"
                    >
                      <el-form :model="item" :rules="rules3" ref="setFormItem">
                        <el-form-item
                          prop="policyCost"
                          :rules="rules3.policyCost"
                        >
                          保费满
                          <el-input
                            v-model="item.policyCost"
                            placeholder="请输入"
                            size="mini"
                          >
                          </el-input
                          >万元，
                        </el-form-item>
                        <el-form-item
                          prop="ratio"
                          :rules="rules3.ratio"
                          :class="
                            item.ratio > scope.row.max_commission
                              ? 'is-error'
                              : ''
                          "
                        >
                          佣金比例
                          <el-input
                            v-model="item.ratio"
                            placeholder="请输入"
                            size="mini"
                            @change="
                              maxRatio(
                                scope.row.product_name,
                                item.ratio,
                                scope.row.max_commission
                              )
                            "
                          >
                          </el-input>
                        </el-form-item>
                        <el-button
                          v-if="index == 0"
                          type="primary"
                          @click="addStep(scope.$index)"
                        >
                          添加
                        </el-button>
                        <el-button
                          v-else
                          type="danger"
                          @click="deleStep(scope.$index, index)"
                        >
                          删除
                        </el-button>
                      </el-form>
                    </div>
                  </template>
                  <el-button
                    v-else
                    type="primary"
                    size="mini"
                    @click="addStep(scope.$index)"
                  >
                    添加
                  </el-button>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <div class="btnBox">
        <el-button @click="resetPro(setForm.id, setDrawerTit)" size="small">
          重置
        </el-button>
        <el-button type="primary" @click="setOk" size="small">
          确 定
        </el-button>
      </div>
      <div class="supernatant" v-if="drawerLevelVisible">
        <div class="supLeft">
          <el-radio-group v-model="drawerLevelId" @change="changeLevelId">
            <el-radio
              v-for="item in levelList"
              :label="item.levelId"
              :key="item.levelId"
            >
              {{ item.levelName }}
            </el-radio>
          </el-radio-group>
        </div>
        <div class="supRight">
          <div class="supRsearchBox">
            <el-input
              size="small"
              v-model="rsearch"
              placeholder="请输入关键字"
            ></el-input>
            <el-button size="small" class="supSearch" @click="rsearchBtn">
              搜索
            </el-button>
            <el-button size="small" @click="rsearchResetBtn">重置</el-button>
          </div>
          <el-table
            ref="drawerTable"
            :data="drawertableData"
            style="width: 100%"
            stripe
            height="calc(100% - 120px)"
            :row-class-name="tableRowClassName"
            @selection-change="drawerSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="nickname" label="姓名"> </el-table-column>
            <el-table-column prop="username" label="账号"> </el-table-column>
            <el-table-column prop="teamTitle" label="团队"> </el-table-column>
          </el-table>
          <el-button
            size="small"
            type="primary"
            class="supernatantOk"
            @click="drawerLevelVisible = false"
          >
            确定
          </el-button>
        </div>
        <i class="el-icon-circle-close" @click="triggerLevelVisible"></i>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import searchForm from "@/components/common/searchForm";
import groupBased from "@/components/productAdmin/groupBased";
import groupRegular from "@/components/productAdmin/groupRegular";
import groupPrecept from "@/components/productAdmin/groupPrecept";
import groupBrokerage from "@/components/productAdmin/groupBrokerage";
import {
  getProductList,
  getAdminLevelList,
  changeProductAllChannel,
  copyProductInfo,
  batchFuzeren
} from "@/api/productAdmin";
import { getProductCate, getCompany } from "@/api/public";
import { getProductChannelList } from "@/api/system";
export default {
  name: "productList",
  components: {
    searchForm,
    groupBased,
    groupRegular,
    groupPrecept,
    groupBrokerage
  },
  data() {
    return {
      // 当前账号可操作权限标识
      rulesTag: this.$store.state.rulesTag,
      // 添加基础数据id
      id: "",
      identi: "1",
      searcharray: [
        {
          label: "产品名称",
          val: "product_name",
          type: "input",
          pla: "请输入名称"
        },
        {
          label: "产品代码",
          val: "product_code",
          type: "input",
          pla: "请输入名称"
        },
        {
          label: "产品编号",
          val: "product_num",
          type: "input",
          pla: "请输入名称"
        },
        {
          label: "保险公司",
          val: "company",
          type: "select",
          pla: "请选择保险公司",
          option: []
        },
        {
          label: "产品分类",
          val: "product_cate",
          type: "select",
          pla: "请选择产品分类",
          option: []
        },
        {
          label: "产品状态",
          val: "product_status",
          type: "select",
          pla: "请选择产品状态",
          option: [
            { label: "下线", val: "1" },
            { label: "上线", val: "2" },
            { label: "资料整理中", val: "3" },
            { label: "审核中", val: "4" },
            { label: "备案中", val: "5" }
          ]
        }
      ],
      searchVal: {},
      // 列表数据
      tableData: [],
      loading: false,
      // 分页 每页条数
      pageSize: 15,
      // 分页 每页条数可选项
      pageSizeArray: [15, 30, 45, 60],
      // 当前页
      page: 1,
      // 总页数
      total: 0,
      popTitle: "添加",
      dialogState: false, //弹窗
      dialogStateFuzeren: false, //弹窗
      isBased: false, //基础设置
      isRegular: false, //规则
      isPrecept: false, //方案
      dialogState2: false, //佣金弹窗
      drawer: false, //产品佣金批量设置
      admin_id: this.$store.getters.id,
      levelList: [], ////产品佣金批量等级账户列表
      adminId: [], //产品佣金批量设置选中账号
      drawerLevel: "一级", //产品佣金批量设置选择级别文字
      drawerLevelId: 1, //产品佣金批量设置选择级别id
      drawerLevelVisible: false, //选择级别浮框显示隐藏
      rsearch: "", //产品佣金批量设置搜索关键字
      setForm: {
        //产品佣金批量设置 form
        list: [
          {
            product_name: "产品名称",
            status: 0,
            commission_type: 1,
            promotion_commission: "50",
            max_commission: "",
            step_commission: [
              {
                policyCost: "",
                ratio: ""
              }
            ]
          }
        ]
      },
      rules3: {
        promotion_commission: [
          {
            required: true,
            message: "佣金比例不可为空",
            trigger: ["blur", "change"]
          },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: "佣金比例必须为数字值",
            trigger: ["blur", "change"]
          },
          {
            validator: (rule, value, callback, pars) => {
              let key = Object.keys(pars)[0].split(".")[1];
              if (
                Number(value) <= Number(this.setForm.list[key].max_commission)
              ) {
                callback();
              } else {
                callback(
                  new Error(
                    "佣金比例不能大于" +
                      Number(this.setForm.list[key].max_commission)
                  )
                );
              }
            },
            trigger: ["blur", "change"]
          }
        ],
        policyCost: [
          {
            required: true,
            message: "金额不可为空",
            trigger: ["blur", "change"]
          },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: "金额必须为数字值",
            trigger: ["blur", "change"]
          }
        ],
        ratio: [
          {
            required: true,
            message: "佣金比例不可为空",
            trigger: ["blur", "change"]
          },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: "佣金比例必须为数字值",
            trigger: ["blur", "change"]
          }
        ]
      },
      drawertableData: [], ////产品佣金批量账号
      selectData:[],
      fuzerenName:'',
    };
  },
  mounted() {
    this.getProductList(); //获取列表
    this.getCompany(); //获取保司
    this.getProductCate(); //获取产品分类
  },
  methods: {
    handleSelectionChange(e){
        this.selectData = [];
        e.forEach(item=>{
            this.selectData.push(item.id);
        })
    },
    batchFuzeren(){
        this.id = "";
        this.popTitle = "设置负责人";
        this.dialogStateFuzeren = true;
        this.fuzerenName = '';
    },
    dialogCloseFuzeren(){
        this.dialogStateFuzeren = false;
    },
    batchFuzerenSave(){
        let that = this;
        batchFuzeren({ name: this.fuzerenName,proId:this.selectData })
            .then(function(res) {
            console.log(res);
            if (res.code == 1) {
                that.$message.success(res.msg);
                that.dialogStateFuzeren = false;
            } else {
                that.$message.error(res.msg);
            }
            })
            .catch(function(error) {
                console.log(error);
            });
    },
    handleClick() {
      // 切换页签 搜索清空page归一
      this.$refs.searchform.resetForm();
      this.page = 1;
      this.getProductList();
    },
    search(e) {
      this.searchVal = e;
      this.page = 1;
      console.log(e);
      this.getProductList();
    },
    reset() {
      this.page = 1;
      this.searchVal = {};
      this.getProductList();
    },
    add(e) {
      this.id = "";
      this.popTitle = "添加";
      this.dialogState = true;
      this.isBased = true;
    },
    // 编辑
    edit(e) {
      this.id = e;
      console.log(e);
      this.popTitle = "编辑";
      this.dialogState = true;
      this.isBased = true;
    },
    // 设置方案
    scheme(e) {
      this.id = e;
      this.popTitle = "设置方案";
      this.dialogState = true;
      this.isPrecept = true;
    },
    // 设置规则
    precept(e) {
      this.id = e;
      this.popTitle = "设置规则";
      this.dialogState = true;
      this.isRegular = true;
    },
    // 设置佣金
    brokerage(e) {
      this.id = e;
      this.dialogState2 = true;
    },
    // 一键复制
    oneCopy(e) {
      console.log(e);
      let that = this;
      copyProductInfo({ proId: e })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.$message.success(res.msg);
            that.getProductList();
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 每页几条
    sizeChange() {
      console.log(1);
      this.getProductList();
    },
    // 页码变化
    currentChange() {
      this.getProductList();
    },
    // 关闭弹窗
    dialogClose() {
      this.dialogState = false;
      this.isBased = false; //基础规则
      this.isRegular = false;
      this.isPrecept = false;
    },
    // 关闭设置佣金弹窗
    dialogClose2() {
      this.dialogState2 = false;
    },
    //获取列表
    getProductList() {
      let that = this;
      let _data = this.searchVal;
      _data.identi = this.identi;
      _data.page = this.page;
      _data.limit = this.pageSize;
      that.loading = true;
      getProductList(_data)
        .then(function(res) {
          that.loading = false;
          console.log(res);
          if (res.code == 1) {
            that.tableData = res.data.list;
            that.total = res.data.total;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取保司列表
    getCompany() {
      let that = this;
      getCompany()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            res.data.forEach((item, index) => {
              let _obj = { label: "", val: "" };
              _obj.label = item.name;
              _obj.val = item.id;
              that.searcharray[3].option.push(_obj);
            });
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 产品分类
    getProductCate() {
      let that = this;
      let _data = {
        identi: this.identi
      };
      getProductCate(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            res.data.forEach((item, index) => {
              let _obj = { label: "", val: "" };
              _obj.label = item.title;
              _obj.val = item.id;
              that.searcharray[4].option.push(_obj);
            });
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 产品佣金批量设置
    // 产品佣金批量设置抽屉打开
    batchSte() {
      this.setForm.id = 1;
      let that = this;
      that.getAdminLevelList(); //获取等级账户列表
      getProductChannelList({ admin_id: that.admin_id })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.setForm.list = res.data;
            // 重置数据
            that.setForm.list.forEach(element => {
              element.status = 0;
              element.commission_type = 1;
              element.promotion_commission = 0;
              element.step_commission = [];
            });
            // 重置选中人员
            that.adminId = [];
            that.drawer = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 批量设置产品关闭
    closeSetBtn() {
      this.drawer = false;
    },
    // 批量设置产品阶梯添加
    addStep(i) {
      if (this.setForm.list[i].step_commission) {
        this.setForm.list[i].step_commission.push({
          policyCost: "0",
          ratio: "0"
        });
      } else {
        this.setForm.list[i].step_commission = [];
        this.setForm.list[i].step_commission.push({
          policyCost: "0",
          ratio: "0"
        });
      }
    },
    // 批量设置产品阶梯删除
    deleStep(i, e) {
      this.setForm.list[i].step_commission.splice(e, 1);
    },
    //佣金比例设置超过最大佣金
    maxRatio(t, v, b) {
      console.log(v, b);
      if (parseFloat(v) > parseFloat(b)) {
        this.$message.error(t + "佣金比例设置不可以大于最大佣金" + b);
      }
    },
    // 批量设置产品确定
    setOk() {
      let that = this;
      if (that.adminId.length == 0) {
        that.$message.error("请先选择需要批量设置的人员");
        return;
      }
      this.$confirm("确认后内容将自动更改，请确认数据无误！", "确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          that.$refs.setForm.validate(valid => {
            if (valid) {
              if (that.$refs.setFormItem) {
                that.$refs.setFormItem.forEach(element => {
                  element.validate(v => {});
                });
              }
              that.$nextTick(() => {
                let error = document.querySelectorAll(".is-error");
                if (error.length > 0) {
                  that.$nextTick(() => {
                    document.querySelector(".is-error").scrollIntoView();
                  });
                } else {
                  let _data = {};
                  _data.productData = [];
                  _data.adminId = that.adminId;
                  that.setForm.list.forEach(element => {
                    if (element.status == 1) {
                      let _obj = {
                        productId: element.product_id,
                        productCommissionType: element.commission_type,
                        productPromotionCommission:
                          element.promotion_commission,
                        productStepCommission: element.step_commission
                      };
                      _data.productData.push(_obj);
                    }
                  });
                  if (!_data.productData.length > 0) {
                    that.$message.error("请选择开放产品，否则无法批量设置");
                    return;
                  }
                  let loadind = that.$loading({
                    lock: true,
                    text: "Loading",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                  });
                  console.log(_data);
                  changeProductAllChannel(_data)
                    .then(function(res) {
                      console.log(res);
                      loadind.close();
                      if (res.code == 1) {
                        that.$message.success(res.msg);
                        that.closeSetBtn();
                      } else {
                        that.$message.error(res.msg);
                      }
                    })
                    .catch(function(error) {
                      console.log(error);
                    });
                }
              });
            } else {
              that.$message.error("输入异常，请检查输入项");
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 批量设置产品重置
    resetPro() {
      let that = this;
      this.$confirm("重置后，数据将恢复初始数据！", "重置", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          that.batchSte();
        })
        .catch(() => {});
    },
    // 选择级别弹窗
    triggerLevelVisible() {
      let that = this;
      if (that.drawerLevelVisible) {
        that.drawerLevelVisible = false;
      } else {
        that.drawerLevelVisible = true;
        that.adminId.forEach(element => {
          that.drawertableData.forEach(item => {
            if (element == item.id) {
              that.$nextTick(() => {
                that.$refs.drawerTable.toggleRowSelection(item, true);
              });
            }
          });
        });
      }
    },
    // 批量设置产品选择人员
    drawerSelectionChange(e) {
      console.log(e);
      let that = this;
      that.adminId = [];
      e.forEach(element => {
        that.adminId.push(element.id);
      });
    },
    // 批量设置产品使用搜索筛选人员
    tableRowClassName({ row, rowIndex }) {
      if (!row.display) {
        return "displayNone";
      }
    },
    //批量设置产品使用搜索 ---搜索
    rsearchBtn() {
      this.drawertableData.forEach(element => {
        if (
          element.nickname.includes(this.rsearch) ||
          element.username.includes(this.rsearch)
        ) {
          element.display = true;
        } else {
          element.display = false;
        }
      });
    },
    //批量设置产品使用搜索 ---重置
    rsearchResetBtn() {
      this.rsearch = "";
      this.drawertableData.forEach(element => {
        element.display = true;
      });
    },
    //获取等级账户列表
    getAdminLevelList() {
      let that = this;
      getAdminLevelList()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.levelList = res.data;
            that.levelList.forEach(element => {
              element.adminList.forEach(item => {
                that.$set(item, "display", true);
              });
            });
            that.drawerLevel = that.levelList[0].levelName;
            that.drawerLevelId = that.levelList[0].levelId;
            that.changeLevelId(that.drawerLevelId);
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 等级账户选择变化
    changeLevelId(e) {
      console.log(e);
      this.levelList.forEach(element => {
        if (element.levelId == e) {
          this.drawertableData = element.adminList;
          this.drawerLevel = element.levelName;
        }
      });
      this.adminId = [];
    }
  }
};
</script>
<style lang="scss">
.productList {
  padding: 30px;
  background: #fff;
  min-height: 100%;
}
//产品批量佣金设置
.setDrawer {
  .el-drawer__header {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    padding: 25px 25px 0;
    margin-bottom: 0;
    & > span::before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 24px;
      background: #2d8aee;
      border-radius: 0px 10px 10px 0px;
      vertical-align: middle;
      margin-top: -2px;
      margin-right: 15px;
    }
  }
  .el-drawer__body {
    padding: 25px 25px 30px;
    position: relative;
    .drawerLevelBtn {
      width: 270px;
      margin-bottom: 25px;
      cursor: pointer;
      input {
        cursor: pointer;
      }
    }
    .setForm .el-form-item {
      margin-bottom: 0;
      .el-form-item__label,
      .el-form-item__content {
        line-height: 28px;
      }
      .el-input {
        width: 50px;
        input {
          padding: 0 5px;
        }
      }
    }
    table tr th {
      background: #f1f4f8;
    }
    table tr td {
      padding: 8px 0;
      .cell {
        display: block;
        .el-form-item__error {
          display: none;
        }
        .el-button {
          padding-top: 7px;
          padding-bottom: 7px;
          margin-left: 15px;
        }
        .box .el-form {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          align-items: center;
        }
        .box + .box {
          border-top: 1px solid #7eb1e733;
          margin-top: 5px;
          padding-top: 5px;
        }
        .el-radio-group .el-radio {
          margin-right: 20px;
        }
      }
    }
    .btnBox {
      text-align: center;
      padding-top: 25px;
    }
    .supernatant {
      background: #ffffff;
      box-shadow: 0px 2px 15px 0px rgb(9 42 88 / 14%);
      border-radius: 3px;
      position: absolute;
      bottom: 30px;
      width: calc(100% - 50px);
      height: calc(100vh - 150px);
      display: flex;
      justify-content: flex-start;
      z-index: 2;
      .supLeft {
        width: 140px;
        background: #f8f9fb;
        border-radius: 3px 0px 0px 3px;
        padding: 25px;
        .el-radio-group {
          .el-radio {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 48px;
          }
        }
      }
      .supRight {
        width: calc(100% - 140px);
        padding: 24px;
        .supRsearchBox {
          margin-bottom: 25px;
          .el-input {
            width: 270px;
            margin-right: 15px;
          }
        }
        .el-table {
          tr.displayNone {
            display: none;
          }
        }
        .supernatantOk {
          margin-top: 30px;
          float: right;
        }
      }
      & > .el-icon-circle-close {
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px;
        font-size: 30px;
        color: #f56c6c;
        cursor: pointer;
      }
    }
  }
}
</style>
