<template>
  <div class="policyDetails">
    <div class="itemBox">
      <div class="bTit">
        <i class="el-icon-document"></i> 保单信息
        <div class="floatBox" v-if="info">
          <el-button
            type="primary"
            size="small"
            :disabled="info.billFile == null || info.is_have_bill == 0"
            @click="down(info.billFile)"
          >
            发票下载
          </el-button>
          <el-button
            type="primary"
            size="small"
            @click="down(info.policyFile)"
            :disabled="info.policyFile == null"
            >电子保单下载
          </el-button>
        </div>
      </div>
      <div class="conBox">
        <div class="guarantee" v-if="info">
          <div class="top">
            <div
              class="imgbox"
              :style="{ background: status[info.status].color }"
            >
              <i class="iconfont" :class="status[info.status].icon"></i>
              {{ info.statusTxt }}
            </div>
            <div class="center">
              <p>保单号：{{ info.policyNum ? info.policyNum : "暂无" }}</p>
              <div>
                <p>流水号：{{ info.numId ? info.numId : "暂无" }}</p>
                <p>保险公司：{{ info.companyName }}</p>
                <p>提交时间：{{ info.cTime }}</p>
                <p>产品名称：{{ info.plan.productName }}</p>
                <p>保障期限：{{ info.sTime }} - {{ info.eTime }}</p>
                <p>提交人：{{ info.adminName }}</p>
              </div>
            </div>
            <p class="premium">
              保费：<span>{{ info.policyMoney }}</span>
            </p>
          </div>
          <div class="bottom">
            <p>方案详情：</p>
            <div>
              <span>职业类别：{{ info.workCate }}</span>
              <span>{{ info.plan.death_quota }}</span>
              <span>{{ info.plan.medical_quota }}</span>
              <span>{{ info.plan.loss_work_cost }}</span>
              <span>{{ info.plan.hospital_allowance }}</span>
              <span>{{ info.plan.legal_fees }}</span>
              <span>
                扩展信息：{{ info.plan.disabRatio }} {{ info.plan.isAllDays }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="pType != 3">
      <div class="itemBox">
        <div class="bTit"><i class="el-icon-document"></i>投保人信息</div>
        <div class="conBox">
          <div class="conBoxFlex" v-if="insured">
            <div class="left">
              <p>企业名称：{{ insured.application.name }}</p>
              <p>社会信用代码：{{ insured.application.code }}</p>
              <p>联系人：{{ insured.application.legal_person }}</p>
              <p>联系方式：{{ insured.application.phone }}</p>
              <p>
                地址：{{ insured.application.provinceName }}
                {{ insured.application.cityName }}
                {{ insured.application.areaName }}
                {{ insured.application.address }}
              </p>
            </div>
            <div class="right">
              <span>营业执照：</span>
              <el-image
                style="width: 100px; height: 100px"
                :src="insured.application.code_img"
                :preview-src-list="[insured.application.code_img]"
              >
              </el-image>
            </div>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="bTit"><i class="el-icon-document"></i>被保人信息</div>
        <div class="conBox">
          <div class="conBoxFlex" v-if="insured">
            <div class="left">
              <p>名称：{{ insured.insure.name }}</p>
              <p>证件号：{{ insured.insure.code }}</p>
              <p>联系人：{{ insured.insure.legal_person }}</p>
              <p>联系方式：{{ insured.insure.phone }}</p>
              <p>
                地址：{{ insured.insure.provinceName }}
                {{ insured.insure.cityName }} {{ insured.insure.areaName }}
                {{ insured.insure.address }}
              </p>
            </div>
            <div class="right">
              <span>营业执照：</span>
              <el-image
                style="width: 100px; height: 100px"
                :src="insured.insure.code_img"
                :preview-src-list="[insured.insure.code_img]"
              >
              </el-image>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="itemBox">
        <div class="bTit"><i class="el-icon-document"></i>投保人信息</div>
        <div class="conBox">
          <div class="conBoxFlex" v-if="carInsured">
            <div class="left">
              <p>名称：{{ carInsured.application.name }}</p>
              <p>证件号：{{ carInsured.application.code }}</p>
              <p>联系人：{{ carInsured.application.legal_person }}</p>
              <p>联系方式：{{ carInsured.application.phone }}</p>
              <p>
                地址：{{ carInsured.application.provinceName }}
                {{ carInsured.application.cityName }}
                {{ carInsured.application.areaName }}
                {{ carInsured.application.address }}
              </p>
              <p v-if="carInsured.application.affiliation_agreement">
                挂靠协议：<span
                  @click="down(carInsured.application.affiliation_agreement)"
                  style="cursor: pointer;color: red;"
                >
                  文件
                </span>
              </p>
            </div>
            <div class="right">
              <span>证件照片：</span>
              <el-image
                style="width: 100px; height: 100px"
                :src="carInsured.application.codeImg[0]"
                :preview-src-list="carInsured.application.codeImg"
              >
              </el-image>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="itemBox">
      <div class="bTit"><i class="el-icon-document"></i>缴费信息</div>
      <div class="conBox">
        <div class="conBoxFlex" v-if="info">
          <div class="left">
            <p>账户：{{ info.payVoucher.bank_no }}</p>
            <p>开户行：{{ info.payVoucher.bank_title }}</p>
            <p>账户名：{{ info.payVoucher.bank_name }}</p>
            <p>实际姓名：{{ info.payVoucher.payName }}</p>
            <p>打款时间：{{ info.payVoucher.payTime }}</p>
            <p>
              保费：<span class="price">{{ info.policyMoney }}</span>
            </p>
          </div>
          <div class="right">
            <span>缴费凭证扫描件：</span>
            <el-image
              style="width: 100px; height: 100px;margin-right:5px"
              :src="item"
              :preview-src-list="info.payVoucher.payVoucher"
              v-for="(item, index) in info.payVoucher.payVoucher"
              :key="index"
            >
            </el-image>
          </div>
        </div>
      </div>
    </div>
    <div class="itemBox">
      <div class="bTit"><i class="el-icon-document"></i>开票信息</div>
      <div class="conBox">
        <div class="conBoxFlex" v-if="invoice">
          <div class="left">
            <p v-if="info.is_have_bill == 0">
              是否需要开票：{{ info.is_have_bill == 0 ? "否" : "" }}
            </p>
            <template v-else>
              <p>
                发票类型：{{
                  invoice.type == 1 ? "增值税普通发票" : "增值税专用发票"
                }}
              </p>
              <p>纳税人名称：{{ invoice.taxpayer_name }}</p>
              <p>纳税人识别号：{{ invoice.taxpayer_id }}</p>
              <template v-if="invoice.type == 2">
                <p>税务登记地址：{{ invoice.taxpayer_addr }}</p>
                <p>税务登记联系电话：{{ invoice.taxpayer_tel }}</p>
                <p>税务开户银行名称：{{ invoice.taxpayer_bank }}</p>
                <p>税务开户银行账号：{{ invoice.taxpayer_bankno }}</p>
                <p>
                  收件人：{{ invoice.new_contact_name }}； 联系电话：
                  {{ invoice.invoice_phone }}； 收件地址：
                  {{ invoice.address }}；
                </p>
              </template>
            </template>
          </div>
          <div class="right" v-if="invoice.type == 2">
            <span>一般纳税人证明：</span>
            <el-image
              style="width: 100px; height: 100px"
              :src="invoice.ext_info"
              :preview-src-list="[invoice.ext_info]"
            >
            </el-image>
          </div>
        </div>
      </div>
    </div>
    <div class="itemBox" v-if="info.status != 8">
      <template v-if="pType != 3">
        <div class="bTit"><i class="el-icon-document"></i>人员清单</div>
        <div class="conBox">
          <el-tabs v-model="type" type="card" @tab-click="personnelClick">
            <el-tab-pane label="在保人员" name="1"></el-tab-pane>
            <el-tab-pane label="过保人员" name="2"></el-tab-pane>
            <el-tab-pane label="待生效人员" name="3"></el-tab-pane>
          </el-tabs>
          <el-table :data="tableData" style="width: 100%" size="mini">
            <el-table-column
              type="index"
              label="序号"
              width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="name" label="姓名" align="center">
            </el-table-column>
            <el-table-column prop="code" label="身份证号" align="center">
            </el-table-column>
            <el-table-column
              prop="work_cate_title"
              label="工作类型"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="money"
              label="保费金额"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column label="保障期限" align="center">
                <template slot-scope="scope">
                  {{ scope.row.bzqx }} 个月
                </template>
            </el-table-column>
            <el-table-column label="保障期限" align="center">
              <template slot-scope="scope">
                {{ scope.row.s_time }} -- {{ scope.row.e_time }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <!-- 车险清单 -->
      <template v-else>
        <div class="bTit"><i class="el-icon-document"></i>车辆清单</div>

        <div class="conBox">
          <el-table
            :data="carInsured.carList"
            border
            stripe
            style="width: 100%"
            size="mini"
            id="exportTab"
          >
            <el-table-column type="index" label="序号" width="80">
            </el-table-column>
            <el-table-column prop="carNum" label="车牌号" min-width="90">
            </el-table-column>
            <el-table-column prop="carCode" label="车辆识别"> </el-table-column>
            <el-table-column prop="engineNumber" label="发动机号">
            </el-table-column>
            <el-table-column
              prop="drivingLicenseFrontPage"
              label="行驶证正页"
              width="150"
            >
              <template slot-scope="scope">
                <el-image
                  :src="scope.row.drivingLicenseFrontPage"
                  :preview-src-list="[scope.row.drivingLicenseFrontPage]"
                  style="width: 50px;height: 50px;"
                >
                </el-image>
              </template>
            </el-table-column>
            <el-table-column
              prop="drivingLicenseSubPage"
              label="行驶证副页"
              width="150"
            >
              <template slot-scope="scope">
                <el-image
                  style="width: 50px;height: 50px;"
                  :src="scope.row.drivingLicenseSubPage"
                  :preview-src-list="[scope.row.drivingLicenseSubPage]"
                >
                </el-image>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </div>
    <div class="itemBox">
      <div class="bTit"><i class="el-icon-document"></i>其他投保文件</div>
      <div class="conBox">
        <p class="file">
          <span @click="down(info.insureFile)" v-if="info.insureFile">
            文件
          </span>
        </p>
      </div>
    </div>
    <div class="itemBox">
      <div class="bTit"><i class="el-icon-document"></i>操作日志</div>
      <div class="conBox">
        <el-table :data="Log" border style="width: 100%" size="mini">
          <el-table-column prop="createtime" label="操作时间" align="center">
          </el-table-column>
          <el-table-column prop="username" label="操作员" align="center">
          </el-table-column>
          <el-table-column prop="title" label="操作内容" align="center">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getOrderInfo,
  getOrderInsured,
  getCarOrderInsured,
  getOrderInvoice,
  getOrderPersonnel,
  getOperationLog
} from "@/api/audit";
import download from "@/utils/download";
export default {
  name: "guaDdetails",
  data() {
    return {
      status: [],
      numId: "",
      info: "", //订单详情
      insured: "", //投、被保人
      pType: "", //产品类型
      carInsured: "", //车险投保信息
      invoice: "", //开票信息
      tableData: [], //人员清单
      Log: [],
      type: "1", //保障状态：1 在保 2 过保 3 待生效
      form: {
        type: "1",
        desc: ""
      },
      form2: {
        policyNum: "",
        policyFile: [],
        billFile: []
      }
    };
  },
  created() {
    this.status = this.$store.state.orderStatus;
    this.numId = this.$route.query.numId;
    this.pType = this.$route.query.type;
    this.getOrderInfo(); //获取订单详情
    this.getOrderInvoice(); //开票信息
    this.getOrderPersonnel(); //保障人员
    this.getOperationLog(); //操作记录接口
    if (this.pType == 3) {
      this.getCarOrderInsured();
    } else {
      this.getOrderInsured(); //获取投、被保人
    }
  },
  methods: {
    //获取订单详情
    getOrderInfo() {
      let that = this;
      getOrderInfo({ numId: this.numId })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.info = res.data;
            that.form2 = {
              policyNum: res.data.policyNum,
              policyFile: [],
              billFile: []
            };
            if (res.data.policyFile) {
              that.form2.policyFile[0] = {
                url: res.data.policyFile,
                name: "电子保单"
              };
            }
            if (res.data.billFile) {
              that.form2.billFile[0] = {
                url: res.data.billFile,
                name: "发票信息"
              };
            }
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }, //获取投、被保人
    getOrderInsured() {
      let that = this;
      getOrderInsured({ numId: this.numId })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.insured = res.data;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    //车辆投保时获取投信息
    getCarOrderInsured() {
      let that = this;
      getCarOrderInsured({ numId: this.numId })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.carInsured = res.data;
            that.carInsured.carList[0].drivingLicenseFrontPage =
              "http://api.ruilubao.cn/uploads/BusinessLicense/20220901/7ce8956d7417e4e175c13dab1f8d78a5.jpg";
            that.carInsured.carList[1].drivingLicenseFrontPage =
              "http://api.ruilubao.cn/uploads/BusinessLicense/20220901/7ce8956d7417e4e175c13dab1f8d78a5.jpg";
            that.carInsured.carList[0].drivingLicenseSubPage =
              "http://api.ruilubao.cn/uploads/BusinessLicense/20220901/7ce8956d7417e4e175c13dab1f8d78a5.jpg";
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }, //获取开票信息
    getOrderInvoice() {
      let that = this;
      getOrderInvoice({ numId: this.numId })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.invoice = res.data;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }, //获取保障人员
    getOrderPersonnel() {
      let that = this;
      getOrderPersonnel({ numId: this.numId, type: this.type })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.tableData = res.data;
            // 加入保障期限
            that.tableData.forEach(item=>{
                item.bzqx = that.timeDiffMonth(item.s_time,item.e_time);
                // console.log(item)
            })
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    timeDiffMonth(d1,d2) {
		//如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
		var dateBegin = new Date(d1.replace(/-/g, "/")); //将-转化为/，使用new Date
		var dateEnd = new Date(d2.replace(/-/g, "/")); // 结束时间
        var bzqx = Math.abs((dateEnd.getFullYear() * 12 + dateEnd.getMonth()) - (dateBegin.getFullYear() * 12 + dateBegin.getMonth()));
        if((dateEnd.getDate() - dateBegin.getDate()) > 0){
            bzqx += 1;
        }
        return bzqx;
	},
    // 操作记录
    getOperationLog() {
      let that = this;
      getOperationLog({ numId: this.numId })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.Log = res.data;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 人员类型切换
    personnelClick() {
      this.getOrderPersonnel();
    },
    // 下载
    down(downurl) {
      download(downurl);
    }
  }
};
</script>
<style lang="scss">
.policyDetails {
  .itemBox:nth-child(1) {
    margin: 0;
  }
  .itemBox {
    background: #fff;
    margin-top: 15px;
    .bTit {
      font-size: 16px;
      color: #666;
      padding: 20px;
      margin: 0;
      border-bottom: 1px solid #eee;
      i {
        margin-right: 5px;
        background: #4f8bf0;
        padding: 5px;
        border-radius: 50%;
        color: #fff;
        font-size: 12px;
      }
      .floatBox {
        float: right;
        margin-top: -4px;
      }
    }
    .conBox {
      padding: 20px 45px;
      p {
        margin: 0 0 15px;
        font-size: 12px;
        color: #333;
      }
      .guarantee {
        .top {
          display: flex;
          justify-content: space-between;
          .imgbox {
            width: 80px;
            height: 80px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            border-radius: 5px;
            color: #fff;
            font-size: 14px;
            i {
              font-size: 30px;
            }
          }
          .center {
            flex: 1;
            padding-left: 20px;
            & > p {
              font-weight: 600;
              font-size: 20px;
              margin: 0;
            }
            & > div {
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              p {
                width: 33%;
                padding-right: 15px;
                margin: 8px 0 0;
                color: #999;
                font-size: 13px;
              }
            }
          }
          .premium {
            font-weight: 600;
            color: #333;
            font-size: 12px;
            margin: 55px 0 0;
            span {
              font-style: normal;
              color: #dc3545;
              font-size: 19px;
            }
          }
        }
        .bottom {
          padding: 20px 30px;
          background: #f5f5f5;
          border-radius: 8px;
          margin-top: 25px;
          position: relative;
          & > p {
            font-weight: 600;
            font-size: 14px;
            margin: 0 0 15px;
          }
          & > div {
            font-size: 12px;
            color: #333;
            span {
              display: inline-block;
              margin-right: 20px;
            }
          }
        }
        .bottom:before {
          content: "";
          width: 10px;
          height: 10px;
          background: #f5f5f5;
          display: block;
          position: absolute;
          top: -5px;
          left: 50px;
          transform: rotate(45deg);
        }
      }
      .conBoxFlex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .left {
          min-width: 550px;
          margin-right: 20px;
          padding-right: 20px;
          border-right: 1px solid #eee;
          .price {
            color: #dc3545;
            font-size: 19px;
          }
          .link {
            color: #5b8cff;
            cursor: pointer;
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          span {
            font-size: 12px;
            color: #333;
          }
        }
      }
      .file {
        margin: 0 0 15px;
        font-size: 12px;
        color: #333;
        span {
          color: #5b8cff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
