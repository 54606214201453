<template>
  <div class="workDetails">
    <div class="dtit">
      {{ work_library }}
    </div>
    <el-button type="primary" size="small" icon="el-icon-plus" @click="add()">
      添加职业类别
    </el-button>
    <!-- <el-button
      type="primary"
      size="small"
      icon="el-icon-upload2"
      @click="bulkImport()"
    >
      批量导入
    </el-button> -->
    <el-upload
      class="upload-demo"
      action=""
      accept=".xlsx,.xls,.csv"
      :http-request="bulkImport"
      :show-file-list="false"
      multiple
    >
      <el-button size="small" type="primary"
        ><i class="el-icon-upload2"></i> 批量导入</el-button
      >
    </el-upload>
    <el-button
      type="primary"
      size="small"
      icon="el-icon-download"
      @click="down('/template/workType.xlsx')"
    >
      模板下载
    </el-button>
    <el-checkbox-group v-model="multiple" size="small">
      <el-checkbox-button :label="true">
        批量操作
      </el-checkbox-button>
    </el-checkbox-group>
    <transition name="fade-transform" mode="out-in">
      <el-form
        inline
        class="demo-form-inline"
        label-width="180px"
        v-if="multiple"
        size="mini"
      >
        <el-form-item label="批量设置是否需要保前审核">
          <el-select
            filterable
            v-model="batchAudit"
            placeholder="请选择"
            @change="changeAudit"
            style="width: 100px;"
          >
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="批量设置是否启用">
          <el-select
            filterable
            v-model="batchUse"
            placeholder="请选择"
            @change="changeUse"
            style="width: 100px;"
          >
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="批量设置是否含高空">
          <el-select
            filterable
            v-model="isHeight"
            placeholder="请选择"
            @change="changeIsHeight"
            style="width: 100px;"
          >
            <el-option label="包含" :value="1"></el-option>
            <el-option label="不含" :value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </transition>
    <div class="tableBox">
      <div class="left">
        <el-table
          :data="tableData"
          style="width: 100%"
          row-key="id"
          border
          stripe
          height="calc(100vh - 220px)"
          lazy
          size="small"
        >
          <el-table-column label="职业类别表" align="center">
            <el-table-column prop="id" label="ID" align="center" width="60">
            </el-table-column>
            <el-table-column prop="title" align="center" label="职业类别">
            </el-table-column>
            <el-table-column
              prop="remark"
              align="center"
              label="状态"
              width="110"
            >
              <template slot-scope="scope">
                <el-tag type="success" size="mini" v-if="scope.row.status == 1">
                  启用
                </el-tag>
                <el-tag type="danger" size="mini" v-else>禁用</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  @click="leftEdit(scope.row.id)"
                >
                  编辑
                </el-button>
                <el-button
                  type="text"
                  size="mini"
                  @click="leftLook(scope.row.id, scope.row.title)"
                >
                  查看
                </el-button>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <div class="right">
        <el-table
          :data="tableData2"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          :span-method="objectSpanMethod"
          style="width: 100%"
          row-key="$index"
          border
          lazy
          height="calc(100vh - 220px)"
          size="small"
          ref="rghtTable"
        >
          <el-table-column :label="work_kind" align="center">
            <el-table-column align="center" label="大分类">
              <template slot-scope="scope">
                <p>{{ scope.row.title }}</p>
                <p v-if="scope.row.code">{{ scope.row.code }}</p>
                <i class="el-icon-edit" @click="rightEdit(scope.row.id, 1)"></i>
              </template>
            </el-table-column>
            <el-table-column align="center" label="中分类">
              <template slot-scope="scope">
                <p>{{ scope.row.title2 }}</p>
                <p v-if="scope.row.code2">{{ scope.row.code2 }}</p>
                <i
                  v-if="scope.row.title2"
                  class="el-icon-edit"
                  @click="rightEdit(scope.row.id2, 2)"
                ></i>
              </template>
            </el-table-column>
            <el-table-column align="center" label="小分类">
              <template slot-scope="scope">
                <p>{{ scope.row.title3 }}</p>
                <p v-if="scope.row.code3">{{ scope.row.code3 }}</p>
                <i
                  v-if="scope.row.title3"
                  class="el-icon-edit"
                  @click="rightEdit(scope.row.id3, 3)"
                ></i>
              </template>
            </el-table-column>
            <el-table-column align="center" label="细分类">
              <template slot-scope="scope">
                <p>{{ scope.row.title4 }}</p>
                <p v-if="scope.row.code4">{{ scope.row.code4 }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="grade" align="center" label="职业等级">
              <template slot-scope="scope">
                {{ typeList[scope.row.grade] }}
              </template>
            </el-table-column>
            <el-table-column
              prop="is_high"
              align="center"
              label="是否含高空"
              width="130"
            >
              <template slot-scope="scope">
                <el-tag
                  type="success"
                  size="mini"
                  v-if="scope.row.is_high == 1"
                >
                  包含
                </el-tag>
                <el-tag
                  type="danger"
                  size="mini"
                  v-else-if="scope.row.is_high == 0"
                  >不含</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="is_insure_before_check"
              align="center"
              label="是否需要保前审核"
              width="130"
            >
              <template slot-scope="scope">
                <el-tag
                  type="success"
                  size="mini"
                  v-if="scope.row.is_insure_before_check == 1"
                >
                  是
                </el-tag>
                <el-tag
                  type="danger"
                  size="mini"
                  v-else-if="scope.row.is_insure_before_check == 0"
                  >否</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="remark"
              align="center"
              label="是否启用"
              width="110"
            >
              <template slot-scope="scope">
                <el-tag type="success" size="mini" v-if="scope.row.status == 1">
                  启用
                </el-tag>
                <el-tag
                  type="danger"
                  size="mini"
                  v-else-if="scope.row.status == 0"
                  >禁用</el-tag
                >
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <el-table-column label="操作" align="center">
              <template slot="header">
                <el-button type="primary" size="mini" @click="rightAdd()">
                  添加工种
                </el-button>
              </template>
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  @click="rightEdit(scope.row.id4, 4)"
                >
                  编辑
                </el-button>
              </template>
            </el-table-column></el-table-column
          >
        </el-table>
      </div>
    </div>
    <!--弹窗 -->
    <div class="jobPop">
      <el-dialog
        :title="popTitle"
        :visible.sync="dialogState"
        width="540px"
        custom-class="jobPop"
        @close="dialogClose"
      >
        <el-form
          :model="jobForm"
          :rules="rules"
          :validate-on-rule-change="false"
          ref="jobForm"
          label-width="130px"
          class="jobForm"
          size="small"
        >
          <el-form-item label="职业类别名称" prop="title">
            <el-input
              v-model="jobForm.title"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="职业类别状态" prop="status">
            <el-radio v-model="jobForm.status" :label="1">启用</el-radio>
            <el-radio v-model="jobForm.status" :label="0">禁用</el-radio>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="dialogOk()">
            确 定
          </el-button>
          <el-button @click="dialogClose()" size="small">取 消</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="kindForm">
      <el-dialog
        :title="popTitle2"
        :visible.sync="dialogState2"
        width="540px"
        custom-class="jobPop"
        @close="dialogClose2"
      >
        <el-form
          :model="kindForm"
          :rules="rules2"
          :validate-on-rule-change="false"
          ref="kindForm"
          label-width="130px"
          class="kindForm"
          size="small"
        >
          <el-form-item label="添加的级别" prop="level">
            <el-select
              filterable
              v-model="kindForm.level"
              placeholder="请选择"
              style="width:100%"
              :disabled="disabled"
              @change="changeLevel"
            >
              <el-option label="大分类" :value="1"> </el-option>
              <el-option label="中分类" :value="2"> </el-option>
              <el-option label="小分类" :value="3"> </el-option>
              <el-option label="细分类" :value="4"> </el-option>
            </el-select>
          </el-form-item>
          <template v-if="kindForm.level == 1">
            <el-form-item label="大分类名称" prop="title">
              <el-input
                v-model="kindForm.title"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="大分类编码" prop="code">
              <el-input
                v-model="kindForm.code"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </template>
          <template v-else-if="kindForm.level == 2">
            <el-form-item label="父级" prop="pid">
              <el-select
                v-model="kindForm.pid"
                placeholder="请选择"
                style="width:100%"
                filterable
              >
                <el-option
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="中分类名称" prop="title">
              <el-input
                v-model="kindForm.title"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="中分类编码" prop="code">
              <el-input
                v-model="kindForm.code"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </template>
          <template v-else-if="kindForm.level == 3">
            <el-form-item label="父级" prop="pid">
              <el-cascader
                v-model="kindForm.pidArray"
                :options="options"
                @change="setPid"
                :props="{ children: 'child', label: 'title', value: 'id' }"
                filterable
                style="width:100%"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="小分类名称" prop="title">
              <el-input
                v-model="kindForm.title"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="小分类编码" prop="code">
              <el-input
                v-model="kindForm.code"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="父级" prop="pid">
              <el-cascader
                v-model="kindForm.pidArray"
                :options="options"
                @change="setPid"
                :props="{ children: 'child', label: 'title', value: 'id' }"
                filterable
                style="width:100%"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="细分类名称" prop="title">
              <el-input
                v-model="kindForm.title"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="细分类编码" prop="code">
              <el-input
                v-model="kindForm.code"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="职业等级" prop="grade">
              <el-select
                filterable
                v-model="kindForm.grade"
                placeholder="请选择"
                style="width:100%"
              >
                <el-option
                  v-for="(item, index) in typeList"
                  :key="index"
                  :label="item"
                  :value="parseInt(index)"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否包含高空" prop="is_high">
              <el-radio v-model="kindForm.is_high" :label="1">包含</el-radio>
              <el-radio v-model="kindForm.is_high" :label="0">不含</el-radio>
            </el-form-item>
            <el-form-item
              label="是否需要保前审核"
              prop="is_insure_before_check"
            >
              <el-radio v-model="kindForm.is_insure_before_check" :label="1"
                >是</el-radio
              >
              <el-radio v-model="kindForm.is_insure_before_check" :label="0"
                >否</el-radio
              >
            </el-form-item>
            <el-form-item label="是否启用" prop="status">
              <el-radio v-model="kindForm.status" :label="1">启用</el-radio>
              <el-radio v-model="kindForm.status" :label="0">禁用</el-radio>
            </el-form-item>
          </template>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="dialogOk2()">
            确 定
          </el-button>
          <el-button @click="dialogClose2()" size="small">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  changeWorkCategory,
  getWorkCategoryList,
  getWorkCategoryInfo,
  changeWorkType,
  getWorkTypeList,
  getWorkType,
  uploadWorkType,
  changeAllWorkType
} from "@/api/productAdmin";
import download from "@/utils/download";
export default {
  name: "workDetails",
  data() {
    return {
      lib_id: "",
      company_id: "",
      // 当前选中的职业类别
      cate_id: "",
      // 大标题
      work_library: "",
      popTitle: "添加",
      dialogState: false,
      jobForm: { id: "", title: "", status: 1 },
      // 工种表标题
      work_kind: "工种表",
      popTitle2: "添加",
      dialogState2: false,
      // 添加工种下拉数据源
      options: [],
      // 编辑时限制
      disabled: false,
      // 类别类别数据源
      typeList: {
        1: "一类",
        2: "二类",
        3: "三类",
        4: "四类",
        5: "五类",
        6: "六类",
        7: "七类",
        8: "八类",
        9: "九类",
        10: "十类",
        11: "十一类",
        12: "十二类",
        13: "十三类",
        14: "十四类",
        15: "十五类",
        16: "十六类",
        17: "十七类",
        18: "十八类",
        19: "十九类",
        20: "二十类"
      },

      kindForm: {
        id: "",
        level: 1,
        pidArray: "",
        pid: "",
        title: "",
        code: "",
        grade: 1,
        is_high: 0,
        is_insure_before_check: 0,
        status: 1
      },
      rules: {
        title: [
          { required: true, message: "职业类别名称不能为空", trigger: "blur" }
        ]
      },
      rules2: {
        title: [
          { required: true, message: "分类名称不能为空", trigger: "blur" }
        ],
        // code: [
        //   { required: true, message: "分类编码不能为空", trigger: "blur" }
        // ],
        pid: [{ required: true, message: "上级分类不能为空", trigger: "blur" }],
        grade: [
          { required: true, message: "工种等级不能为空", trigger: "blur" }
        ]
      },
      tableData: [],
      loading: false,
      tableData2: [],
      // 是否批量操作
      multiple: false,
      // 批量设置保前审核
      batchAudit: "",
      // 批量设置保前审核
      batchUse: "",
      // 批量设置是否含高空
      isHeight: "",
      spanArr: [],
      pos: "",
      spanArr2: [],
      pos2: "",
      spanArr3: [],
      pos3: ""
    };
  },
  mounted() {
    this.lib_id = this.$route.query.lib_id;
    this.company_id = this.$route.query.company_id;
    // 加载职业类别表
    this.getWorkCategoryList();
  },
  methods: {
    // 添加职业类别
    add() {
      this.jobForm = {
        id: "",
        title: "",
        status: 1
      };
      this.popTitle = "添加";
      this.dialogState = true;
    },
    // 批量导入
    bulkImport(e) {
      let that = this;
      let _form = new FormData();
      console.log(e);
      _form.append("file", e.file);
      _form.append("lib_id", that.lib_id);
      _form.append("company_id", that.company_id);
      console.log(_form.get("file"));
      let loadind = that.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      uploadWorkType(_form)
        .then(function(res) {
          console.log(res);
          loadind.close();
          if (res.code == 1) {
            that.getWorkCategoryList();
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          that.$message.error(error.msg);
          console.log(error);
        });
    },

    // 模板下载
    down(downurl) {
      download(downurl);
    },
    // 左侧编辑
    leftEdit(e) {
      let that = this;
      getWorkCategoryInfo({ id: e })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.popTitle = "编辑";
            that.jobForm = {
              id: res.data.id,
              title: res.data.title,
              status: res.data.status
            };
            that.dialogState = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 左侧查看
    leftLook(e, t) {
      console.log(e);
      this.cate_id = e;
      this.work_kind = t;
      // 批量设置保前审核清除选择
      this.batchAudit = "";
      // 批量设置保前审核清除选择
      this.batchUse = "";
      // 批量设置是否高空清除选择
      this.isHeight = "";
      this.getWorkTypeList();
    },
    // 批量修改保前审核
    changeAudit(e) {
      console.log(e);
      let that = this;
      this.$confirm("此操作将批量修改保前审核, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let _data = { cate_id: that.cate_id, beforeCheck: e };
          that.changeAllWorkType(_data);
        })
        .catch(() => {
          that.batchAudit = "";
        });
    },
    // 批量修改是否启用
    changeUse(e) {
      console.log(e);
      let that = this;
      this.$confirm("此操作将批量修改启用状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let _data = { cate_id: that.cate_id, status: e };
          that.changeAllWorkType(_data);
        })
        .catch(() => {
          that.batchUse = "";
        });
    },
    // 批量修改是否含高空
    changeIsHeight(e) {
      console.log(e);
      let that = this;
      this.$confirm("此操作将批量修改是否含高空, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let _data = { cate_id: that.cate_id, isHeight: e };
          that.changeAllWorkType(_data);
        })
        .catch(() => {
          that.isHeight = "";
        });
    },
    // 批量更新保前审核、工种状态接口
    changeAllWorkType(data) {
      let that = this;
      let loadind = that.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      changeAllWorkType(data)
        .then(function(res) {
          console.log(res);
          loadind.close();
          if (res.code == 1) {
            that.$message.success(res.msg);
            that.getWorkTypeList();
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 弹窗确定
    dialogOk() {
      this.$refs.jobForm.validate(valid => {
        let that = this;
        if (valid) {
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          let _data = that.jobForm;
          _data.company_id = that.company_id;
          _data.lib_id = that.lib_id;
          changeWorkCategory(_data)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.getWorkCategoryList();
                that.dialogClose();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭取消
    dialogClose() {
      this.$nextTick(() => {
        this.$refs.jobForm.resetFields();
      });
      this.dialogState = false;
    },
    // 获取职业类别表
    getWorkCategoryList() {
      let that = this;
      getWorkCategoryList({ lib_id: that.lib_id })
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.tableData = res.data.list;
            that.work_library = res.data.work_library;
            if (res.data.list.length > 0) {
              that.cate_id = res.data.list[0].id;
              that.work_kind = res.data.list[0].title;
              that.getWorkTypeList();
            }
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 右侧打开弹窗
    rightAdd() {
      this.disabled = false;
      this.kindForm = {
        id: "",
        level: 1,
        pidArray: "",
        pid: "",
        title: "",
        code: "",
        grade: 1,
        is_high: 0,
        is_insure_before_check: 0,
        status: 1
      };
      this.popTitle2 = "添加";
      this.dialogState2 = true;
    },
    //修改级别时触发
    changeLevel(e) {
      this.getWorkTypeList(--e);
    },
    // 处理右侧弹窗父级数据
    setPid(e) {
      e.forEach(element => {
        this.kindForm.pid = element;
      });
    },
    // 右侧编辑
    rightEdit(e, l) {
      console.log(e);
      let that = this;
      that.disabled = true;
      let _data = {
        id: e,
        level: l
      };
      this.getWorkTypeList(--l);
      getWorkType(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.popTitle2 = "编辑";
            that.kindForm = res.data;
            that.kindForm.pidArray = res.data.pidArr;
            that.kindForm.id = e;
            that.company_id = res.data.company_id;
            that.cate_id = res.data.cate_id;
            that.dialogState2 = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 弹窗确定2
    dialogOk2() {
      this.$refs.kindForm.validate(valid => {
        let that = this;
        if (valid) {
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          let _data = that.kindForm;
          _data.company_id = that.company_id;
          _data.cate_id = that.cate_id;
          if (_data.level == 1) {
            _data.pid = 0;
          }
          changeWorkType(_data)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.getWorkTypeList();
                that.dialogClose2();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭取消2
    dialogClose2() {
      this.$nextTick(() => {
        this.$refs.kindForm.resetFields();
      });
      this.dialogState2 = false;
    },
    // 获取工种表数据
    getWorkTypeList(level) {
      let that = this;
      let _data = {
        cate_id: that.cate_id
      };
      if (level) {
        _data.level = level;
      } else {
        that.loading = true;
      }
      getWorkTypeList(_data)
        .then(function(res) {
          console.log(res);
          that.loading = false;
          if (res.code == 1) {
            if (level) {
              that.options = res.data;
            } else {
              that.tableData2 = that.getTreeTable(res.data);
            }
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 递归处理工种表数据
    getTreeTable(data) {
      var tableData = [];
      data.forEach(item => {
        if (item.child) {
          item.child.forEach(item2 => {
            if (item2.child) {
              item2.child.forEach(item3 => {
                if (item3.child) {
                  item3.child.forEach(item4 => {
                    let _obj = {};
                    _obj.title = item.title;
                    _obj.code = item.code;
                    _obj.id = item.id;
                    _obj.title2 = item2.title;
                    _obj.code2 = item2.code;
                    _obj.id2 = item2.id;
                    _obj.title3 = item3.title;
                    _obj.code3 = item3.code;
                    _obj.id3 = item3.id;
                    _obj.title4 = item4.title;
                    _obj.code4 = item4.code;
                    _obj.id4 = item4.id;
                    _obj.status = item4.status;
                    _obj.grade = item4.grade;
                    _obj.is_high = item4.is_high;
                    _obj.is_insure_before_check = item4.is_insure_before_check;
                    tableData.push(_obj);
                  });
                } else {
                  let _obj = {};
                  _obj.title = item.title;
                  _obj.code = item.code;
                  _obj.id = item.id;
                  _obj.title2 = item2.title;
                  _obj.code2 = item2.code;
                  _obj.id2 = item2.id;
                  _obj.title3 = item3.title;
                  _obj.code3 = item3.code;
                  _obj.id3 = item3.id;
                  tableData.push(_obj);
                }
              });
            } else {
              let _obj = {};
              _obj.title = item.title;
              _obj.code = item.code;
              _obj.id = item.id;
              _obj.title2 = item2.title;
              _obj.code2 = item2.code;
              _obj.id2 = item2.id;
              tableData.push(_obj);
            }
          });
        } else {
          let _obj = {};
          _obj.title = item.title;
          _obj.code = item.code;
          _obj.id = item.id;
          tableData.push(_obj);
        }
      });

      this.getSpanArr(tableData);
      return tableData;
    },
    // 合并行
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        //用于设置要合并的列
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
      if (columnIndex === 1) {
        //用于设置要合并的列
        const _row = this.spanArr2[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
      if (columnIndex === 2) {
        //用于设置要合并的列
        const _row = this.spanArr3[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    },
    // 处理合并
    getSpanArr(data) {
      //
      this.spanArr = [];
      this.pos = "";
      this.spanArr2 = [];
      this.pos2 = "";
      this.spanArr3 = [];
      this.pos3 = "";
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1);
          this.pos = 0;
          this.spanArr2.push(1);
          this.pos2 = 0;
          this.spanArr3.push(1);
          this.pos3 = 0;
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i].title === data[i - 1].title) {
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            this.pos = i;
          }
          if (data[i].title2 === data[i - 1].title2) {
            this.spanArr2[this.pos2] += 1;
            this.spanArr2.push(0);
          } else {
            this.spanArr2.push(1);
            this.pos2 = i;
          }
          if (data[i].title3 === data[i - 1].title3) {
            this.spanArr3[this.pos3] += 1;
            this.spanArr3.push(0);
          } else {
            this.spanArr3.push(1);
            this.pos3 = i;
          }
        }
      }
    }
  }
};
</script>
<style lang="scss">
.workDetails {
  background: #fff;
  .jobPop .el-form {
    width: calc(100% - 100px);
  }
  .dtit {
    text-align: center;
    font-size: 18px;
    margin: 10px 0 25px;
  }
  .el-checkbox-group {
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle;
  }
  .el-form.el-form--inline {
    margin-left: 30px;
    display: inline-block;
    vertical-align: middle;
    .el-form-item {
      margin: 0;
    }
  }
  .upload-demo {
    display: inline-block;
    margin: 0 10px;
  }
  .tableBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 15px;
    .left {
      width: 450px;
    }
    .right {
      width: calc(100% - 500px);
      .el-table {
        .el-input + .el-input {
          margin-top: 5px;
        }
        .cell {
          position: relative;
          min-height: 22px;
          p {
            margin: 0;
            color: #555;
            padding-right: 10px;
          }
          p + p {
            border-top: 1px dotted #f0f0f0;
          }
          .el-icon-edit {
            position: absolute;
            z-index: 1;
            top: 0px;
            right: 3px;
            padding: 5px;
            cursor: pointer;
            opacity: 0;
            display: none;
            transition: all 0.2s;
          }
          .el-icon-edit:hover {
            color: #409eff;
          }
        }
        tr .cell:hover .el-icon-edit {
          opacity: 1;
          display: block;
        }
        tbody tr:hover > td {
          background-color: #ffffff !important;
        }
      }
    }
  }
}
</style>
