<template>
  <div class="blackKey">
    <search-form
      :searcharray="searcharray"
      :span="6"
      @change="search"
      @reset="reset"
    ></search-form>
    <el-button type="primary" size="small" icon="el-icon-plus" @click="add()"
      >添加
    </el-button>
    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%;margin-top:15px"
      row-key="id"
      border
      stripe
      lazy
      size="small"
    >
      <el-table-column type="index" label="序号" align="center" width="80px">
      </el-table-column>
      <el-table-column prop="productName" align="center" label="产品">
      </el-table-column>
      <el-table-column align="center" label="关键词">
        <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.keywords" :key="index">
            <i v-if="index > 0">、</i>{{ item }}
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="state" align="center" label="状态">
        <template slot-scope="scope">
          <el-tag type="danger" size="mini" v-if="scope.row.status == 1">
            拒保
          </el-tag>
          <el-tag type="success" size="mini" v-else>不拒保</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="handleEdit(scope.row.productId)"
          >
            编辑
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="handleDele(scope.row.productId)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :page-sizes="pageSizeArray"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!--弹窗 -->
    <div>
      <el-dialog
        :title="popTitle"
        :visible.sync="dialogState"
        width="540px"
        @close="dialogClose"
      >
        <el-form
          :model="form"
          :rules="rules"
          :validate-on-rule-change="false"
          ref="form"
          label-width="130px"
          class="form"
          size="small"
        >
          <el-form-item
            label="关键词"
            :prop="'keywords.' + [0]"
            :rules="rules.keywords"
            style="display:inline-block;width: calc(100% - 80px)"
          >
            <el-input
              v-model="form.keywords[0]"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-button type="text" style="margin-left:15px" @click="addKey">
            添加
          </el-button>
          <el-scrollbar>
            <div
              v-for="(item, index) in form.keywords"
              :key="index"
              class="scroll"
              ref="scroll"
            >
              <template v-if="index != 0">
                <el-form-item
                  label="关键词"
                  :prop="'keywords.' + [index]"
                  :rules="rules.keywords"
                  style="display:inline-block;width: calc(100% - 80px)"
                >
                  <el-input
                    v-model="form.keywords[index]"
                    placeholder="请输入内容"
                  ></el-input>
                </el-form-item>
                <el-button
                  type="text"
                  style="margin-left:15px;color:#f56c6c"
                  @click="deleKey(index)"
                >
                  删除
                </el-button>
              </template>
            </div>
          </el-scrollbar>
          <el-form-item label="产品名称" prop="productIds">
            <el-select
              v-model="form.productIds"
              placeholder="请选择"
              style="width:100%"
              filterable
              multiple
            >
              <el-option
                v-for="(item, index) in proArray"
                :label="item.product_name"
                :value="item.id + ''"
                :key="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="状态" prop="status">
            <el-radio v-model="form.status" :label="1">拒保</el-radio>
            <el-radio v-model="form.status" :label="0">不拒保</el-radio>
          </el-form-item> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="dialogOk()">
            确 定
          </el-button>
          <el-button @click="dialogClose()" size="small">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import searchForm from "@/components/common/searchForm";
import {
  changeBackListKeywords,
  getBackListKeywords,
  getBackListKeywordsInfo,
  unsetProductKeywords
} from "@/api/blackList";
import { getSelectProductList } from "@/api/public";
export default {
  name: "blackKey",
  data() {
    return {
      searcharray: [
        {
          label: "关键词",
          val: "keywords",
          type: "input",
          pla: "请输入关键词"
        },
        {
          label: "产品",
          val: "productId",
          type: "select",
          pla: "请选择状态",
          option: []
        }
      ],
      searchData: {},
      proArray: [],
      loading: false,
      tableData: [{ id: 1 }],
      // 分页 每页条数
      pageSize: 15,
      // 分页 每页条数可选项
      pageSizeArray: [15, 30, 45, 60],
      // 当前页
      page: 1,
      // 总页数
      total: 0,
      popTitle: "",
      dialogState: false,
      form: {
        keywords: [""],
        productIds: [],
        status: 1
      },
      rules: {
        keywords: [
          { required: true, message: "关键词不能为空", trigger: "blur" }
        ],
        productIds: [
          { required: true, message: "产品方案不能为空", trigger: "blur" }
        ]
      }
    };
  },
  components: {
    searchForm
  },
  mounted() {
    // 获取列表
    this.getBackListKeywords();
    // 获取产品列表
    this.getSelectProductList();
  },
  methods: {
    // 搜索数据
    search(e) {
      this.page = 1;
      this.searchData = e;
      console.log(e);
      this.getBackListKeywords();
    },
    //搜索重置
    reset(e) {
      this.page = 1;
      this.searchData = {};
      this.getBackListKeywords();
    },
    // 获取列表
    getBackListKeywords() {
      let that = this;
      let _data = this.searchData;
      _data.page = this.page;
      _data.limit = this.pageSize;
      that.loading = true;
      getBackListKeywords(_data)
        .then(function(res) {
          that.loading = false;
          console.log(res);
          if (res.code == 1) {
            that.tableData = res.data.list;
            that.total = res.data.total;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取产品
    getSelectProductList() {
      let that = this;
      getSelectProductList()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.proArray = res.data;
            that.searcharray[1].option = [];
            res.data.forEach(element => {
              let _data = {};
              _data.label = element.product_name;
              _data.val = element.id;
              that.searcharray[1].option.push(_data);
            });
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 每页几条
    sizeChange() {
      this.getBackListKeywords();
    },
    // 页码变化
    currentChange() {
      this.getBackListKeywords();
    },
    // 打开弹窗
    add() {
      this.form = {
        keywords: [""],
        productIds: [],
        status: 1
      };
      this.popTitle = "添加";
      this.dialogState = true;
    },
    // 添加关键词
    addKey() {
      this.form.keywords.push("");
      this.$nextTick(() => {
        let scrollArray = document.querySelectorAll(".scroll");
        scrollArray[scrollArray.length - 1].scrollIntoView();
      });
    },
    // 删除
    deleKey(index) {
      this.form.keywords.splice(index, 1);
    },
    // 编辑
    handleEdit(e) {
      console.log(e);
      let that = this;
      that.dialogState = true;
      let _data = {
        productId: e
      };
      getBackListKeywordsInfo(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.popTitle = "编辑";
            that.form = res.data;
            that.$set(that.form, "productIds", []);
            that.form.productIds.push(res.data.productId);
            that.dialogState = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 弹窗确定
    dialogOk() {
      this.$refs.form.validate(valid => {
        let that = this;
        if (valid) {
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          changeBackListKeywords(that.form)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.getBackListKeywords();
                that.dialogClose();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭取消
    dialogClose() {
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      });
      this.dialogState = false;
    },
    //删除数据
    handleDele(e) {
      console.log(e);
      let that = this;
      this.$confirm("此操作将删除本条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          //确定执行
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          unsetProductKeywords({ productId: e })
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.getBackListKeywords();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        })
        .catch(e => {
          //取消执行
          console.log(e);
        });
    }
  }
};
</script>
<style lang="scss">
.blackKey {
  background: #fff;
  .form {
    width: calc(100% - 100px);
    .el-scrollbar .el-scrollbar__bar {
      opacity: 1;
    }
    .el-scrollbar__wrap {
      max-height: 400px;
      overflow-x: hidden;
      margin-bottom: 0 !important;
    }
  }
}
</style>
