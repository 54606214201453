import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/font/iconfont/iconfont.css"; // 阿里字体图标
import "@/styles/index.scss"; // 全局 css
// element引用 Loading加载、Notification 通知、Message 消息提示、按钮、按钮组、导航菜单、滚动、Tooltip 文字提示、Badge 标记、面包屑、头像、下拉菜单,Input,Form,单选框,多选框,选择器,日期选择器、标签、分页、表格、标签页、上传,开关,对话框，弹窗,气泡确认框，树,弹出框,分割线,时间选择,图片,抽屉
import {
  Loading,
  Notification,
  Message,
  Button,
  ButtonGroup,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Scrollbar,
  Tooltip,
  Badge,
  Avatar,
  Breadcrumb,
  BreadcrumbItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  InputNumber,
  Form,
  FormItem,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Radio,
  RadioGroup,
  RadioButton,
  Select,
  Option,
  OptionGroup,
  DatePicker,
  Row,
  Col,
  Tag,
  Pagination,
  Table,
  TableColumn,
  Tabs,
  TabPane,
  Upload,
  Switch,
  Dialog,
  MessageBox,
  Popconfirm,
  Tree,
  Popover,
  Divider,
  TimeSelect,
  TimePicker,
  Cascader,
  Image,
  Drawer
} from "element-ui";

Vue.config.productionTip = false;
Vue.use(Loading);
Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$notification = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;

Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(MenuItemGroup);
Vue.use(Scrollbar);
Vue.use(Tooltip);
Vue.use(Badge);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Avatar);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(DatePicker);
Vue.use(Row);
Vue.use(Col);
Vue.use(Tag);
Vue.use(Pagination);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Upload);
Vue.use(Switch);
Vue.use(Dialog);
Vue.use(Popconfirm);
Vue.use(Tree);
Vue.use(Popover);
Vue.use(Divider);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Cascader);
Vue.use(Image);
Vue.use(Drawer);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
