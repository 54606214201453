<template>
  <div class="icAdminList">
    <searchForm
      :searcharray="searcharray"
      :span="6"
      @change="search"
      @reset="reset"
    ></searchForm>
    <el-button type="primary" size="small" icon="el-icon-plus" @click="add()"
      >添加
    </el-button>
    <el-table
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%;margin-top:15px"
      row-key="id"
      border
      stripe
      lazy
      size="small"
      class="tableList"
    >
      <el-table-column prop="id" label="ID" align="center" width="80px">
      </el-table-column>
      <el-table-column prop="name" align="center" label="公司名称">
      </el-table-column>
      <el-table-column prop="bank_name" align="center" label="账号名称">
      </el-table-column>
      <el-table-column prop="bank_no" align="center" label="保司银行账号">
      </el-table-column>
      <el-table-column prop="insure_email" align="center" label="投保邮箱">
      </el-table-column>
      <el-table-column
        prop="insure_email_spare"
        align="center"
        label="投保备用邮箱"
      >
      </el-table-column>
      <el-table-column prop="correction_email" align="center" label="批改邮箱">
      </el-table-column>
      <el-table-column
        prop="correction_email_spare"
        align="center"
        label="批改备用邮箱"
      >
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="handleEdit(scope.row.id)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      :page-sizes="pageSizeArray"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!--弹窗 -->
    <div class="icPop">
      <el-dialog
        :title="popTitle"
        :visible.sync="dialogState"
        width="540px"
        @close="dialogClose"
        top="10vh"
      >
        <el-form
          :model="icForm"
          :rules="rules"
          :validate-on-rule-change="false"
          ref="icForm"
          label-width="130px"
          class="icForm"
          size="small"
        >
          <el-form-item label="保险公司名称" prop="name">
            <el-input v-model="icForm.name" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="保司银行账号" prop="bankNo">
            <el-input
              v-model.trim="icForm.bankNo"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="开户行" prop="bankTitle">
            <el-input
              v-model.trim="icForm.bankTitle"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="账户名称" prop="bankName">
            <el-input
              v-model="icForm.bankName"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="保司电话" prop="phone">
            <el-input
              v-model="icForm.phone"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="投保邮箱" prop="insureEmail">
            <el-input
              v-model="icForm.insureEmail"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="投保备用邮箱" prop="insureEmailSpare">
            <el-input
              v-model="icForm.insureEmailSpare"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="批改邮箱" prop="correctionEmail">
            <el-input
              v-model="icForm.correctionEmail"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="批改备用邮箱" prop="correctionEmailSpare">
            <el-input
              v-model="icForm.correctionEmailSpare"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否支持开专票" prop="isSpecial">
            <el-radio v-model="icForm.isSpecial" :label="1">
              支持
            </el-radio>
            <el-radio v-model="icForm.isSpecial" :label="0">
              不支持
            </el-radio>
          </el-form-item>
          <el-form-item
            label="专票限制金额"
            prop="specialTicket"
            v-if="icForm.isSpecial == 1"
          >
            <el-input-number
              v-model="icForm.specialTicket"
              controls-position="right"
              :precision="2"
              :min="0"
              :max="1000000"
              style="width:100%"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="保费精确位数" prop="premiumSetting">
            <el-radio v-model="icForm.premiumSetting" :label="2">
              精确两位小数
            </el-radio>
            <el-radio v-model="icForm.premiumSetting" :label="4">
              精确四位小数
            </el-radio>
          </el-form-item>
          <el-form-item label="费用规则" prop="premiumRule">
            <el-radio v-model="icForm.premiumRule" :label="1">
              四舍五入
            </el-radio>
            <el-radio v-model="icForm.premiumRule" :label="2">
              进一
            </el-radio>
            <el-radio v-model="icForm.premiumRule" :label="3">
              截取
            </el-radio>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="dialogOk()">
            确 定
          </el-button>
          <el-button @click="dialogClose()" size="small">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import searchForm from "@/components/common/searchForm";
import { changeICompany, getCompanyInfo, getCompanyList } from "@/api/icAdmin";
import { getCompany } from "@/api/public";
export default {
  name: "icAdminList",
  components: {
    searchForm
  },
  data() {
    return {
      searcharray: [
        {
          label: "保司名称",
          val: "id",
          type: "select",
          pla: "请选择保司",
          option: []
        },
        {
          label: "保司银行账号",
          val: "bankNo",
          type: "input",
          pla: "请输入保司银行账号"
        },
        {
          label: "账号名称",
          val: "bankName",
          type: "input",
          pla: "请输入账号名称"
        },
        // {
        //   label: "开户行",
        //   val: "bankTitle",
        //   type: "input",
        //   pla: "请输入开户行"
        // },
        {
          label: "投保邮箱",
          val: "insureEmail",
          type: "input",
          pla: "请输入投保邮箱"
        },
        {
          label: "投保备用邮箱",
          val: "insureEmailSpare",
          type: "input",
          pla: "请输入投保备用邮箱"
        },
        {
          label: "批改邮箱",
          val: "correctionEmail",
          type: "input",
          pla: "请输入批改邮箱"
        },
        {
          label: "批改备用邮箱",
          val: "correctionEmailSpare",
          type: "input",
          pla: "请输入批改备用邮箱"
        }
      ],
      // 搜索数据
      searchVal: {},
      // 表格数据
      tableData: [],
      //表格loading
      loading: false,
      popTitle: "添加",
      dialogState: false,
      icForm: {
        id: "",
        name: "",
        bankNo: "",
        bankName: "",
        bankTitle: "",
        phone: "",
        insureEmail: "",
        insureEmailSpare: "",
        correctionEmail: "",
        correctionEmailSpare: "",
        isSpecial: 0,
        specialTicket: "",
        premiumSetting: 2,
        premiumRule: 1
      },
      // 分页 每页条数
      pageSize: 15,
      // 分页 每页条数可选项
      pageSizeArray: [15, 30, 45, 60],
      // 当前页
      page: 1,
      // 总页数
      total: 0,
      rules: {
        name: [
          { required: true, message: "保险公司名称不能为空", trigger: "blur" }
        ],
        bankNo: [
          { required: true, message: "保司银行账号不能为空", trigger: "blur" },
          {
            pattern: /^\d+$/,
            message: "保司银行账号必须为数字值",
            trigger: "blur"
          }
        ],
        bankTitle: [
          { required: true, message: "开户行不能为空", trigger: "blur" }
        ],
        bankName: [
          { required: true, message: "账户名称不能为空", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "保司电话不能为空", trigger: "blur" }
        ],
        insureEmail: [
          { required: true, message: "投保邮箱不能为空", trigger: "blur" },
          { type: "email", message: "邮箱格式不正确", trigger: "blur" }
        ],
        insureEmailSpare: [
          { required: true, message: "投保备用邮箱不能为空", trigger: "blur" },
          { type: "email", message: "邮箱格式不正确", trigger: "blur" }
        ],
        correctionEmail: [
          { required: true, message: "批改邮箱不能为空", trigger: "blur" },
          { type: "email", message: "邮箱格式不正确", trigger: "blur" }
        ],
        correctionEmailSpare: [
          {
            required: true,
            message: "批改邮备用邮箱不能为空",
            trigger: "blur"
          },
          { type: "email", message: "邮箱格式不正确", trigger: "blur" }
        ],
        specialTicket: [
          { required: true, message: "专票最低金额不能为空", trigger: "blur" },
          {
            pattern: /^\d+(\.\d+)?$/,
            message: "金额必须为数字值",
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    this.getCompanyList();
    // 获取保司
    this.getCompany();
  },
  methods: {
    search(e) {
      //搜索
      this.page = 1;
      this.searchVal = e;
      this.getCompanyList();
    },
    reset() {
      //重置
      this.page = 1;
      this.searchVal = {};
      this.getCompanyList();
    },
    // 获取列表
    getCompanyList() {
      let that = this;
      let _data = that.searchVal;
      _data.page = this.page;
      _data.limit = this.pageSize;
      that.loading = true;
      getCompanyList(_data)
        .then(function(res) {
          console.log(res);
          that.loading = false;
          if (res.code == 1) {
            that.tableData = res.data.list;
            that.total = res.data.total;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    //添加保司弹窗
    add() {
      this.icForm = {
        id: "",
        name: "",
        bankNo: "",
        bankName: "",
        phone: "",
        bankTitle: "",
        insureEmail: "",
        insureEmailSpare: "",
        correctionEmail: "",
        correctionEmailSpare: "",
        isSpecial: 0,
        specialTicket: "",
        premiumSetting: 2,
        premiumRule: 1
      };
      this.popTitle = "添加";
      this.dialogState = true;
    },
    //编辑保司弹窗
    handleEdit(e) {
      console.log(e);
      let that = this;
      let _data = { id: e };
      getCompanyInfo(_data)
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.icForm = res.data;
            that.popTitle = "编辑";
            that.dialogState = true;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 关闭弹窗
    dialogClose() {
      this.$nextTick(() => {
        this.$refs["icForm"].resetFields();
      });
      this.dialogState = false;
    },
    // 弹窗确认
    dialogOk() {
      this.$refs.icForm.validate(valid => {
        let that = this;
        console.log(valid);
        if (valid) {
          let loadind = that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          changeICompany(that.icForm)
            .then(function(res) {
              console.log(res);
              loadind.close();
              if (res.code == 1) {
                that.$message.success(res.msg);
                that.dialogClose();
                that.getCompanyList();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch(function(error) {
              that.loading = false;
              that.$message.error(error.msg);
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 每页几条
    sizeChange() {
      this.getCompanyList();
    },
    // 页码变化
    currentChange() {
      this.getCompanyList();
    },
    // 获取保司列表
    getCompany() {
      let that = this;
      getCompany()
        .then(function(res) {
          console.log(res);
          if (res.code == 1) {
            that.searcharray[0].option.push({ label: "全部", val: "" });
            res.data.forEach((item, index) => {
              let _obj = { label: "", val: "" };
              _obj.label = item.name;
              _obj.val = item.id;
              that.searcharray[0].option.push(_obj);
            });
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>
<style lang="scss">
.icAdminList {
  padding: 30px;
  background: #fff;
  min-height: 100%;
  // 弹窗
  .icPop .icForm {
    width: calc(100% - 100px);
    .el-form-item {
      margin-bottom: 15px;
      .el-input-number .el-input__inner {
        text-align: left;
      }
    }
  }
}
</style>
