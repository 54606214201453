import http from "../utils/axios";
// 保司管理
export const changeICompany = data => {
  return http({
    url: "/api/company/changeICompany", // 接口
    method: "post",
    data: data
  });
};
// 获取保司信息接口
export const getCompanyInfo = data => {
  return http({
    url: "/api/company/getCompanyInfo", // 接口
    method: "post",
    data: data
  });
};
// 获取保司信息接口
export const getCompanyList = data => {
  return http({
    url: "/api/company/getCompanyList", // 接口
    method: "post",
    data: data
  });
};
// 获取产品投保规则信息接口
export const changeAgent = data => {
  return http({
    url: "/api/agent/changeAgent", // 接口
    method: "post",
    data: data
  });
};
// 获取保司披露信息列表接口
export const getAgentList = data => {
  return http({
    url: "/api/agent/getAgentList", // 接口
    method: "post",
    data: data
  });
};
// 获取保司披露记录信息接口
export const getAgentInfo = data => {
  return http({
    url: "/api/agent/getAgentInfo", // 接口
    method: "post",
    data: data
  });
};
//添加、编辑披露信息记录分支机构接口
export const changeAgentBranch = data => {
  return http({
    url: "/api/agent/changeAgentBranch", // 接口
    method: "post",
    data: data
  });
};
//添加、编辑披露信息记录分支机构接口
export const getAgentBranch = data => {
  return http({
    url: "/api/agent/getAgentBranch", // 接口
    method: "post",
    data: data
  });
};
//添加、编辑披露信息合作记录接口
export const changeAgentCompany = data => {
  return http({
    url: "/api/agent/changeAgentCompany", // 接口
    method: "post",
    data: data
  });
};
//获取披露信息合作记录接口
export const getAgentCompany = data => {
  return http({
    url: "/api/agent/getAgentCompany", // 接口
    method: "post",
    data: data
  });
};
